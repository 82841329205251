import React from 'react'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { Button, Card, Col, Dropdown, Placeholder, Row, SplitButton } from 'react-bootstrap'
import GameRowInfoColumn from './GameRowInfoColumn'
import GameRowRefereesColumn from './GameRowRefereesColumn'
import { requiredButNotAssigned } from '../../util'
import { Link } from 'react-router-dom'

class ReleaseGameRow extends React.Component {

    render() {

        const referees           = this.props.game && this.props.game.MapRefereeGames ? this.props.game.MapRefereeGames : []
          let assignmentComplete = !! referees.length

        referees.forEach( e => {

            if ( requiredButNotAssigned( e ) ) {

                assignmentComplete = false

            }

        } )

        return (

            <Card className={ 'mb-2 release-game-row' + ( this.props.game && this.props.game.isReleased ? ' released' : '' ) }>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } xl={ 4 }>

                            <GameRowInfoColumn
                                disabled={ ! assignmentComplete }
                                game={ this.props.game }
                                isSelected={ this.props.isSelected }
                                noCheckbox={ ! this.props.isReleasable }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } md={ 10 } xl={ 6 }>

                            <div className='my-3'>

                                <GameRowRefereesColumn
                                    federations={ this.props.federations }
                                    game={ this.props.game }
                                    referees={ referees }
                                />

                            </div>

                        </Col>

                        <Col xs={ 12 } md={ 2 } xl={ 2 } className='text-right text-nowrap'>

                            {

                                this.props.game ?

                                    <>

                                        <Link to='/einteilung' className='fs-4 d-inline-block me-3 align-middle' title={ _( 'Ändern' ) }><Icon icon='edit' /></Link>

                                        {

                                            this.props.isReleasable && this.props.isNotifiable ?

                                                <SplitButton
                                                    align='end'
                                                    disabled={ this.props.isReleasing || this.props.game.isReleased || ! assignmentComplete }
                                                    onClick={ () => this.props.onReleaseGame( this.props.game.Id ) }
                                                    title={ this.props.isReleasing ? <Icon spin icon='spinner' /> : this.props.game.isReleased ? <Icon icon='thumbs-up' /> : _( 'Freigeben' ) }
                                                >

                                                    <Dropdown.Item as='button' type='button' onClick={ () => this.props.onReleaseGame( this.props.game.Id, true ) }>{ _( 'Freigeben & Benachrichtigen' ) }</Dropdown.Item>

                                                </SplitButton>

                                            : this.props.isReleasable ?

                                                <Button onClick={ () => this.props.onReleaseGame( this.props.game.Id ) } disabled={ this.props.isReleasing || this.props.game.isReleased || ! assignmentComplete }>{ this.props.isReleasing ? <Icon spin icon='spinner' /> : this.props.game.isReleased ? <Icon icon='thumbs-up' /> : _( 'Freigeben' ) }</Button>

                                            :

                                                ''

                                        }

                                    </>

                                :

                                    this.props.isReleasable && <Placeholder.Button xs={ 3 } />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default ReleaseGameRow