import { connect } from 'react-redux'
import PublicSchedule from '../../components/pages/PublicSchedule'
import { setDateRange, setDivisions } from '../../store/actions'
import { createDate } from '../../util/date'

const mapStateToProps = state => {

    return {

        dateRangeFrom: createDate( state.ui.dateRangeFromPublic ),
        dateRangeTo:   createDate( state.ui.dateRangeToPublic   ),
        divisions:     state.ui.divisionsPublic || [],

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange: ( from, to ) => dispatch( setDateRange( from, to, 'dateRangeFromPublic', 'dateRangeToPublic' ) ),
        onDivisionsChange: divisions    => dispatch( setDivisions( divisions, 'divisionsPublic'                         ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( PublicSchedule )