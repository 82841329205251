import { ajax, ExternalPage, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Button, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import TreeSelect from '../controls/TreeSelect'
import { convertLeagues, getDateRangePickerLocale, sortByKey } from '../../util'
import { createDate, fillDate } from '../../util/date'
import PublicScheduleGameRow from '../elements/PublicScheduleGameRow'

class PublicSchedule extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            divisions:        null,
            error:            null,
            federations:      null,
            games:            null,
            hasError:         false,
            isLoadingLeagues: false,
            isLoadingGames:   false,

        }

    }

    componentDidMount() {

        this.loadLeagues()

    }

    dismissError() {

        this.setState( { hasError: false } )

    }

    error() {

        this.setState( {

            error:    _( 'Daten konnten nicht geladen werden.' ),
            hasError: true,

        } )

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

        setTimeout( () => this.loadGames(), 200 )

    }

    loadGames() {

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return

        }

        const data = {

            divIds: { __arr: true, values: this.props.divisions },
            from:   this.props.dateRangeFrom.toJSON(),
            to:     this.props.dateRangeTo.toJSON(),

        }

        const success = e => {

            const games = e.Games

            sortByKey( games, 'ScheduleDate' )

            this.setState( { federations: e.Federations, games } )

        }

        this.setState( { hasError: false, isLoadingGames: true }, () => setTimeout( () => {

            ajax( getDataUrl( 'api/PublicSchedule/getPublicSchedule' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data[ 0 ] ) : this.error() )
                .catch( () => this.error() )
                .finally( () => this.setState( { isLoadingGames: false } ) )

        }, 400 ) )

    }

    loadLeagues() {

        const success = e => {

            if ( ! this.props.divisions.length ) {

                const dateRangeTo = createDate( this.props.dateRangeFrom )
                const divisions   = []

                e.forEach( league => league.Divisions && league.Divisions.forEach( division => divisions.push( division.Id ) ) )

                dateRangeTo.setMonth( dateRangeTo.getMonth() + 1 )

                this.props.onDivisionsChange( divisions                                               )
                this.props.onDateRangeChange( this.props.dateRangeFrom.toJSON(), dateRangeTo.toJSON() )

            }

            setTimeout( () => this.setState( { divisions: convertLeagues( e ) }, () => this.loadGames() ), 200 )

        }

        this.setState( { hasError: false, isLoadingLeagues: true }, () => setTimeout( () => {

            ajax( getDataUrl( 'api/PublicSchedule/getScheduleableLeagues' ), null, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data ) : this.error() )
                .catch( () => this.error() )
                .finally( () => this.setState( { isLoadingLeagues: false } ) )

        }, 400 ) )

    }

    render() {

        return (

            <ExternalPage headerRight={ this.props.headerRight } logoImageUrl={ this.props.logoImageUrl } logoText={ this.props.logoText }>

                <Container className='py-4 py-lg-5 my-lg-5'>

                    <Row>

                        <Col>

                            <Form>

                                <Row className='align-items-center mb-3 px-3'>

                                    <Col xs={ 12 } sm={ 6 } xl={ { span: 4, offset: 2 } } className='mb-1'>

                                        <Form.Group as={ Row } controlId='league'>

                                            <Form.Label column xs={ 4 } className='text-right text-white'>{ _( 'Liga' ) }</Form.Label>

                                            <Col xs={ 8 }>

                                                <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                                            </Col>

                                        </Form.Group>

                                    </Col>

                                    <Col xs={ 12 } sm={ 6 } xl={ 4 } className='mb-1'>

                                        <Form.Group as={ Row } controlId='date-range'>

                                            <Form.Label column xs={ 4 } className='text-right text-white'>{ _( 'Zeitraum' ) }</Form.Label>

                                            <Col xs={ 8 }>

                                                <DateRangePicker initialSettings={ {

                                                    endDate:   this.props.dateRangeTo,
                                                    locale:    getDateRangePickerLocale(),
                                                    startDate: this.props.dateRangeFrom,

                                                } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                                    <Form.Control />

                                                </DateRangePicker>

                                            </Col>

                                        </Form.Group>

                                    </Col>

                                </Row>

                            </Form>

                            {

                                this.state.isLoadingLeagues ?

                                    <div className='my-5 text-center text-white'><Icon icon='spinner' spin size='2' /></div>

                                : this.state.isLoadingGames ?

                                    Array.from( { length: 3 } ).map( ( e, i ) => <PublicScheduleGameRow key={ i } /> )

                                : this.state.games && this.state.games.length ?

                                    <div>

                                        { this.state.games.map( ( game, i ) =>

                                            <PublicScheduleGameRow
                                                federations={ this.state.federations }
                                                game={ game }
                                                key={ i }
                                            />

                                        ) }

                                    </div>

                                : this.state.games && ! this.state.games.length ?

                                    <div className='my-5 text-center text-white'>{ _( 'Keine Spiele gefunden.' ) }</div>

                                : this.state.hasError ?

                                    ''

                                : ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ?

                                    <div className='my-5 text-center text-white'>{ _( 'Bite Zeitraum und Liga wählen.' ) }</div>

                                : ''

                            }

                        </Col>

                    </Row>

                </Container>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissError() } show={ this.state.hasError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ this.state.error }</p>

                            <Button onClick={ () => this.loadLeagues() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ExternalPage>

        )

    }

}

export default PublicSchedule