import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row, Toast, ToastContainer } from 'react-bootstrap'
import LeagueRow from '../elements/LeagueRow'
import { deepCopy, sortByKey, sortByName } from '../../util'
import { withRouter } from 'react-router-dom'
import { STATUS_MESSAGE_LEAGUE_ALREADY_EXISTS } from '../../util/constants'

class LeagueManagement extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            leagueModalOpen:                 false,
            hasImportingError:               false,
            hasLoadingInterfaceLeaguesError: false,
            hasLoadingError:                 false,
            hasSavingError:                  false,
            importLeagueId:                  null,
            interfaceLeagues:                null,
            interfaceLeaguesUnique:          null,
            interfaceSeasons:                null,
            interfaces:                      null,
            isImporting:                     false,
            isLoadingInterfaceLeagues:       false,
            isSaving:                        false,
            league:                          this.getDefaultLeague(),
            leagueAlreadyExists:             false,
            leagues:                         null,
            leagueTypes:                     null,
            refereeSystems:                  null,
            showImportSuccessMessage:        false,

        }

    }

    componentDidMount() {

        this.load()

    }

    changeLeague( e ) {

        const league = { ...this.state.league, [ e.target.name ]: e.target.value }

        if ( e.target.name === 'interfaceId' ) {

            const interfaceId = e.target.value ? Number( e.target.value ) : null
            const intrfc      = this.state.interfaces && this.state.league.interfaceId !== null && this.state.interfaces.find( e => e.Id === interfaceId )

            league.interfaceRequiresLeague = intrfc && intrfc.Name !== 'Excel' // TODO Remove hardcoded value

        }

        this.setState( { league }, () => {

            e.target.name === 'interfaceId'       && this.state.league.interfaceRequiresLeague && this.loadInterfaceLeagues()
            e.target.name === 'interfaceLeagueId' && this.setInterfaceSeasons()

        } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorImporting() {

        this.setState( { hasImportingError: true } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    errorSaving( e ) {

        e && e.StatusMsg === STATUS_MESSAGE_LEAGUE_ALREADY_EXISTS ? this.setState( { leagueAlreadyExists: true } ) : this.setState( { hasSavingError: true } )

    }

    filterLeague( league ) {

        const filterQuery = this.props.filterQuery.toLowerCase()

        if ( filterQuery && league.Name.toLowerCase().indexOf( filterQuery ) === -1 ) {

            return false

        }

        if ( this.props.filterLeagueType && Number( this.props.filterLeagueType ) !== league.LeagueType ) {

            return false

        }

        return true

    }

    getDefaultLeague() {

        return {

            interfaceId:             '',
            interfaceLeagueId:       '',
            interfaceRequiresLeague: false,
            interfaceSeasonId:       '',
            leagueName:              '',
            leagueType:              '',
            refereeSystemId:         '',

        }

    }

    hideImportModal() {

        this.setState( { importLeagueId: null } )

    }

    hideLeagueModal() {

        this.setState( {

            leagueModalOpen: false,
            league:          this.getDefaultLeague(),

        } )

    }

    importGames() {

        this.setState( { hasImportingError: false, isImporting: true, showImportSuccessMessage: false }, () => {

            ajax( getDataUrl( 'api/League/ImportGamePlan' ), { leagueId: this.state.importLeagueId, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { showImportSuccessMessage: true } ) : this.errorImporting() )
                .catch( () => this.errorImporting() )
                .finally( () => this.setState( { isImporting: false } ) )

        } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/Interface/Get',     name: 'interfaces',     fn: sortByName } ),
                this.loadItems( { url: 'api/League/Get',        name: 'leagues'                        } ),
                this.loadItems( { url: 'api/LeagueType/Get',    name: 'leagueTypes',    fn: sortByName } ),
                this.loadItems( { url: 'api/RefereeSystem/Get', name: 'refereeSystems', fn: sortByName } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.errorLoading() )
                .finally( () => {

                    this.props.onToggleIsLoading( false )

                    this.sortLeagues()

                } )

        }, 400 ) )

    }

    loadInterfaceLeagues() {

        this.setState( { hasLoadingInterfaceLeaguesError: false, league: { ...this.state.league, interfaceLeagueId: '', interfaceSeasonId: '' } }, () => {

            if ( this.state.league.interfaceId ) {

                this.setState( { isLoadingInterfaceLeagues: true }, () => {

                    const errorLoading = () => this.setState( { hasLoadingInterfaceLeaguesError: true } )

                    ajax( getDataUrl( 'api/League/GetInterfaceLeagues' ), { interfaceId: this.state.league.interfaceId, token: this.props.token }, { method: 'POST' } )
                        .then( e => e.StatusId > 0 && e.Data ? this.setInterfaceLeagues( e.Data ) : errorLoading() )
                        .catch( errorLoading )
                        .finally( () => this.setState( { isLoadingInterfaceLeagues: false } ) )

                } )

            } else {

                this.setInterfaceLeagues()

            }

        } )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    saveLeague() {

        let league = null

        if ( this.state.league.interfaceRequiresLeague ) {

            const interfaceLeagueId = Number( this.state.league.interfaceLeagueId )
            const interfaceSeasonId = Number( this.state.league.interfaceSeasonId )
                  league            = this.state.interfaceLeagues && this.state.interfaceLeagues.find( e => e.ExternalLeagueId === interfaceLeagueId && e.ExternalSeasonId === interfaceSeasonId )

        }

        ( ! this.state.league.interfaceRequiresLeague || league ) && this.setState( { hasSavingError: false, isSaving: true, leagueAlreadyExists: false }, () => {

            const success = e => {

                const leagues = this.state.leagues ? deepCopy( this.state.leagues ) : []

                leagues.push( e )

                this.setState( { leagues, leagueModalOpen: false, importLeagueId: e.Id }, () => this.sortLeagues() )

            }

            const data = {

                id:               0,
                managerAccountId: 0,
                leagueType:       Number( this.state.league.leagueType ),
                refereeSystemId:  Number( this.state.league.refereeSystemId ),
                interfaceId:      Number( this.state.league.interfaceId ),
                token:            this.props.token,

            }

            if ( this.state.league.interfaceRequiresLeague ) {

                data.name        = league.LeagueName
                data.externalId  = league.ExternalLeagueId
                data.seasonDivId = league.ExternalSeasonId
                data.seasonName  = league.SeasonName

            } else {

                data.name = this.state.league.leagueName

            }

            ajax( getDataUrl( 'api/League/AddOrEdit' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data[ 0 ] ) : this.errorSaving( e ) )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false } ) )

        } )

    }

    setInterfaceLeagues( interfaceLeagues ) {

        let interfaceLeaguesUnique = null

        if ( interfaceLeagues ) {

            interfaceLeaguesUnique = []

            interfaceLeagues.forEach( interfaceLeague => interfaceLeaguesUnique.findIndex( e => e.id === interfaceLeague.ExternalLeagueId ) === -1 && interfaceLeaguesUnique.push( { id: interfaceLeague.ExternalLeagueId, name: interfaceLeague.LeagueName } ) )

            sortByKey( interfaceLeaguesUnique, 'name' )

        }

        this.setState( { interfaceLeagues, interfaceLeaguesUnique, interfaceSeasons: null } )

    }

    setInterfaceSeasons() {

        let interfaceSeasons = null

        if ( this.state.league.interfaceLeagueId ) {

            const interfaceLeagueId = Number( this.state.league.interfaceLeagueId )
                  interfaceSeasons  = []

            this.state.interfaceLeagues && this.state.interfaceLeagues.forEach( interfaceLeague => interfaceLeague.ExternalLeagueId === interfaceLeagueId && interfaceSeasons.push( { id: interfaceLeague.ExternalSeasonId, name: interfaceLeague.SeasonName } ) )

            sortByKey( interfaceSeasons, 'name' )

        }

        this.setState( { interfaceSeasons, league: { ...this.state.league, interfaceSeasonId: '' } } )

    }

    sortLeagues() {

        if ( this.state.leagues ) {

            const leagues = deepCopy( this.state.leagues )

            leagues
                .sort( ( a, b ) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0 )
                .forEach( league => league.Teams && sortByName( league.Teams ) )

            this.setState( { leagues } )

        }

    }

    showLeagueModal() {

        this.setState( { leagueModalOpen: true } )

    }

    render() {

        const leagues = this.state.leagues ? this.state.leagues.filter( e => this.filterLeague( e ) ) : null

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='diamond' className='subheader-icon' /> { _( 'Ligaverwaltung' ) }

                    </h1>

                    <div className='subheader-block'>

                        <Button variant='primary' onClick={ () => this.showLeagueModal() }><Icon icon='plus' /> { _( 'Neu' ) }</Button>

                    </div>

                </div>

                <Form>

                    <Row>

                        <Col xs={ 12 } sm={ 6 } xl={ 5 } xxl={ 4 } className='mb-1'>

                            <FloatingLabel label={ _( 'Liga' ) }>

                                <Form.Select value={ this.props.filterLeagueType } onChange={ e => this.props.onSetFilterLeagueType( e.target.value ) }>

                                    <option value=''>{ _( 'Alle' ) }</option>

                                    { this.state.leagueTypes && this.state.leagueTypes.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                </Form.Select>

                            </FloatingLabel>

                        </Col>

                        <Col xs={ 12 } sm={ 6 } xl={ 5 } xxl={ 4 } className='ms-auto mb-1'>

                            <Form.Group>

                                <Form.Control placeholder={ _( 'Ligen suchen' ) } size='lg' className='floating-label-height' value={ this.props.filterQuery } onChange={ e => this.props.onSetFilterQuery( e.target.value ) } />

                            </Form.Group>

                        </Col>

                    </Row>

                </Form>

                <Row className='my-5'>

                    <Col>

                        {

                            this.props.isLoading ?

                                Array.from( { length: 3 } ).map( ( e, i ) => <LeagueRow key={ i } /> )

                            : leagues && ! leagues.length ?

                                <div className='my-3 text-center'>{ _( 'Keine Ligen gefunden.' ) }</div>

                            : leagues ?

                                leagues.map( ( e, i ) => <LeagueRow league={ e } key={ i } teams={ e.Teams } /> )

                            : ''

                        }

                    </Col>

                </Row>

                <Modal show={ this.state.leagueModalOpen } onHide={ () => this.hideLeagueModal() } backdrop='static' centered scrollable>

                    <Modal.Header closeButton>

                        <Modal.Title>{ _( 'Liga hinzufügen' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <Form>

                            <fieldset disabled={ this.state.isSaving }>

                                <Form.Group as={ Row } className='mb-3' controlId='interfaceId'>

                                    <Form.Label column sm={ 3 }>{ _( 'Importsystem' ) }</Form.Label>

                                    <Col sm={ 9 }>

                                        <Form.Select value={ this.state.league.interfaceId } name='interfaceId' onChange={ e => this.changeLeague( e ) }>

                                            <option value=''></option>

                                            { this.state.interfaces && this.state.interfaces.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                        </Form.Select>

                                    </Col>

                                </Form.Group>

                                {

                                    this.state.league.interfaceRequiresLeague ?

                                        <>

                                            <Form.Group as={ Row } className='mb-3' controlId='interfaceLeagueId'>

                                                <Form.Label column sm={ 3 }>{ _( 'Liga' ) }</Form.Label>

                                                <Col sm={ 9 }>

                                                    <Form.Select value={ this.state.league.interfaceLeagueId } name='interfaceLeagueId' onChange={ e => this.changeLeague( e ) } disabled={ this.state.isLoadingInterfaceLeagues || this.state.hasLoadingInterfaceLeaguesError || ! this.state.interfaceLeaguesUnique || ! this.state.interfaceLeaguesUnique.length }>

                                                        {

                                                            this.state.isLoadingInterfaceLeagues ?

                                                                <option>{ _( 'Wird geladen...' ) }</option>

                                                            : this.state.hasLoadingInterfaceLeaguesError ?

                                                                <option>{ _( 'Fehler beim Laden.' ) }</option>

                                                            : ! this.state.interfaceLeaguesUnique ?

                                                                <option></option>

                                                            : ! this.state.interfaceLeaguesUnique.length ?

                                                                <option>{ _( 'Keine Einträge gefunden.' ) }</option>

                                                            :

                                                                <>

                                                                    <option value=''>{ _( 'Bitte wählen:' ) }</option>

                                                                    { this.state.interfaceLeaguesUnique.map( ( e, i ) => <option value={ e.id } key={ i }>{ e.name }</option> ) }

                                                                </>

                                                        }

                                                    </Form.Select>

                                                </Col>

                                            </Form.Group>

                                            <Form.Group as={ Row } className='mb-3' controlId='interfaceSeasonId'>

                                                <Form.Label column sm={ 3 }>{ _( 'Saison' ) }</Form.Label>

                                                <Col sm={ 9 }>

                                                    <Form.Select value={ this.state.league.interfaceSeasonId } name='interfaceSeasonId' onChange={ e => this.changeLeague( e ) } disabled={ this.state.isLoadingInterfaceLeagues || this.state.hasLoadingInterfaceLeaguesError || ! this.state.interfaceSeasons || ! this.state.interfaceSeasons.length }>

                                                        {

                                                            ! this.state.interfaceSeasons ?

                                                                <option></option>

                                                            :

                                                                <>

                                                                    <option value=''>{ _( 'Bitte wählen:' ) }</option>

                                                                    { this.state.interfaceSeasons.map( ( e, i ) => <option value={ e.id } key={ i }>{ e.name }</option> ) }

                                                                </>

                                                        }

                                                    </Form.Select>

                                                </Col>

                                            </Form.Group>

                                        </>

                                    :

                                        <Form.Group as={ Row } className='mb-3' controlId='leagueName'>

                                            <Form.Label column sm={ 3 }>{ _( 'Liganame' ) }</Form.Label>

                                            <Col sm={ 9 }>

                                                <Form.Control value={ this.state.league.leagueName } name='leagueName' onChange={ e => this.changeLeague( e ) }/>

                                            </Col>

                                        </Form.Group>

                                }

                                <Form.Group as={ Row } className='mb-3' controlId='leagueType'>

                                    <Form.Label column sm={ 3 }>{ _( 'Ligatyp' ) }</Form.Label>

                                    <Col sm={ 9 }>

                                        <Form.Select value={ this.state.league.leagueType } name='leagueType' onChange={ e => this.changeLeague( e ) }>

                                            <option value=''>{ _( 'Bitte wählen:' ) }</option>

                                            { this.state.leagueTypes && this.state.leagueTypes.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                        </Form.Select>

                                    </Col>

                                </Form.Group>

                                <Form.Group as={ Row } className='mb-3' controlId='refereeSystemId'>

                                    <Form.Label column sm={ 3 }>{ _( 'Referee-System' ) }</Form.Label>

                                    <Col sm={ 9 }>

                                        <Form.Select value={ this.state.league.refereeSystemId } name='refereeSystemId' onChange={ e => this.changeLeague( e ) }>

                                            <option value=''>{ _( 'Bitte wählen:' ) }</option>

                                            { this.state.refereeSystems && this.state.refereeSystems.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                        </Form.Select>

                                    </Col>

                                </Form.Group>

                            </fieldset>

                            { this.state.hasSavingError && <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                            { this.state.leagueAlreadyExists && <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Die ausgewählte Liga existiert bereits.' ) }</Alert> }

                        </Form>

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideLeagueModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.saveLeague() } disabled={ ! this.state.league.interfaceId || ( this.state.league.interfaceRequiresLeague ? ( ! this.state.league.interfaceLeagueId || ! this.state.league.interfaceSeasonId ) : ! this.state.league.leagueName ) || ! this.state.league.leagueType || ! this.state.league.refereeSystemId }>{ _( 'Liga hinzufügen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <Modal show={ this.state.importLeagueId !== null } onHide={ () => this.hideImportModal() } backdrop='static' centered scrollable>

                    <Modal.Header closeButton />

                    <Modal.Body className='lead text-center'>

                        {

                            this.state.isImporting ?

                                <p><Icon spin icon='spinner' /> { _( 'Spielplan wird importiert' ) }</p>

                            : this.state.hasImportingError ?

                                <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert>

                            : this.state.showImportSuccessMessage ?

                                <Alert variant='success'><Icon icon='check' /> { _( 'Der Spielplan wurde erfolgreich importiert.' ) }</Alert>

                            :

                                <p>

                                    <Icon icon='check' /> { _( 'Die Liga wurde erfolgreich hinzugefügt.' ) }

                                    <br /><br />

                                    { _( 'Möchten Sie den Spielplan der soeben hinzugefügten Liga importieren?' ) }

                                </p>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        {

                            this.state.showImportSuccessMessage ?

                                <Button onClick={ () => this.hideImportModal() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                            :

                                <>

                                    <Button onClick={ () => this.hideImportModal() } disabled={ this.state.isImporting } variant='secondary'>{ _( 'Jetzt nicht' ) }</Button>
                                    <Button onClick={ () => this.state.league.interfaceRequiresLeague ? this.importGames() : this.props.history.push( '/liga/' + this.state.importLeagueId ) } disabled={ this.state.isImporting }>{ _( 'Ja, jetzt importieren' ) }</Button>

                                </>

                        }

                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( LeagueManagement )