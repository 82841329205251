import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Button, ButtonGroup, Card, Col, Dropdown, DropdownButton, FloatingLabel, Form, Modal, Row, Toast, ToastContainer } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Select from 'react-select'
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend as DnDBackend } from 'react-dnd-html5-backend'
import RefereeInfoCard from '../elements/RefereeInfoCard'
import GameInfoCard from '../elements/GameInfoCard'
import RefereeBadge from '../elements/RefereeBadge'
import { createDate, fillDate, formatDate, formatDateInput, formatDuration, formatTime, stripDate } from '../../util/date'
import ScheduleGameRow from '../elements/ScheduleGameRow'
import { refereeCategories } from '../../util/enum'
import TreeSelect from '../controls/TreeSelect'
import { cancelEvent, convertLeagues, deepCopy, getDateRangePickerLocale, getGameTitle, getRefereeFullName, sortByKey, sortByLastnameAndFirstname, sortByName } from '../../util'
import { getAbsoluteHeight, getStyleProperty } from '../../util/dom'
import { ASSIGNMENT_STATUS_DECLINED, AVAILABLE_REFEREES_SORT_BY_OPTION_NAME, AVAILABLE_REFEREES_SORT_BY_OPTION_SEASON_COUNT, AVAILABLE_REFEREES_SORT_BY_OPTION_ZIP_CODE, GAME_INFO_CARD_TYPE_GAME, GAME_INFO_CARD_TYPE_REFEREE, KEY_BACKSPACE, KEY_DELETE, KEY_DOWN, KEY_LEFT, KEY_LETTER_A, KEY_LETTER_AUML, KEY_LETTER_OUML, KEY_LETTER_SZLIG, KEY_LETTER_UUML, KEY_LETTER_Z, KEY_RIGHT, KEY_UP, PRIV_EXPORT_SCHEDULE_XLS, PRIV_LOAD_SCHEDULEABLE_REGIONS, REFEREE_AVAILABILITY_ALREADY_ASSIGNED, REFEREE_AVAILABILITY_AVAILABLE, REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT, REFEREE_CATEGORY_A, REFEREE_CATEGORY_B, REFEREE_CATEGORY_C } from '../../util/constants'
import { Prompt, withRouter } from 'react-router-dom'
import { hasPrivilege } from '../../util/permissions'
import AvailableRefereesTools from '../../containers/elements/AvailableRefereesTools'
import { getCustomer } from '../../util/customer'

class Schedule extends React.Component {

    #arrowKeys
    #commonlyNeededReferees
    #customer
    #enableRegions
    #mergedGamesByDate
    #mergedGames
    #specialKeys

    constructor( props ) {

        super( props )

        this.state = {

            absenceReasons:                     null,
            activeGame:                         null,
            activeNeededRefereeIndex:           null,
            activeReferee:                      null,
            aiSuggestionModalOpen:              false,
            availableRefereesCardOpen:          false,
            distancePossibilityData:            null,
            divisions:                          null,
            federations:                        null,
            gameInfoCardOpen:                   false,
            gameInfoCardType:                   null,
            games:                              null,
            gamesContainerHeight:               null,
            hasExportingScheduleXlsError:       false,
            hasLoadingAISuggestionError:        false,
            hasLoadingDistancePossibilityError: false,
            hasLoadingGamesAndRefereesError:    false,
            hasLoadingError:                    false,
            hasSavingError:                     false,
            isLoadingAISuggestion:              false,
            isLoadingDistancePossibility:       false,
            isLoadingGamesAndReferees:          false,
            isSaving:                           false,
            leagues:                            null,
            refereeAutoComplete:                '',
            refereeInfoCardOpen:                false,
            refereeInfoCardNotesModalOpen:      false,
            referees:                           null,
            refereesByLeague:                   null,
            refereeSystemsByLeague:             null,
            regions:                            null,
            selectedFederationId:               0,
            selectedRefereeId:                  0,
            setFederationModalOpen:             false,
            setRefereeModalOpen:                false,
            showSaveSuccessMessage:             false,

        }

        this.#customer                = getCustomer()
        this.#enableRegions           = hasPrivilege( props.user, PRIV_LOAD_SCHEDULEABLE_REGIONS )
        this.#arrowKeys               = [ KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN ]
        this.#specialKeys             = [ KEY_LETTER_SZLIG, KEY_LETTER_AUML, KEY_LETTER_OUML, KEY_LETTER_UUML ]
        this.handleDocumentClick      = this.handleDocumentClick.bind( this )
        this.handleKeyUp              = this.handleKeyUp.bind(         this )
        this.handleWindowResize       = this.handleWindowResize.bind(  this )
        this.checkAISuggestionLoading = this.checkAISuggestionLoading.bind( this )

    }

    componentDidMount() {

        this.calculateGameContainerHeight()
        this.load()

        document.addEventListener( 'click',        this.handleDocumentClick      )
        document.addEventListener( 'keyup',        this.handleKeyUp              )
        window.addEventListener(   'resize',       this.handleWindowResize       )
        window.addEventListener(   'beforeunload', this.checkAISuggestionLoading )

    }

    componentWillUnmount() {

        document.removeEventListener( 'click',        this.handleDocumentClick      )
        document.removeEventListener( 'keyup',        this.handleKeyUp              )
        window.removeEventListener(   'resize',       this.handleWindowResize       )
        window.removeEventListener(   'beforeunload', this.checkAISuggestionLoading )

    }

    addLocalChange( neededRefereeIndex, assignment ) {

        if ( this.state.isSaving || ! this.state.activeGame || ! this.isGameEditable( this.state.activeGame ) ) {

            return

        }

        const gameId          = this.state.activeGame.Id
        const gameChanges     = ( gameId in this.props.localChanges ) ? this.props.localChanges[ gameId ] : {}
        const neededReferee   = this.#commonlyNeededReferees[ neededRefereeIndex ]
        const assignmentIndex = neededReferee && this.state.activeGame.MapRefereeGames && this.state.activeGame.MapRefereeGames.findIndex( e => e.RefereeSystemJob && e.RefereeSystemJob.LicenseTypeId === neededReferee.LicenseTypeId && e.RefereeSystemJob.ShortName === neededReferee.ShortName )

        if ( assignmentIndex !== -1 ) {

            gameChanges[ assignmentIndex ] = { Status: 0, ...( gameChanges[ assignmentIndex ] || {} ), ...assignment }

            this.props.onLocalChangesChange( { ...this.props.localChanges, [ gameId ]: gameChanges } )

        } else {

            console.warn( 'Inconsistent data! No assignment found for needed referee in game [' + gameId + '].' )

        }

    }

    assignReferee( referee ) {

        if ( ! this.state.activeGame || formatDateInput( this.state.activeGame.ScheduleDate ) !== referee.gameDate || this.state.activeNeededRefereeIndex === null ) {

            return

        }

        const neededReferee  = this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ]
        const gameAssignment = neededReferee && this.state.activeGame.MapRefereeGames && this.state.activeGame.MapRefereeGames.find( e => e.RefereeSystemJob && e.RefereeSystemJob.LicenseTypeId === neededReferee.LicenseTypeId && e.RefereeSystemJob.ShortName === neededReferee.ShortName )

        if ( gameAssignment && ( ! gameAssignment.Released || gameAssignment.Status === ASSIGNMENT_STATUS_DECLINED || this.state.activeGame.forceEdit ) ) {

            this.addLocalChange( this.state.activeNeededRefereeIndex, {

                FederationId: null,
                Force:        false,
                RefereeId:    referee.Id,

            } )

            this.setState( { refereeAutoComplete: '' } )

        }

    }

    assignRefereeFromModal() {

        this.assignReferee( this.state.activeReferee.referee )

        this.toggleAvailableRefereesCard()

    }

    calculateGameContainerHeight() {

        let gamesContainerHeight = 0

        if ( window.innerWidth > 1398 ) {

            const pageHeaderHeight                    = getAbsoluteHeight( '.page-header' )
            const pageFooterHeight                    = getAbsoluteHeight( '.page-footer' )
            const pageContentPaddingTop               = Number( getStyleProperty( '.page-content', 'padding-top' ).replace( /\D+/g, '' ) )
            const pageContentPaddingBottom            = Number( getStyleProperty( '.page-content', 'padding-bottom' ).replace( /\D+/g, '' ) )
            const subHeaderHeight                     = getAbsoluteHeight( '.subheader' )
            const scheduleHeaderHeight                = getAbsoluteHeight( '.schedule-header' )
            const scheduleGamesHeaderContainerHeight  = getAbsoluteHeight( '.schedule-games-header-container' )

            gamesContainerHeight = window.innerHeight
                                    - pageHeaderHeight
                                    - pageFooterHeight
                                    - pageContentPaddingTop
                                    - pageContentPaddingBottom
                                    - subHeaderHeight
                                    - scheduleHeaderHeight
                                    - scheduleGamesHeaderContainerHeight

        }

        this.setState( { gamesContainerHeight } )

    }

    changeGame( game ) {

        const index = this.state.games.findIndex( e => e.Id === game.Id )
        const games = [ ...this.state.games ]

        games[ index ] = game

        this.setState( { games } )

    }

    checkAISuggestionLoading( e ) {

        if ( this.state.isLoadingAISuggestion ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Der KI-Vorschlag wird erstellt.' )

        }

    }

    clearLocalChanges() {

        this.props.onLocalChangesChange( {} )

    }

    dismissExportingScheduleXlsError() {

        this.setState( { hasExportingScheduleXlsError: false } )

    }

    dismissLoadingGamesAndRefereesError() {

        this.setState( { hasLoadingGamesAndRefereesError: false } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    errorLoadingAISuggestion() {

        this.setState( { hasLoadingAISuggestionError: true } )

    }

    errorLoadingDistancePossibility() {

        this.setState( { hasLoadingDistancePossibilityError: true } )

    }

    errorLoadingGamesAndReferees() {

        this.setState( { hasLoadingGamesAndRefereesError: true } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    getAvailableReferees( options ) {

        const refereesPerGameDate = {}

        options.gameDates.forEach( gameDate => {

            refereesPerGameDate[ gameDate ] = this.getAvailableRefereesOfGameDate( { ...options, gameDate } )

        } )

        return refereesPerGameDate

    }

    getAvailableRefereesOfGameDate( options ) {

        const gamesOnGameDate       = this.#mergedGamesByDate[ options.gameDate ]
        const leagueTypesOnGameDate = []
        const availableReferees     = []
        const requiredLicenseTypes  = []
        const filterName            = this.props.availableRefereesFilterName ? this.props.availableRefereesFilterName.toLowerCase() : null
        const filterZipCode         = this.props.availableRefereesFilterZipCode ? this.props.availableRefereesFilterZipCode.toLowerCase() : null
          let ignoreLicenseType     = false

        if ( this.state.activeGame ) {

            leagueTypesOnGameDate.push( this.state.activeGame.LeagueType )

            if ( this.state.activeNeededRefereeIndex !== null ) {

                const commonlyNeededReferee = this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ]
                const refereeSystem         = this.state.refereeSystemsByLeague[ this.state.activeGame.LeagueId ]
                const neededReferee         = refereeSystem && refereeSystem.NeededRefList && refereeSystem.NeededRefList.find( e => e.LicenseTypeId === commonlyNeededReferee.LicenseTypeId && e.ShortName === commonlyNeededReferee.ShortName )

                if ( neededReferee ) {

                    ignoreLicenseType = neededReferee.IgnoreLicenseType

                    requiredLicenseTypes.push( neededReferee.LicenseTypeId )

                }

            } else {

                this.state.activeGame.MapRefereeGames && this.state.activeGame.MapRefereeGames.forEach( e => e.RefereeSystemJob && requiredLicenseTypes.indexOf( e.RefereeSystemJob.LicenseTypeId ) === -1 && requiredLicenseTypes.push( e.RefereeSystemJob.LicenseTypeId ) )

            }

        } else {

            gamesOnGameDate.forEach( game => {

                ! leagueTypesOnGameDate.find( e => e === game.LeagueType ) && leagueTypesOnGameDate.push( game.LeagueType )

                game.MapRefereeGames && game.MapRefereeGames.forEach( e => e.RefereeSystemJob && requiredLicenseTypes.indexOf( e.RefereeSystemJob.LicenseTypeId ) === -1 && requiredLicenseTypes.push( e.RefereeSystemJob.LicenseTypeId ) )

            } )

        }

        leagueTypesOnGameDate.forEach( leagueType => {

            const leagueReferees = this.state.refereesByLeague && this.state.refereesByLeague[ leagueType ]

            leagueReferees && leagueReferees.forEach( refereeId => {

                const referee = this.state.referees.find( e => e.Id === refereeId )
                let anyway    = false

                // Referee is in LeagueTypeRefs but not in Referees
                if ( ! referee ) {

                    console.warn( 'Inconsistent data! Referee ID [' + refereeId + '] found in LeagueTypeRefs but not in Referees.' )

                    return

                }

                // Referee does not meet the AvailableRefereesTools name filter
                if ( filterName && referee.Firstname.toLowerCase().indexOf( filterName ) === -1 && referee.Lastname.toLowerCase().indexOf( filterName ) === -1 ) {

                    return

                }

                // Referee does not meet the AvailableRefereesTools ZIP code filter
                if ( filterZipCode && ( ! referee.Address || ! referee.Address.PostalCode || referee.Address.PostalCode.toLowerCase().indexOf( filterZipCode ) !== 0 ) ) {

                    return

                }

                // Referee is blocked for one of the game's teams
                if ( this.state.activeGame && referee.RefereeBans && referee.RefereeBans.find( e => e.Team && ( ( this.state.activeGame.HomeTeam && e.Team.Id === this.state.activeGame.HomeTeam.Id ) || ( this.state.activeGame.AwayTeam && e.Team.Id === this.state.activeGame.AwayTeam.Id ) ) ) ) {

                    if ( options.availability === REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT ) {

                        anyway = true

                    } else {

                        return

                    }

                }

                // Referee is already assigned on the given game date (in another league, so it is not visible in UI)
                if ( referee.RefereeGames && referee.RefereeGames.find( e => formatDateInput( e ) === options.gameDate ) ) {

                    if ( options.availability === REFEREE_AVAILABILITY_ALREADY_ASSIGNED ) {

                        anyway = true

                    } else {

                        return

                    }

                }

                // Referee is already assigned on the given game date (visible in UI)
                if ( gamesOnGameDate && gamesOnGameDate.find( game => game.MapRefereeGames && game.MapRefereeGames.find( e => e.RefereeId === referee.Id ) ) ) {

                    if ( options.availability === REFEREE_AVAILABILITY_ALREADY_ASSIGNED ) {

                        anyway = true

                    } else {

                        return

                    }

                }

                // Referee is not available on the given game date (and time)
                if ( this.refereeIsAbsent( referee, createDate( options.gameDate ), this.state.activeGame ) ) {

                    if ( options.availability === REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT ) {

                        anyway = true

                    } else {

                        return

                    }

                }

                // Referee is not available in the region of the game
                if ( this.#enableRegions && this.state.activeGame && this.state.activeGame.RegionPrefix && ( ! referee.RefereeRegions || ! referee.RefereeRegions.find( e => e.Prefix === this.state.activeGame.RegionPrefix ) ) ) {

                    return

                }

                // Ignore license type: add referee to category A
                if ( ignoreLicenseType ) {

                    return options.category === REFEREE_CATEGORY_A && availableReferees.push( { ...referee, classification: 'none', gameDate: options.gameDate } )

                }

                // Check if referee has a required license
                let refereeClassification = false

                referee.LicenseTypes && referee.LicenseTypes.forEach( refereeLicense => {

                    if ( requiredLicenseTypes.indexOf ( refereeLicense.Id ) !== -1 ) {

                        if ( refereeLicense.Classifiable ) {

                            referee.MapLeagueReferees && referee.MapLeagueReferees.forEach( refereePermission => {

                                if ( refereePermission.LeagueType === leagueType ) {

                                    refereePermission.Classifications && refereePermission.Classifications.forEach( e => {

                                        if ( e.LicenseTypeId === refereeLicense.Id && e.Category === options.category ) {

                                            refereeClassification = e.Category

                                        }

                                    } )

                                }

                            } )

                        } else {

                            refereeClassification = 'none'

                        }

                    }

                } )

                // Ignore classification, check availability
                if ( options.availability ) {

                    if ( options.availability === REFEREE_AVAILABILITY_AVAILABLE || anyway ) {

                        return availableReferees.push( { ...referee, availability: options.availability, gameDate: options.gameDate } )

                    }

                } else {

                    refereeClassification !== false &&
                    ( refereeClassification !== 'none' || options.category === REFEREE_CATEGORY_A ) &&
                    availableReferees.push( { ...referee, classification: refereeClassification, gameDate: options.gameDate } )

                }

            } )

        } )

        if ( this.props.availableRefereesSortBy === AVAILABLE_REFEREES_SORT_BY_OPTION_NAME ) {

            sortByLastnameAndFirstname( availableReferees )

        } else if ( this.props.availableRefereesSortBy === AVAILABLE_REFEREES_SORT_BY_OPTION_ZIP_CODE ) {

            availableReferees.sort( ( a, b ) => {

                a = a.Address && a.Address.PostalCode ? a.Address.PostalCode : null
                b = b.Address && b.Address.PostalCode ? b.Address.PostalCode : null

                return a && b && a !== b ? ( a > b ? 1 : -1 ) : 0

            } )

        } else if ( this.props.availableRefereesSortBy === AVAILABLE_REFEREES_SORT_BY_OPTION_SEASON_COUNT ) {

            sortByKey( availableReferees, 'allSeasonCntTotal' )

        }

        return availableReferees

    }

    getGameInfoData() {

        if ( ! this.state.gameInfoCardType || ( ! this.state.activeGame && ! this.state.activeReferee ) ) {

            return null

        }

        const games               = []
        const neededReferees      = []
          let title               = null

        if ( this.state.gameInfoCardType === GAME_INFO_CARD_TYPE_GAME && this.state.activeGame ) {

                  title               = getGameTitle( this.state.activeGame )
            const activeNeededReferee = this.state.activeNeededRefereeIndex !== null ? this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ] : null
            const assignment          = activeNeededReferee && this.state.activeGame.MapRefereeGames && this.state.activeGame.MapRefereeGames.find( e => e.RefereeSystemJob && e.RefereeSystemJob.LicenseTypeId === activeNeededReferee.LicenseTypeId )
            const processGames        = e => {

                e.forEach( game => {

                    games.push( {

                        awayTeam:      game.AwayTeam && game.AwayTeam.ShortName,
                        homeTeam:      game.HomeTeam && game.HomeTeam.ShortName,
                        referees:      game.MapRefereeGames,
                        scheduledDate: createDate( game.ScheduleDate ),

                    } )

                    assignment && game.MapRefereeGames && game.MapRefereeGames.forEach(

                        gameAssignment =>

                            neededReferees.findIndex(

                                neededReferee =>

                                    neededReferee.RefereeSystemLicenseTypeId === gameAssignment.RefereeSystemJob.LicenseTypeId &&
                                    neededReferee.RefereeSystemJob === gameAssignment.RefereeSystemJob.ShortName

                            ) === -1 &&

                            gameAssignment.RefereeSystemJob.LicenseTypeId === assignment.RefereeSystemJob.LicenseTypeId &&

                            neededReferees.push( { RefereeSystemLicenseTypeId: gameAssignment.RefereeSystemJob.LicenseTypeId, RefereeSystemJob: gameAssignment.RefereeSystemJob.ShortName } )

                    )

                } )

            }

            this.state.activeGame.AwayTeam && this.state.activeGame.AwayTeam.lastGames && processGames( this.state.activeGame.AwayTeam.lastGames )
            this.state.activeGame.HomeTeam && this.state.activeGame.HomeTeam.lastGames && processGames( this.state.activeGame.HomeTeam.lastGames )

        } else if ( this.state.gameInfoCardType === GAME_INFO_CARD_TYPE_REFEREE && this.state.activeReferee ) {

            title = getRefereeFullName( this.state.activeReferee.referee )

            this.state.activeReferee.referee.LastRefereeGames && this.state.activeReferee.referee.LastRefereeGames.forEach( game => {

                games.push( {

                    awayTeam:      game.AwayTeam,
                    homeTeam:      game.HomeTeam,
                    scheduledDate: createDate( game.ScheduleDate ),

                } )

            } )

        }

        games.sort( ( a, b ) => b.scheduledDate.getTime() - a.scheduledDate.getTime() )

        return { games, neededReferees, title }

    }

    getMergedGames() {

        const games = this.state.games ? deepCopy( this.state.games ) : null

        return games && games.map( game => {

            if ( game.Id in this.props.localChanges ) {

                const gameChanges = this.props.localChanges[ game.Id ]

                Object.keys( gameChanges ).forEach( assignmentIndex => {

                    game.MapRefereeGames[ assignmentIndex ] = { ...game.MapRefereeGames[ assignmentIndex ], ...gameChanges[ assignmentIndex ] }

                } )

            }

            return game

        } )

    }

    handleDateRangeSelect( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null,

        )

    }

    handleDismissLocalChangesClick() {

        if ( window.confirm( _( 'Änderungen verwerfen?' ) ) ) {

            this.clearLocalChanges()

        }

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

    }

    handleDocumentClick( e ) {

        // TODO Activate?
        // this.state.activeReferee && ! this.state.refereeInfoCardNotesModalOpen && e.target.className.indexOf( 'referee-badge' ) === -1 && this.setState( { activeReferee: null, gameInfoCardType: null } )

    }

    handleExportScheduleXls() {

        this.setState( { hasExportingScheduleXlsError: false }, () => {

            const init = {

                method: 'POST',
                body:   new FormData(),

            }

            init.body.append( 'from',  this.props.dateRangeFrom.toJSON() )
            init.body.append( 'to',    this.props.dateRangeTo.toJSON()   )
            init.body.append( 'token', this.props.token                  )

            this.props.divisions.forEach( e => init.body.append( 'divIds', e ) )

            this.#enableRegions && this.props.regions.forEach( e => init.body.append( 'regionIds', e ) )

            fetch( getDataUrl( 'api/Schedule/downloadExcelSchedule' ), init )
                .then( res => res.blob() )
                // .then( blob => window.location.assign( window.URL.createObjectURL( new File( [ blob ], _( 'Einteilung.xlsx' ) ) ) ) )
                .then( blob => window.location.assign( window.URL.createObjectURL( blob ) ) )
                .catch( () => this.setState( { hasExportingScheduleXlsError: true } ) )

        } )

    }

    handleFederationSelect( e ) {

        this.setState( { selectedFederationId: Number( e.target.value ) } )

    }

    handleForceEditGameClick( activeGame ) {

        const games = deepCopy( this.state.games )
        const game  = games.find( e => e.Id === activeGame.Id )

        if ( game ) {

            game.forceEdit = true

        }

        this.setState( { games } )

    }

    handleGameClick( activeGame ) {

        this.setState( { activeGame, gameInfoCardType: GAME_INFO_CARD_TYPE_GAME } )

    }

    handleKeyUp( e ) {

        if ( document.activeElement !== document.body ) {

            return

        }

        if ( this.#arrowKeys.includes( e.which ) ) {

            cancelEvent( e )

            if ( ! this.state.activeGame ) {

                this.state.games && this.state.games.length && this.setState( { activeGame: this.state.games[ 0 ], gameInfoCardType: GAME_INFO_CARD_TYPE_GAME }, () => this.setActiveNeededRefereeIndex( 0 ) )

            } else if ( this.state.activeNeededRefereeIndex === null ) {

                this.setActiveNeededRefereeIndex( 0 )

            } else if ( ! this.state.refereeSystemsByLeague || ! this.state.games ) {

                return

            } else {

                if ( e.which === KEY_LEFT ) {

                    if ( this.state.activeNeededRefereeIndex === 0 ) {

                        return

                    }

                    const activeGameNeededReferees = this.state.refereeSystemsByLeague[ this.state.activeGame.LeagueId ].NeededRefList

                    if ( ! activeGameNeededReferees ) {

                        return

                    }

                    for ( let i = this.state.activeNeededRefereeIndex - 1; i >= 0; i-- ) {

                        const commonlyNeededReferee = this.#commonlyNeededReferees[ i ]

                        if ( commonlyNeededReferee && activeGameNeededReferees.find( e => e.ShortName === commonlyNeededReferee.ShortName && e.LicenseTypeId === commonlyNeededReferee.LicenseTypeId ) ) {

                            return this.setActiveNeededRefereeIndex( i )

                        }

                    }

                } else if ( e.which === KEY_UP ) {

                    // TODO Needs fix. Does not work when previous game does not have the same referee system

                    const currentGameIndex      = this.state.games.findIndex( e => e.Id === this.state.activeGame.Id )
                    const commonlyNeededReferee = this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ]

                    if ( currentGameIndex === -1 || currentGameIndex === 0 || ! commonlyNeededReferee ) {

                        return

                    }

                    for ( let i = currentGameIndex - 1; i >= 0; i-- ) {

                        const game               = this.state.games[ i ]
                        const gameNeededReferees = this.state.refereeSystemsByLeague[ game.LeagueId ].NeededRefList

                        if ( commonlyNeededReferee && gameNeededReferees.find( e => e.ShortName === commonlyNeededReferee.ShortName && e.LicenseTypeId === commonlyNeededReferee.LicenseTypeId ) ) {

                            return this.setState( { activeGame: game, gameInfoCardType: GAME_INFO_CARD_TYPE_GAME, refereeAutoComplete: '' } )

                        }

                    }

                } else if ( e.which === KEY_RIGHT ) {

                    if ( this.state.activeNeededRefereeIndex + 1 === this.#commonlyNeededReferees.length ) {

                        return

                    }

                    const activeGameNeededReferees = this.state.refereeSystemsByLeague[ this.state.activeGame.LeagueId ].NeededRefList

                    if ( ! activeGameNeededReferees ) {

                        return

                    }

                    for ( let i = this.state.activeNeededRefereeIndex + 1; i < this.#commonlyNeededReferees.length; i++ ) {

                        const commonlyNeededReferee = this.#commonlyNeededReferees[ i ]

                        if ( commonlyNeededReferee && activeGameNeededReferees.find( e => e.ShortName === commonlyNeededReferee.ShortName && e.LicenseTypeId === commonlyNeededReferee.LicenseTypeId ) ) {

                            return this.setActiveNeededRefereeIndex( i )

                        }

                    }

                } else if ( e.which === KEY_DOWN ) {

                    // TODO Needs fix. Does not work when previous game does not have the same referee system

                    const currentGameIndex      = this.state.games.findIndex( e => e.Id === this.state.activeGame.Id )
                    const commonlyNeededReferee = this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ]

                    if ( currentGameIndex === -1 || currentGameIndex + 1 === this.state.games.length || ! commonlyNeededReferee ) {

                        return

                    }

                    for ( let i = currentGameIndex + 1; i < this.state.games.length; i++ ) {

                        const game               = this.state.games[ i ]
                        const gameNeededReferees = this.state.refereeSystemsByLeague[ game.LeagueId ].NeededRefList

                        if ( commonlyNeededReferee && gameNeededReferees.find( e => e.ShortName === commonlyNeededReferee.ShortName && e.LicenseTypeId === commonlyNeededReferee.LicenseTypeId ) ) {

                            return this.setState( { activeGame: game, gameInfoCardType: GAME_INFO_CARD_TYPE_GAME, refereeAutoComplete: '' } )

                        }

                    }

                }

            }

        } else if ( ( ( e.which >= KEY_LETTER_A && e.which <= KEY_LETTER_Z ) || this.#specialKeys.includes( e.which ) ) && this.state.refereeAutoComplete.length < 6 ) {

            this.setState( { refereeAutoComplete: this.state.refereeAutoComplete + e.key } )

        } else if ( e.which === KEY_BACKSPACE && this.state.refereeAutoComplete ) {

            this.setState( { refereeAutoComplete: this.state.refereeAutoComplete.slice( 0, this.state.refereeAutoComplete.length - 1 ) } )

        } else if ( e.which === KEY_DELETE ) {

            if ( this.state.activeNeededRefereeIndex !== null ) {

                const neededReferee  = this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ]
                const gameAssignment = neededReferee && this.state.activeGame.MapRefereeGames && this.state.activeGame.MapRefereeGames.find( e => e.RefereeSystemJob && e.RefereeSystemJob.LicenseTypeId === neededReferee.LicenseTypeId && e.RefereeSystemJob.ShortName === neededReferee.ShortName )

                if ( gameAssignment && ( ! gameAssignment.Released || gameAssignment.Status === ASSIGNMENT_STATUS_DECLINED || this.state.activeGame.forceEdit ) ) {

                    this.unassign( this.state.activeNeededRefereeIndex )

                }

            }

        }

    }

    handleNeededRefereeClick( e ) {

        this.setActiveNeededRefereeIndex( e )

    }

    handleRefereeClick( referee, uid ) {

        this.setState( { activeReferee: { referee, uid }, gameInfoCardType: GAME_INFO_CARD_TYPE_REFEREE } )

    }

    handleRefereeSelect( e ) {

        this.setState( { selectedRefereeId: Number( e.target.value ), isLoadingDistancePossibility: false, hasLoadingDistancePossibilityError: false, distancePossibilityData: null }, () => {

            if ( this.state.selectedRefereeId ) {

                const games          = this.getMergedGames()
                const gameDate       = formatDateInput( this.state.activeGame.ScheduleDate )
                  let alreadyPlanned = null
                  let gameToSet      = null

                if ( games ) {

                    games.forEach( game => {

                        if ( formatDateInput( game.ScheduleDate ) === gameDate ) {

                            game.MapRefereeGames.forEach( e => {

                                if ( ! alreadyPlanned && e.RefereeId === this.state.selectedRefereeId ) {

                                    alreadyPlanned = e

                                }

                            } )

                        }

                    } )

                    const neededReferee   = this.#commonlyNeededReferees[ this.state.activeNeededRefereeIndex ]
                    const assignmentIndex = neededReferee && this.state.activeGame.MapRefereeGames && this.state.activeGame.MapRefereeGames.findIndex( e => e.RefereeSystemJob && e.RefereeSystemJob.LicenseTypeId === neededReferee.LicenseTypeId && e.RefereeSystemJob.ShortName === neededReferee.ShortName )

                    gameToSet = this.state.activeGame.MapRefereeGames[ assignmentIndex ]

                }

                if ( alreadyPlanned ) {

                    this.setState( { isLoadingDistancePossibility: true }, () => {

                        setTimeout( () => {

                            ajax( getDataUrl( 'api/Schedule/checkDistancePossibility' ), { alreadyPlannedJson: JSON.stringify( alreadyPlanned ), gameToSetJson: JSON.stringify( gameToSet ), token: this.props.token }, { method: 'POST' } )
                                .then( e => e.StatusId > 0 && e.Data ? this.setState( { distancePossibilityData: e.Data[ 0 ] } ) : this.errorLoadingDistancePossibility() )
                                .catch( () => this.errorLoadingDistancePossibility() )
                                .finally( () => this.setState( { isLoadingDistancePossibility: false } ) )

                        }, 500 )

                    } )

                }

            }

        } )

    }

    handleRegionsSelect( e ) {

        this.props.onRegionsChange( e ? e.map( f => f.value ) : [] )

    }

    handleSetFederationClick() {

        this.setState( { setFederationModalOpen: true } )

    }

    handleSetRefereeClick() {

        this.setState( { setRefereeModalOpen: true, selectedRefereeId: 0, isLoadingDistancePossibility: false, hasLoadingDistancePossibilityError: false, distancePossibilityData: null } )

    }

    handleWindowResize() {

        this.calculateGameContainerHeight()

    }

    hideSetFederationModal() {

        this.setState( { setFederationModalOpen: false } )

    }

    hideSetRefereeModal() {

        this.setState( { setRefereeModalOpen: false } )

    }

    isGameEditable( game ) {

        const league = this.state.leagues && this.state.leagues.find( league => league.Id === game.LeagueId )

        return league ? league.Editable : false

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const setRegions = ( regions, resolve ) => {

                sortByName( regions )

                this.setState( { regions: regions.map( e => { return { label: e.Name, value: e.Id } } ) }, resolve )

            }

            const itemsToLoad = [

                this.loadItems( { url: 'api/Absence/GetReasons',              name: 'absenceReasons'                                                                                                     } ),
                this.loadItems( { url: 'api/Schedule/getScheduleableLeagues',                        success: ( e, resolve ) => this.setState( { leagues: e, divisions: convertLeagues( e ) }, resolve ) } ),

            ]

            this.#enableRegions && itemsToLoad.push( this.loadItems( { url: 'api/Schedule/getScheduleableRegions', success: setRegions } ) )

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleIsLoading( false ) )

        }, 400 ) )

    }

    loadAISuggestion() {

        this.setState( { isLoadingAISuggestion: true, hasLoadingAISuggestionError: false }, () => {

            const data = {

                divIds: { __arr: true, values: this.props.divisions },
                from:   this.props.dateRangeFrom.toJSON(),
                to:     this.props.dateRangeTo.toJSON(),
                token:  this.props.token,

            }

            const success = games => {

                const localChanges = {}

                games.forEach( game => {

                    game.MapRefereeGames && game.MapRefereeGames.forEach( ( e, i ) => {

                        if ( ! e.RefereeId ) {

                            return

                        }

                        if ( ! ( game.Id in localChanges ) ) {

                            localChanges[ game.Id ] = {}

                        }

                        localChanges[ game.Id ][ i ] = e

                    } )

                } )

                this.toggleAISuggestionModal( false )

                this.props.onLocalChangesChange( localChanges )

            }

            ajax( getDataUrl( 'api/Schedule/getSuggestion' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data[ 0 ] && e.Data[ 0 ].Games ? success( e.Data[ 0 ].Games ) : this.errorLoadingAISuggestion() )
                .catch( () => this.errorLoadingAISuggestion() )
                .finally( () => this.setState( { isLoadingAISuggestion: false } ) )



        } )

    }

    loadGamesAndReferees() {

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return

        }

        this.setState( { activeGame: null, activeReferee: null, gameInfoCardType: null, games: null, referees: null, hasLoadingGamesAndRefereesError: false, isLoadingGamesAndReferees: true }, () => {

            this.props.onToggleIsLoading( true )

            const data = {

                divIds: { __arr: true, values: this.props.divisions },
                from:   this.props.dateRangeFrom.toJSON(),
                to:     this.props.dateRangeTo.toJSON(),
                token:  this.props.token,

            }

            const success = e => {

                let refereeSystemsByLeague = {}

                e.Data[ 0 ].LeagueRefereeSystem && e.Data[ 0 ].LeagueRefereeSystem.forEach( refereeSystem => {

                    refereeSystemsByLeague = { ...refereeSystemsByLeague, ...refereeSystem }

                } )

                const referees = e.Data[ 0 ].Referees.map( referee => {

                    let allSeasonCntTotal = 0

                    referee.AllSeasonCnt && Object.keys( referee.AllSeasonCnt ).forEach( e => { allSeasonCntTotal += referee.AllSeasonCnt[ e ] } )

                    referee.allSeasonCntTotal = allSeasonCntTotal

                    return referee

                } )

                sortByLastnameAndFirstname( referees )

                this.setState( {

                    federations:      e.Data[ 0 ].Federations,
                    games:            e.Data[ 0 ].Games,
                    refereesByLeague: e.Data[ 0 ].LeagueTypeRefs,

                    referees, refereeSystemsByLeague,

                } )

            }

            if ( this.#enableRegions ) {

                data.regionIds = { __arr: true, values: this.props.regions }

            }

            ajax( getDataUrl( 'api/Schedule/getGamesAndRefs' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data.length ? success( e ) : this.errorLoadingGamesAndReferees() )
                .catch( () => this.errorLoadingGamesAndReferees() )
                .finally( () => this.setState( { isLoadingGamesAndReferees: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data, resolve ) : this.setState( { [ options.name ]: e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    refereeIsAbsent( referee, date, checkTime ) {

        if ( ! referee.Absences ) {

            return false

        }

        return referee.Absences.find( absence => {

            // TODO Implement all checks
            // - Recurring events
            // - checkTime

            const dateTimestamp = date.getTime()
            const from          = createDate( absence.From )
            const to            = createDate( absence.To   )

            if ( absence.FullDay || ! checkTime ) {

                stripDate( from )
                fillDate(  to   )

            }

            if ( from.getTime() <= dateTimestamp && to.getTime() >= dateTimestamp ) {

                return true

            }

            return false

        } )

    }

    save() {

        const data = []

        const success = () => {

            this.setState(

                {

                    games:                  this.getMergedGames(),
                    showSaveSuccessMessage: true,

                },

                () => {

                    this.clearLocalChanges()

                    setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 )

                }

            )

        }

        Object.keys( this.props.localChanges ).forEach( gameId => {

                  gameId      = Number( gameId )
            const gameChanges = this.props.localChanges[ gameId ]
            const game        = this.state.games && this.state.games.find( e => e.Id === gameId )

            if ( ! game ) {

                return

            }

            Object.keys( gameChanges ).forEach( assignmentIndex => { data.push( { ...game.MapRefereeGames[ assignmentIndex ], ...gameChanges[ assignmentIndex ] } ) } )

        } )

        this.setState( { hasSavingError: false, isSaving: true }, () => {

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Schedule/saveRefSchedule' ), { token: this.props.token, mapRefereeGameListjson: JSON.stringify( data ) }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    setFederation() {

        const federationId = this.state.selectedFederationId

        this.setState( { setFederationModalOpen: false }, () => this.addLocalChange( this.state.activeNeededRefereeIndex, {

            FederationId: federationId,
            Force:        false,
            RefereeId:    null,

        } ) )

    }

    setActiveNeededRefereeIndex( activeNeededRefereeIndex ) {

        this.setState( { activeNeededRefereeIndex, refereeAutoComplete: '' } )

    }

    setReferee() {

        this.setState( { setRefereeModalOpen: false }, () => this.addLocalChange( this.state.activeNeededRefereeIndex, {

            FederationId: null,
            Force:        true,
            RefereeId:    this.state.selectedRefereeId,

        } ) )

    }

    toggleAISuggestionModal( e ) {

        this.setState( { aiSuggestionModalOpen: e, isLoadingAISuggestion: false, hasLoadingAISuggestionError: false } )

    }

    toggleAvailableRefereesCard() {

        this.setState( {

            availableRefereesCardOpen: ! this.state.availableRefereesCardOpen,
            gameInfoCardOpen:          false,
            refereeInfoCardOpen:       false,

        } )

    }

    toggleNotShowByDefaultRefereesMode() {

        this.props.onToggleDisplayNotShowByDefaultRefereesIsEnabled( ! this.props.displayNotShowByDefaultRefereesIsEnabled )

    }

    toggleDivisionDisplayMode() {

        this.props.onToggleDivisionDisplayIsEnabled( ! this.props.divisionDisplayIsEnabled )

    }

    toggleGameInfoCard() {

        this.setState( {

            availableRefereesCardOpen: false,
            gameInfoCardOpen:          ! this.state.gameInfoCardOpen,
            refereeInfoCardOpen:       false,

        } )

    }

    toggleRefereeInfoCard() {

        this.setState( {

            availableRefereesCardOpen: false,
            gameInfoCardOpen:          false,
            refereeInfoCardOpen:       ! this.state.refereeInfoCardOpen,

        } )

    }

    toggleRefeeInfoCardNotesModal( e ) {

        this.setState( { refereeInfoCardNotesModalOpen: e } )

    }

    toggleRemote( neededRefereeIndex, checked ) {

        this.addLocalChange( neededRefereeIndex, { Remote: checked } )

    }

    toggleScheduleHeader() {

        this.props.onToggleScheduleHeaderIsHidden( ! this.props.scheduleHeaderIsHidden )

        setTimeout( () => this.calculateGameContainerHeight(), 200 )

    }

    unassign( neededRefereeIndex ) {

        this.addLocalChange( neededRefereeIndex, {

            FederationId: null,
            Force:        false,
            RefereeId:    null,

        } )

    }

    renderAvailabilityLabels() {

        return (

            <>

                <RefereeBadge className={ 'me-1 referee-badge-availability-' + REFEREE_AVAILABILITY_AVAILABLE         } displayName={ _( 'Verfügbar'          ) } low />
                <RefereeBadge className={ 'me-1 referee-badge-availability-' + REFEREE_AVAILABILITY_ALREADY_ASSIGNED  } displayName={ _( 'Bereits eingeteilt' ) } low />
                <RefereeBadge className={ 'me-1 referee-badge-availability-' + REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT } displayName={ _( 'Geblockt/Abwesend'  ) } low />

            </>

        )

    }

    renderAvailableReferees( options ) {

        return (

            <Col md={ 4 } className='px-1'>

                { options.availableReferees && options.availableReferees.map( ( referee, i ) => {

                    const refereeUid = 'available-referee-' + options.gameDate + '-' + referee.Id

                    return (

                        <span className='badge-margin' key={ i }>

                            <RefereeBadge
                                active={ this.state.activeReferee && this.state.activeReferee.referee.Id === referee.Id && this.state.activeReferee.uid === refereeUid }
                                className={ referee.classification === 'none' ? 'outline' : '' }
                                onClick={ ( referee, uid ) => this.handleRefereeClick( referee, uid ) }
                                onDoubleClick={ e => this.assignReferee( e ) }
                                referee={ referee }
                                selectable={ true }
                                uid={ refereeUid }
                            />

                        </span>

                    )

                } ) }

            </Col>

        )

    }

    renderGameDays( options ) {

        if ( this.state.isLoadingGamesAndReferees ) {

            return <div className='my-5 text-center'><Icon icon='spinner' spin /></div>

        }

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return <div className='my-5 text-center'>{ _( 'Zeitraum und Liga wählen, um Spiele zu laden.' ) }</div>

        }

        if ( ! this.state.games ) {

            return null

        }

        if ( ! this.state.games.length ) {

            return <div className='my-5 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

        }

        return (

            <div className={ 'schedule-games-container' + ( this.state.isSaving ? ' saving' : '' ) } style={ { height: this.state.gamesContainerHeight ? this.state.gamesContainerHeight + 'px' : 'unset' } }>

                { options.gameDates.map( ( gameDate, i ) => {

                    return (

                        <Row className='border-bottom' key={ i }>

                            <Col xl={ 8 } className='text-center'>

                                { this.renderGameRows( { ...options, gameDate } ) }

                            </Col>

                            <Col xl={ 4 } className='available-referees-container'>

                                <Card className='my-1 available-referees-card'>

                                    <Card.Body className='py-1'>

                                        <div className='available-referees'>

                                            <Row>

                                                { this.renderAvailableReferees( { gameDate, availableReferees: options.availableRefereesCol1[ gameDate ] } ) }
                                                { this.renderAvailableReferees( { gameDate, availableReferees: options.availableRefereesCol2[ gameDate ] } ) }
                                                { this.renderAvailableReferees( { gameDate, availableReferees: options.availableRefereesCol3[ gameDate ] } ) }

                                            </Row>

                                        </div>

                                    </Card.Body>

                                </Card>

                            </Col>

                        </Row>

                    )

                } ) }

                { this.state.isSaving && <div className='schedule-games-overlay'><div><Icon icon='spinner' spin /></div></div> }

            </div>

        )

    }

    renderGameRows( options ) {

        const assignedReferees = []

        return this.#mergedGamesByDate[ options.gameDate ].map( ( game, i ) => {

            const editable                 = this.isGameEditable( game )
            const isActive                 = this.state.activeGame && this.state.activeGame.Id === game.Id
            const assignmentAvailabilities = {}

            this.#customer.groupAvailableRefereesByAvailability && game.MapRefereeGames && game.MapRefereeGames.forEach( assignment => {

                if ( assignment.RefereeId ) {

                    const referee = this.state.referees.find( e => e.Id === assignment.RefereeId )

                    if ( referee ) {

                        // Referee is blocked for one of the game's teams
                        if ( referee.RefereeBans && referee.RefereeBans.find( e => e.Team && ( ( game.HomeTeam && e.Team.Id === game.HomeTeam.Id ) || ( game.AwayTeam && e.Team.Id === game.AwayTeam.Id ) ) ) ) {

                            assignmentAvailabilities[ assignment.Id ] = REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT

                        }

                        // Referee is not available on the given game date (and time)
                        if ( this.refereeIsAbsent( referee, createDate( options.gameDate ), true ) ) {

                            assignmentAvailabilities[ assignment.Id ] = REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT

                        }

                        // Referee is already assigned on the given game date (in another league, so it is not visible in UI)
                        if ( referee.RefereeGames && referee.RefereeGames.find( e => formatDateInput( e ) === options.gameDate ) ) {

                            assignmentAvailabilities[ assignment.Id ] = REFEREE_AVAILABILITY_ALREADY_ASSIGNED

                        }

                        // Referee is already assigned on the given game date (visible in UI)
                        else if ( assignedReferees.includes( referee.Id ) ) {

                            assignmentAvailabilities[ assignment.Id ] = REFEREE_AVAILABILITY_ALREADY_ASSIGNED

                        } else {

                            assignedReferees.push( referee.Id )

                        }

                        if ( ! ( assignment.Id in assignmentAvailabilities ) ) {

                            assignmentAvailabilities[ assignment.Id ] = REFEREE_AVAILABILITY_AVAILABLE

                        }

                    } else {

                        console.warn( 'Inconsistent data! Referee ID [' + assignment.RefereeId + '] assigned in Game [' + game.Id + '] but not found in Referees.' )

                    }

                }

            } )

            const props = {

                activeNeededRefereeIndex:      this.state.activeNeededRefereeIndex,
                activeReferee:                 this.state.activeReferee,
                availableReferees:             [],
                commonlyNeededReferees:        this.#commonlyNeededReferees,
                divisionDisplayIsEnabled:      this.props.divisionDisplayIsEnabled,
                federations:                   this.state.federations,
                key:                           i,
                localChanges:                  this.props.localChanges[ game.Id ],
                onChange:                      e => this.changeGame( e ),
                onClick:                       e => this.handleGameClick( e ),
                onForceEdit:                   e => this.handleForceEditGameClick( e ),
                onAvailableRefereeClick:       ( referee, uid ) => this.handleRefereeClick( referee, uid ),
                onAvailableRefereeDoubleClick: e => this.assignReferee( e ),
                onNeededRefereeClick:          e => this.handleNeededRefereeClick( e ),
                onRefereeClick:                ( referee, uid ) => this.handleRefereeClick( referee, uid ),
                onToggleRemote:                ( e, checked ) => this.toggleRemote( e, checked ),
                onUnassign:                    e => this.unassign( e ),
                refereeAutoComplete:           null,
                referees:                      this.state.referees,
                refereeSystem:                 this.state.refereeSystemsByLeague && this.state.refereeSystemsByLeague[ game.LeagueId ],
                refereeUidBase:                'assigned-referee-' + game.Id + '-',
                showDivisionColumn:            this.props.divisions.length > 1 || this.props.divisionDisplayIsEnabled,
                token:                         this.props.token,
                user:                          this.props.user,

                assignmentAvailabilities, editable, game, isActive,

            }

            if ( editable && isActive ) {

                const availableRefereesCol1 = options.availableRefereesCol1[ options.gameDate ]
                const availableRefereesCol2 = options.availableRefereesCol2[ options.gameDate ]
                const availableRefereesCol3 = options.availableRefereesCol3[ options.gameDate ]

                availableRefereesCol1 && props.availableReferees.push( ...availableRefereesCol1 )
                availableRefereesCol2 && props.availableReferees.push( ...availableRefereesCol2 )
                availableRefereesCol3 && props.availableReferees.push( ...availableRefereesCol3 )

                props.refereeAutoComplete = this.state.refereeAutoComplete

            }

            return <ScheduleGameRow { ...props } />

        } )

    }

    render() {

        const formDisabled                      = this.props.isLoading || this.state.hasLoadingError || this.state.isSaving
        const activeGameDate                    = this.state.activeGame ? formatDateInput( this.state.activeGame.ScheduleDate ) : null
        const gameInfoData                      = this.getGameInfoData()
        const hasLocalChanges                   = Object.keys( this.props.localChanges ).length !== 0
        const disableExportScheduleXls          = hasLocalChanges || ! this.props.divisions.length
        const gameDates                         = []
        this.#commonlyNeededReferees            = []
        this.#mergedGamesByDate                 = {}
        this.#mergedGames                       = this.getMergedGames()
        let regions                             = null
        let distancePossibilityTimeDifference   = null

        this.#mergedGames && this.#mergedGames.forEach( game => {

            const gameDate = formatDateInput( game.ScheduleDate )

            if ( ! ( gameDate in this.#mergedGamesByDate ) ) {

                this.#mergedGamesByDate[ gameDate ] = []

                gameDates.push( gameDate )

            }

            this.#mergedGamesByDate[ gameDate ].push( game )

        } )

        gameDates.sort()

        this.state.refereeSystemsByLeague && Object.keys( this.state.refereeSystemsByLeague ).forEach( leagueId => {

            const refereeSystem = this.state.refereeSystemsByLeague[ leagueId ]

            refereeSystem.NeededRefList.forEach( neededReferee => {

                if (

                    ! this.#commonlyNeededReferees.find( e => e.LicenseTypeId === neededReferee.LicenseTypeId && e.ShortName === neededReferee.ShortName ) &&

                    ( this.props.displayNotShowByDefaultRefereesIsEnabled || neededReferee.ShowByDefault )

                ) {

                    this.#commonlyNeededReferees.push( neededReferee )

                }

            } )

        } )

        if ( this.state.distancePossibilityData ) {

            distancePossibilityTimeDifference = createDate( this.state.distancePossibilityData.GameStart ).getTime() - createDate( this.state.distancePossibilityData.Arrival ).getTime()

        }

        if ( this.#enableRegions ) {

            regions = this.state.regions ? this.props.regions.map( regionId => {

                const region = this.state.regions.find( e => e.value === regionId )

                return { value: regionId, label: region ? region.label : regionId }

            } ) : []

        }

        const availableRefereesCol1 = this.getAvailableReferees( { gameDates, category: this.#customer.groupAvailableRefereesByAvailability ? null : REFEREE_CATEGORY_A, availability: this.#customer.groupAvailableRefereesByAvailability ? REFEREE_AVAILABILITY_AVAILABLE         : null } )
        const availableRefereesCol2 = this.getAvailableReferees( { gameDates, category: this.#customer.groupAvailableRefereesByAvailability ? null : REFEREE_CATEGORY_C, availability: this.#customer.groupAvailableRefereesByAvailability ? REFEREE_AVAILABILITY_ALREADY_ASSIGNED  : null } )
        const availableRefereesCol3 = this.getAvailableReferees( { gameDates, category: this.#customer.groupAvailableRefereesByAvailability ? null : REFEREE_CATEGORY_B, availability: this.#customer.groupAvailableRefereesByAvailability ? REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT : null } )

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='calendar' className='subheader-icon' /> { _( 'Einteilung' ) }

                    </h1>

                    <div className='subheader-block'>

                        <div className='d-flex'>

                            <Dropdown as={ ButtonGroup } align='end' className='me-1'>

                                <Button disabled={ ! hasLocalChanges } onClick={ () => this.save() }>{ _( 'Speichern' ) }</Button>

                                <Dropdown.Toggle split />

                                <Dropdown.Menu>

                                    { hasPrivilege( this.props.user, PRIV_EXPORT_SCHEDULE_XLS ) && <Dropdown.Item as='button' type='button' onClick={ () => this.handleExportScheduleXls() } disabled={ disableExportScheduleXls }>{ _( 'Als XLS exportieren' ) }</Dropdown.Item> }

                                    <Dropdown.Item as='button' type='button' onClick={ () => this.handleDismissLocalChangesClick() } disabled={ ! hasLocalChanges }>{ _( 'Änderungen verwerfen' ) }</Dropdown.Item>

                                </Dropdown.Menu>

                            </Dropdown>

                            <Button variant='secondary' className='me-1' onClick={ () => this.toggleAISuggestionModal( true ) }>{ _( 'KI-Vorschlag' ) }</Button>

                            <DropdownButton title={ <Icon icon='cog' /> } variant='outline-secondary' align='end'>

                                <Dropdown.Item as='button' type='button' onClick={ () => this.toggleScheduleHeader()               }><Icon icon={ this.props.scheduleHeaderIsHidden                   ? 'check' : '' } fw className='me-1' /> { _( 'Header verbergen'                      ) }</Dropdown.Item>
                                <Dropdown.Item as='button' type='button' onClick={ () => this.toggleDivisionDisplayMode()          }><Icon icon={ this.props.divisionDisplayIsEnabled                 ? 'check' : '' } fw className='me-1' /> { _( 'Divisions anzeigen'                    ) }</Dropdown.Item>
                                <Dropdown.Item as='button' type='button' onClick={ () => this.toggleNotShowByDefaultRefereesMode() }><Icon icon={ this.props.displayNotShowByDefaultRefereesIsEnabled ? 'check' : '' } fw className='me-1' /> { _( 'Weitere Besetzungen anzeigen' ) }</Dropdown.Item>

                            </DropdownButton>

                        </div>

                    </div>

                </div>

                <Form>

                    <Row className={ 'mt-5 schedule-header' + ( this.props.scheduleHeaderIsHidden ? ' d-none' : '' ) }>

                        <Col xl={ 2 } className='position-relative'>

                            <fieldset disabled={ formDisabled }>

                                <Row className='mb-2'>

                                    <Col>

                                        <FloatingLabel controlId='date-range' label={ _( 'Zeitraum' ) } className='mb-2'>

                                            <DateRangePicker initialSettings={ {

                                                endDate:   this.props.dateRangeTo,
                                                locale:    getDateRangePickerLocale(),
                                                startDate: this.props.dateRangeFrom,

                                            } } onApply={ ( e, f ) => this.handleDateRangeSelect( e, f ) }>

                                                <Form.Control />

                                            </DateRangePicker>

                                        </FloatingLabel>

                                        <FloatingLabel controlId='divisions' label={ _( 'Liga' ) } className='mb-2'>

                                            <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                                        </FloatingLabel>

                                        { this.#enableRegions &&

                                            <Select
                                                isDisabled={ formDisabled }
                                                isMulti
                                                noOptionsMessage={ () => _( 'Keine Optionen' ) }
                                                onChange={ e => this.handleRegionsSelect( e ) }
                                                options={ this.state.regions }
                                                placeholder={ _( 'Region' ) }
                                                value={ regions }
                                            />

                                        }

                                    </Col>

                                </Row>

                                <Row className='mb-2'>

                                    <Col>

                                        <Button onClick={ () => this.loadGamesAndReferees() } disabled={ ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length }>{ _( 'Spiele laden' ) }</Button>

                                    </Col>

                                </Row>

                            </fieldset>

                        </Col>

                        <Col xl={ 4 } className={ this.props.scheduleHeaderIsHidden ? 'd-xl-none' : '' }>

                            <GameInfoCard
                                className={ this.state.gameInfoCardOpen ? 'visible' : '' }
                                federations={ this.state.federations }
                                games={ gameInfoData && gameInfoData.games }
                                neededReferees={ gameInfoData && gameInfoData.neededReferees }
                                title={ gameInfoData && gameInfoData.title }
                            />

                        </Col>

                        <Col xl={ 6 } className={ this.props.scheduleHeaderIsHidden ? 'd-xl-none' : '' }>

                            <RefereeInfoCard
                                absenceReasons={ this.state.absenceReasons }
                                className={ this.state.refereeInfoCardOpen ? 'visible' : '' }
                                games={ this.state.games }
                                leagues={ this.state.leagues }
                                localChanges={ this.props.localChanges }
                                onNotesOpen={ e => this.toggleRefeeInfoCardNotesModal( e ) }
                                referee={ this.state.activeReferee && this.state.activeReferee.referee }
                            />

                        </Col>

                    </Row>

                    {/* <DndProvider backend={ DnDBackend }></DndProvider> */} {/* TODO Implement */}

                    <Row className='mt-3 mb-1 schedule-games-header-container text-center align-items-center d-none d-lg-flex'>

                        <Col xl={ 8 }>

                            <Row>

                                <Col lg={ 5 }>

                                    <Row className='schedule-games-header'>

                                        <Col>{ _( 'Datum'    ) }</Col>
                                        <Col className='narrow'>{ _( 'Spieltag' ) }</Col>
                                        <Col className='narrow'>{ _( 'Uhrzeit'  ) }</Col>

                                        { ( this.props.divisions.length > 1 || this.props.divisionDisplayIsEnabled ) && <Col className='narrow text-left'>{ this.props.divisionDisplayIsEnabled ? _( 'Division' ) : _( 'Liga' ) }</Col> }

                                        <Col className='narrow'>{ _( 'Teams'     ) }</Col>
                                        <Col className='narrow text-left'>{ _( 'Ort'      ) }</Col>

                                    </Row>

                                </Col>

                                <Col xs={ 1 }>{/* <Icon icon='star' regular /> */}</Col>

                                <Col lg={ 6 }>

                                    <Row className='schedule-games-header'>

                                        { this.#commonlyNeededReferees.map( ( e, i ) => <Col key={ i }>{ e.ShortName }</Col> ) }

                                    </Row>

                                </Col>

                            </Row>

                        </Col>

                        <Col xl={ 4 }>

                            <Row className='align-items-center'>

                                <Col xs={ 7 } className='text-left'>

                                    {

                                        this.#customer.groupAvailableRefereesByAvailability ?

                                            this.renderAvailabilityLabels()

                                        :

                                            refereeCategories.map( e => <span className='me-2 text-nowrap' key={ e }><RefereeBadge className={ 'referee-badge-classification-' + e } empty={ true } /> { e }</span> )

                                    }

                                </Col>

                                <Col xs={ 5 } className='text-right'>

                                    <div className='d-inline-flex'>

                                        <DropdownButton title={ _( 'Setzen' ) } variant='outline-secondary' size='sm' align='end' className='me-1' disabled={ this.state.activeNeededRefereeIndex === null }>

                                            <Dropdown.Item as='button' type='button' onClick={ () => this.handleSetRefereeClick()    }>{ _( 'Schiedsrichter:in' ) }</Dropdown.Item>
                                            <Dropdown.Item as='button' type='button' onClick={ () => this.handleSetFederationClick() }>{ _( 'LEV'               ) }</Dropdown.Item>

                                        </DropdownButton>

                                    </div>

                                    <AvailableRefereesTools />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    { this.renderGameDays( { availableRefereesCol1, availableRefereesCol2, availableRefereesCol3, gameDates } ) }

                </Form>

                <div className='schedule-mobile-controls'>

                    <button className={ 'btn' + ( this.state.availableRefereesCardOpen ? ' btn-primary' : '' ) } disabled={ this.state.activeNeededRefereeIndex === null } onClick={ () => this.toggleAvailableRefereesCard() }><Icon icon='plus-circle' size='2' /><div>{ _( 'Einteilen' ) }</div></button>
                    <button className='btn' disabled={ this.state.activeNeededRefereeIndex === null } onClick={ () => this.unassign( this.state.activeNeededRefereeIndex ) }><Icon icon='minus-circle' size='2' /><div>{ _( 'Entfernen' ) }</div></button>
                    <button className={ 'btn' + ( this.state.refereeInfoCardOpen ? ' btn-primary' : '' ) } disabled={ ! this.state.activeReferee && ! this.state.refereeInfoCardOpen } onClick={ () => this.toggleRefereeInfoCard() }><Icon icon='user-circle' size='2' /><div>{ _( 'Ref-Info' ) }</div></button>
                    <button className={ 'btn' + ( this.state.gameInfoCardOpen ? ' btn-primary' : '' ) } disabled={ ! this.state.activeGame && ! this.state.gameInfoCardOpen } onClick={ () => this.toggleGameInfoCard() }><Icon icon='info-circle' size='2' /><div>{ _( 'Spielinfo' ) }</div></button>

                    <DropdownButton title={ <><Icon icon='plus-circle' size='2' /><div>{ _( 'Setzen' ) }</div></> } align='end' drop='up' variant='none' disabled={ this.state.activeNeededRefereeIndex === null }>

                        <Dropdown.Item as='button' type='button' onClick={ () => this.handleSetRefereeClick()    }>{ _( 'Schiedsrichter:in' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' type='button' onClick={ () => this.handleSetFederationClick() }>{ _( 'LEV'               ) }</Dropdown.Item>

                    </DropdownButton>

                </div>

                <Card className={ 'schedule-mobile-available-referees-card' + ( this.state.availableRefereesCardOpen ? ' visible' : '' ) }>

                    <Card.Body>

                        { activeGameDate &&

                            <>

                                <Row className='align-items-center mb-3'>

                                    <Col xs={ 9 }>

                                        {

                                            this.#customer.groupAvailableRefereesByAvailability ?

                                                this.renderAvailabilityLabels()

                                            :

                                                refereeCategories.map( e => <span className='me-2 text-nowrap' key={ e }><RefereeBadge className={ 'referee-badge-classification-' + e } empty={ true } /> { e }</span> )

                                        }

                                    </Col>

                                    <Col className='text-right'><AvailableRefereesTools /></Col>

                                </Row>

                                <Row className='schedule-mobile-available-referees'>

                                    { this.renderAvailableReferees( { gameDate: activeGameDate, availableReferees: availableRefereesCol1[ activeGameDate ] } ) }
                                    { this.renderAvailableReferees( { gameDate: activeGameDate, availableReferees: availableRefereesCol2[ activeGameDate ] } ) }
                                    { this.renderAvailableReferees( { gameDate: activeGameDate, availableReferees: availableRefereesCol3[ activeGameDate ] } ) }

                                </Row>

                                <Row className='mt-2'>

                                    <Col>

                                        <div className='d-grid'>

                                            <Button size='sm' disabled={ ! this.state.activeReferee } onClick={ () => this.assignRefereeFromModal() }>{ _( 'Einteilen' ) }</Button>

                                        </div>

                                    </Col>

                                    <Col>

                                        <div className='d-grid'>

                                            <Button size='sm' variant='secondary' onClick={ () => this.toggleAvailableRefereesCard() }>{ _( 'Abbrechen' ) }</Button>

                                        </div>

                                    </Col>

                                </Row>

                            </>

                        }

                    </Card.Body>

                </Card>

                <Modal show={ this.state.setFederationModalOpen } onHide={ () => this.hideSetFederationModal() } centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        <Form.Group as={ Row } className='mb-3' controlId='federationId'>

                            <Form.Label column sm={ 3 }>{ _( 'LEV' ) }</Form.Label>

                            <Col sm={ 9 }>

                                <Form.Select value={ this.state.selectedFederationId } onChange={ e => this.handleFederationSelect( e ) }>

                                    <option value='0'>{ _( 'Wählen...' ) }</option>

                                    { this.state.federations && this.state.federations.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                </Form.Select>

                            </Col>

                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideSetFederationModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.setFederation() } disabled={ this.state.selectedFederationId === 0 }>{ _( 'Setzen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <Modal show={ this.state.setRefereeModalOpen } onHide={ () => this.hideSetRefereeModal() } centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        <Form.Group as={ Row } className='mb-3' controlId='refereeId'>

                            <Form.Label column sm={ 3 }>{ _( 'Schiedsrichter:in' ) }</Form.Label>

                            <Col sm={ 9 }>

                                <Form.Select value={ this.state.selectedRefereeId } onChange={ e => this.handleRefereeSelect( e ) } disabled={ this.state.isLoadingDistancePossibility }>

                                    <option value='0'>{ _( 'Wählen...' ) }</option>

                                    { this.state.referees && this.state.referees.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Lastname } { e.Firstname }</option> ) }

                                </Form.Select>

                            </Col>

                        </Form.Group>

                        <div className='mt-5'>

                            {

                                this.state.isLoadingDistancePossibility ?

                                    <p className='text-center lead'><Icon icon='spinner' spin /> { _( 'Wird geprüft...' ) }</p>

                                : this.state.hasLoadingDistancePossibilityError ?

                                    <Alert variant='warning' className='my-0'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert>

                                : this.state.distancePossibilityData ?

                                    <Alert variant={ this.state.distancePossibilityData.Warning ? 'warning' : 'success' } className='my-0'>

                                        <Row>

                                            <Col xs={ 8 }>{ _( 'Spielbeginn' ) }</Col>
                                            <Col>{ formatDate( this.state.distancePossibilityData.GameStart, { weekday: 'short' } ) } - { formatTime( this.state.distancePossibilityData.GameStart ) }</Col>

                                        </Row>

                                        <Row>

                                            <Col xs={ 8 }>{ _( 'Geschätzte Ankunft' ) }</Col>
                                            <Col>{ formatDate( this.state.distancePossibilityData.Arrival, { weekday: 'short' } ) } - { formatTime( this.state.distancePossibilityData.Arrival ) }</Col>

                                        </Row>

                                        <Row>

                                            <Col xs={ 8 }>{ _( 'Zeit von geschätzter Ankunft bis Spielbeginn' ) }</Col>
                                            <Col>{ formatDuration( distancePossibilityTimeDifference < 0 ? distancePossibilityTimeDifference * -1 : distancePossibilityTimeDifference ) } { distancePossibilityTimeDifference < 0 && _( 'zu spät' ) }</Col>

                                        </Row>

                                    </Alert>

                                :

                                    ''

                            }

                        </div>

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideSetRefereeModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.setReferee() } disabled={ this.state.selectedRefereeId === 0 || this.state.isLoadingDistancePossibility }>{ _( 'Setzen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <Modal show={ this.state.aiSuggestionModalOpen } onHide={ () => this.toggleAISuggestionModal( false ) } backdrop='static' centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        {

                            this.state.hasLoadingAISuggestionError ?

                                <Alert variant='warning' className='my-0'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert>

                            : this.state.isLoadingAISuggestion ?

                                <>

                                    <div className='fs-1 mb-2 text-center'><Icon spin icon='spinner' /></div>

                                    <p className='lead text-center'>{ _( 'KI-Vorschlag wird erstellt...' ) }</p>

                                </>

                            :

                                <>

                                    <Alert variant='info' className='mt-0 mb-3'>

                                        <div className='fs-1 mb-2 text-center'><Icon icon='info-circle' /></div>

                                        <p className='lead text-center'>{ _( 'Der KI-Vorschlag kann längere Zeit in Anspruch nehmen. Verlassen oder aktualisieren Sie die Seite während der Berechnung nicht.' ) }</p>

                                    </Alert>

                                    <p className='text-danger text-center mb-0'><Icon icon='exclamation-triangle' /> { _( 'Ungespeicherte Änderungen gehen verloren!' ) }</p>

                                </>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.toggleAISuggestionModal( false ) } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.loadAISuggestion() } disabled={ this.state.isLoadingAISuggestion }>{ _( 'Ausführen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingGamesAndRefereesError() } show={ this.state.hasLoadingGamesAndRefereesError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.loadGamesAndReferees() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissExportingScheduleXlsError() } show={ this.state.hasExportingScheduleXlsError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Einteilung konnte nicht exportiert werden.' ) }</p>

                            <Button onClick={ () => this.handleExportScheduleXls() } variant='secondary' disabled={ disableExportScheduleXls }>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                            <Button onClick={ () => this.save() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

                <Prompt
                    message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Der KI-Vorschlag wird erstellt.' ) }
                    when={ this.state.isLoadingAISuggestion }
                />

            </>

        )

    }

}

export default withRouter( Schedule )