import { connect } from 'react-redux'
import FileUploadModal from '../../components/elements/FileUploadModal'

const mapStateToProps = state => {

    return {

        token: state.auth.token,

    }

}

export default connect( mapStateToProps )( FileUploadModal )