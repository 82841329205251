import React from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import FeedbackForm from '../../containers/elements/FeedbackForm'
import { translate as _ } from '@hockeydata/skynet'

class Feedback extends React.Component {

    render() {

        return (

            <div className='flex-1'>

                <Container>

                    <Row>

                        <div className='col-lg-5 mx-auto mt-5'>

                            <h1 className='fw-300 mb-3 text-center'>{ _( 'Feedback' ) }</h1>

                            <Card className='p-4 rounded-plus bg-faded'>

                                <Card.Body>

                                    <FeedbackForm />

                                </Card.Body>

                            </Card>

                        </div>

                    </Row>

                </Container>

            </div>

        )

    }

}

export default Feedback