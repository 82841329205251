import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import { Col, Form, Row } from 'react-bootstrap'
import { refereeCategories } from '../../util/enum'

class LeaguePermission extends React.Component {

    dom

    constructor( props ) {

        super( props )

        this.dom = {

            percentage: React.createRef(),

        }

    }

    changeField( permission, name, e ) {

        e = e.target.value

        if ( e === '' ) {

            e = null

        } else {

            e = Math.floor( Number( e ) )

            if ( e < 0 ) {

                e = 0

            } else if ( e > 100 && name === 'Percentage' ) {

                e = 100

            }

        }

        this.props.onChange( permission, name, e )

    }

    createLeaguePermission() {

        return {

            Classifications:  [],
            Id:               null,
            Kilomenters:      null,
            LeagueType:       this.props.leagueType.Id,
            Percentage:       null,

        }

    }

    renderCheckbox( permission, licenseType, refereeCategory, refereeCategoryValue ) {

        return (

            <div className='flex-fill text-center' key={ refereeCategoryValue }>

                <Form.Check name={ 'league-permission-' + licenseType.Id + '-' + this.props.leagueType.Id } inline checked={ permission.Classifications && permission.Classifications.findIndex( e => e.LicenseTypeId === licenseType.Id && e.Category === refereeCategoryValue ) !== -1 } onChange={ e => this.props.onChange( permission, licenseType, e.target.checked ? refereeCategoryValue : null ) } />

                <Form.Label className='d-lg-none mx-2'>{ refereeCategory }</Form.Label>

            </div>

        )

    }

    renderCheckboxes( licenseType, permission, i ) {

        return (

            <Col className={ 'py-3' + ( i ? ' border-left' : '' ) } key={ i }>

                <div className='d-flex'>

                    <Col xs={ 6 } className='d-lg-none'><Form.Label>{ licenseType.Name }</Form.Label></Col>

                    { refereeCategories.map( ( e, i ) => this.renderCheckbox( permission, licenseType, e, i ) ) }

                </div>

            </Col>

        )

    }

    render() {

        const permission = this.props.permission || this.createLeaguePermission()

        return (

            <Row className='align-items-stretch form-check-inline-no-margin'>

                <Col lg={ 2 } className='py-3'>{ this.props.leagueType.Name }</Col>

                <Col lg={ 6 }>

                    <Row>

                        { this.props.licenseTypes && this.props.licenseTypes.map( ( licenseType, i ) => licenseType.Classifiable && this.props.userLicenseTypes.find( e => e.value === licenseType.Id ) && this.renderCheckboxes( licenseType, permission, i ) ) }

                    </Row>

                </Col>

                <Col lg={ 2 } className='py-1 border-left d-flex justify-content-center'>

                    <Col xs={ 6 } className='d-lg-none'><Form.Label>{ _( 'Prozent' ) }</Form.Label></Col>

                    <Col xs={ 6 } xl={ 8 }><Form.Control className='text-center' type='number' value={ permission.Percentage === null ? '' : permission.Percentage } onChange={ e => this.changeField( permission, 'Percentage', e ) } min='0' max='100' ref={ this.dom.percentage } /></Col>

                </Col>

                <Col lg={ 2 } className='py-1 border-left d-flex justify-content-center'>

                    <Col xs={ 6 } className='d-lg-none'><Form.Label>{ _( 'Kilometer' ) }</Form.Label></Col>

                    <Col xs={ 6 } xl={ 8 }><Form.Control className='text-center' type='number' value={ permission.Kilomenters === null ? '' : permission.Kilomenters } onChange={ e => this.changeField( permission, 'Kilomenters', e ) } min='0' /></Col>

                </Col>

            </Row>

        )

    }

}

export default LeaguePermission