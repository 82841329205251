import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Alert, Button, Card, Col, Form, InputGroup, Row, Toast, ToastContainer } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import Select from 'react-select'
import { convertEnumToOptions, convertOptions, convertToOptions, getRefereeFullName, parseJSON, sortByLastnameAndFirstname, sortByName } from '../../util'
import { timeIntervals } from '../../util/enum'
import { isScheduler, isSchedulerWithoutRelease } from '../../util/permissions'
import MultiTextInput from '../controls/MultiTextInput'

class League extends React.Component {

    #dom
    #timeIntervals

    constructor( props ) {

        super( props )

        this.state = {

            hasImportingError:        false,
            hasLoadingError:          false,
            hasSavingError:           false,
            isImporting:              false,
            isSaving:                 false,
            league:                   null,
            leagueTypes:              null,
            managerIds:               [],
            referees:                 null,
            refereeSystems:           null,
            showImportSuccessMessage: false,
            showSaveSuccessMessage:   false,
            teams:                    null,

        }

        this.#timeIntervals = convertEnumToOptions( timeIntervals )
        this.#dom           = { importFile: React.createRef() }

    }

    componentDidMount() {

        this.load()

    }

    changeLeague( e ) {

        let value = e.target.value

        if ( e.target.type === 'checkbox' ) {

            value = e.target.checked

        } else if ( e.target.type === 'number' && value === '' ) {

            value = null

        } else if ( e.target.type === 'select-one' && value === '' ) {

            value = null

        }

        this.setState( { league: { ...this.state.league, [ e.target.name ]: value } } )

    }

    changeManagers( e ) {

        this.setState( { managerIds: e ? e.map( manager => manager.value ) : [] } )

    }

    dismissImportingError() {

        this.setState( { hasImportingError: false } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    errorImporting() {

        this.setState( { hasImportingError: true } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    handleFormSubmit( e ) {

        e.preventDefault()

        this.saveLeague()

    }

    handleImportFileSelect( e ) {

        e.target.files && e.target.files.length && this.setState( { hasImportingError: false, isImporting: true, showImportSuccessMessage: false }, () => {

            ajax( getDataUrl( 'api/League/ImportGamePlanFromFile' ), { file: e.target.files[ 0 ], leagueId: this.state.league.Id, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { showImportSuccessMessage: true }, () => setTimeout( () => this.state.showImportSuccessMessage && this.setState( { showImportSuccessMessage: false } ), 3000 ) ) : this.errorImporting() )
                .catch( () => this.errorImporting() )
                .finally( () => this.setState( { isImporting: false } ) )

        } )

    }

    importGames() {

        this.setState( { hasImportingError: false, isImporting: true, showImportSuccessMessage: false }, () => {

            ajax( getDataUrl( 'api/League/ImportGamePlan' ), { leagueId: this.state.league.Id, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { showImportSuccessMessage: true }, () => setTimeout( () => this.state.showImportSuccessMessage && this.setState( { showImportSuccessMessage: false } ), 3000 ) ) : this.errorImporting() )
                .catch( () => this.errorImporting() )
                .finally( () => this.setState( { isImporting: false } ) )

        } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const setData = ( e, resolve ) => {

                const league     = e[ 0 ]
                const managerIds = league.ManagerIds || []
                const teams      = league.Teams

                league.refereeSystemId    = league.RefereeSystem ? league.RefereeSystem.Id : 0
                league.interfaceHasLeague = league.Source && league.Source.Name !== 'Excel' // TODO Remove hardcoded value
                league.isActive           = ! league.Inactive
                league.Mails              = convertToOptions( league.Mails         ? parseJSON( league.Mails         ) : [] )
                league.InternalMails      = convertToOptions( league.InternalMails ? parseJSON( league.InternalMails ) : [] )

                this.setState( { league, managerIds, teams } )

                resolve()

            }

            const setReferees = ( e, resolve ) => {

                const referees = []

                sortByLastnameAndFirstname( e )

                e.forEach( referee => ( isScheduler( referee ) || isSchedulerWithoutRelease( referee ) ) && referees.push( { value: referee.Id, label: getRefereeFullName( referee ) } ) )

                this.setState( { referees } )

                resolve()

            }

            const itemsToLoad = [

                this.loadItems( { url: 'api/League/Get',        data: { id: this.props.match.params.leagueId }, success: setData     } ),
                this.loadItems( { url: 'api/LeagueType/Get',    name: 'leagueTypes',                            fn:      sortByName  } ),
                this.loadItems( { url: 'api/Referee/Get',       data: { shortResponse: true },                  success: setReferees } ),
                this.loadItems( { url: 'api/RefereeSystem/Get', name: 'refereeSystems',                         fn:      sortByName  } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleIsLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        let data = { token: this.props.token }

        if ( options.data ) {

            data = { ...data, ...options.data }

        }

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data, resolve ) : this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    saveLeague() {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            let makePublicValue = Number( this.state.league.MakePublicValue )

            if ( isNaN( makePublicValue ) ) {

                makePublicValue = 0

            } else {

                makePublicValue = Math.floor( makePublicValue )

            }

            const data = {

                id:                     this.state.league.Id,
                name:                   this.state.league.Name,
                shortname:              this.state.league.Shortname || '',
                managerIds:             { __arr: true, values: this.state.managerIds || [] },
                seasonDivId:            this.state.league.SeasonDivId,
                seasonName:             this.state.league.Season,
                leagueType:             Number( this.state.league.LeagueType ),
                refereeSystemId:        Number( this.state.league.refereeSystemId ),
                makePublicValue:        makePublicValue,
                makePublicTimeInterval: Number( this.state.league.MakePublicTimeInterval ),
                mails:                  JSON.stringify( convertOptions( this.state.league.Mails ) ),
                internalMails:          JSON.stringify( convertOptions( this.state.league.InternalMails ) ),
                inactive:               ! this.state.league.isActive,
                token:                  this.props.token,

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/League/AddOrEdit' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { showSaveSuccessMessage: true }, () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    render() {

        const formDisabled = this.props.isLoading || this.state.hasLoadingError || this.state.isSaving
        const managers     = this.state.referees ? this.state.managerIds.map( managerId => {

            const referee = this.state.referees && this.state.referees.find( referee => referee.value === managerId )

            return { value: managerId, label: referee ? referee.label : managerId }

        } ) : []

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='diamond' className='subheader-icon' /> { _( 'Liga' ) } { this.state.league && <span className='fw-300'>{ this.state.league.Name } <span className='text-muted'>{ this.state.league.Season }</span></span>}

                    </h1>

                    <Link to='/ligaverwaltung' className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Row>

                    <Col xl={ 5 }>

                        <Card className='mb-2'>

                            <Form onSubmit={ e => this.handleFormSubmit( e ) }>

                                <fieldset disabled={ formDisabled }>

                                    <Card.Header>

                                        { _( 'Ligaeinstellungen' ) }

                                    </Card.Header>

                                    <Card.Body>

                                        <Form.Group as={ Row } className='mb-3' controlId='isActive'>

                                            <Form.Label column sm={ 4 }>{ _( 'Aktiv' ) }</Form.Label>

                                            <Col sm={ 8 } className='fs-2 standalone-switch'>

                                                <Form.Check checked={ this.state.league ? this.state.league.isActive : false } name='isActive' onChange={ e => this.changeLeague( e ) } type='switch' />

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3' controlId='Shortname'>

                                            <Form.Label column sm={ 4 }>{ _( 'Kurzbezeichnung' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <Form.Control value={ this.state.league && this.state.league.Shortname !== null ? this.state.league.Shortname : '' } name='Shortname' onChange={ e => this.changeLeague( e ) } />

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3' controlId='leagueType'>

                                            <Form.Label column sm={ 4 }>{ _( 'Ligatyp' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <Form.Select value={ this.state.league ? this.state.league.LeagueType : '' } name='LeagueType' onChange={ e => this.changeLeague( e ) }>

                                                    { ! this.state.league && <option></option> }

                                                    { this.state.leagueTypes && this.state.leagueTypes.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                                </Form.Select>

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3'>

                                            <Form.Label column sm={ 4 }>{ _( 'Veröffentlichen' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <InputGroup>

                                                    <Form.Control type='number' step='1' value={ this.state.league && this.state.league.MakePublicValue ? this.state.league.MakePublicValue : '' } name='MakePublicValue' onChange={ e => this.changeLeague( e ) } />

                                                    <Form.Select value={ this.state.league ? Number( this.state.league.MakePublicTimeInterval ) : '' } name='MakePublicTimeInterval' onChange={ e => this.changeLeague( e ) }>

                                                        { this.#timeIntervals.map( ( option, i ) => <option value={ option.value } key={ i }>{ option.label }</option> ) }

                                                    </Form.Select>

                                                </InputGroup>

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3' controlId='refereeSystemId'>

                                            <Form.Label column sm={ 4 }>{ _( 'Referee-System' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <Form.Select value={ this.state.league ? this.state.league.refereeSystemId : '' } name='refereeSystemId' onChange={ e => this.changeLeague( e ) }>

                                                    { this.state.refereeSystems ? this.state.refereeSystems.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) : <option></option> }

                                                </Form.Select>

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3' controlId='managers'>

                                            <Form.Label column sm={ 4 }>{ _( 'Manager' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <Select
                                                    isDisabled={ formDisabled }
                                                    isMulti
                                                    noOptionsMessage={ () => _( 'Keine Optionen' ) }
                                                    onChange={ e => this.changeManagers( e ) }
                                                    options={ this.state.referees }
                                                    placeholder=''
                                                    value={ managers }
                                                />

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3'>

                                            <Form.Label column sm={ 4 }>{ _( 'Presseverteiler' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <MultiTextInput
                                                    disabled={ formDisabled }
                                                    name='Mails'
                                                    onChange={ e => this.changeLeague( e ) }
                                                    value={ this.state.league ? this.state.league.Mails || [] : '' }
                                                />

                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={ Row } className='mb-3'>

                                            <Form.Label column sm={ 4 }>{ _( 'Mailverteiler (intern)' ) }</Form.Label>

                                            <Col sm={ 8 }>

                                                <MultiTextInput
                                                    disabled={ formDisabled }
                                                    name='InternalMails'
                                                    onChange={ e => this.changeLeague( e ) }
                                                    value={ this.state.league ? this.state.league.InternalMails || [] : '' }
                                                />

                                            </Col>

                                        </Form.Group>

                                    </Card.Body>

                                    <Card.Footer>

                                        <Button type='submit'>{ _( 'Speichern' ) }</Button>

                                    </Card.Footer>

                                </fieldset>

                            </Form>

                        </Card>

                        <Card className='mb-2'>

                            <Card.Header>

                                { _( 'Spielplan-Import' ) }

                            </Card.Header>

                            <Card.Body>

                                {

                                    this.state.league && this.state.league.interfaceHasLeague ?

                                        <>

                                            <p className='lead'>{ _( 'Der Spielplan wird automatisch in einem regelmäßigen Intervall importiert. Mit dem Button unterhalb kann der Import auch manuell durchgeführt werden.' ) }</p>

                                            <div className='text-center my-5'>

                                                <Button onClick={ () => this.importGames() } size='lg' variant='outline-primary' disabled={ this.state.isImporting }>{ this.state.isImporting ? <><Icon spin icon='spinner' /> { _( 'Spielplan wird importiert' ) }</> : _( 'Import starten' ) }</Button>

                                            </div>

                                        </>

                                    :

                                        <>

                                            <p className='lead'>{ _( 'Der Spielplan muss manuell importiert werden.' ) }</p>

                                            <div className='text-center mt-5 mb-3'>

                                                <Button onClick={ () => this.#dom.importFile.current.click() } size='lg' variant='outline-primary' disabled={ this.state.isImporting }>{ this.state.isImporting ? <><Icon spin icon='spinner' /> { _( 'Spielplan wird importiert' ) }</> : _( 'Spielplan hochladen' ) }</Button>

                                            </div>

                                            <div className='text-center mb-5'>

                                                <a href='/assets/easyRef_Spielplan_Importvorlage.xlsx'><Icon icon='download' /> { _( 'Vorlage herunterladen' ) }</a>

                                            </div>

                                        </>

                                }

                                { this.state.showImportSuccessMessage && <Alert variant='success'>{ _( 'Der Spielplan wurde erfolgreich importiert.' ) }</Alert> }

                            </Card.Body>

                        </Card>

                    </Col>

                    <Col xl={ 7 }>

                        {

                            this.state.teams ? (

                                this.state.teams.length ?

                                    this.state.teams.map( ( team, i ) =>

                                        <Card className='mb-2' key={ i }>

                                            <Card.Body>

                                                <Row className='align-items-center'>

                                                    <Col className='fs-5'>{ team.Name }</Col>
                                                    <Col className='col-auto'><Link to={ '/team/' + team.Id } className='btn btn-primary btn-sm'>{ _( 'Verwalten' ) }</Link></Col>

                                                </Row>

                                            </Card.Body>

                                        </Card>

                                    )

                                :

                                    <div className='my-3 text-center'>{ _( 'Keine Teams vorhanden.' ) }</div>


                            ) : ''

                        }

                    </Col>

                </Row>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissImportingError() } show={ this.state.hasImportingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Spielplan konnte nicht importiert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

                <input
                    accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    className='d-none'
                    id='importFile'
                    onChange={ e => this.handleImportFileSelect( e ) }
                    ref={ this.#dom.importFile }
                    type='file'
                />

            </>

        )

    }

}

export default withRouter( League )