import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Button, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { deepCopy } from '../../util'
import GameRow from '../elements/GameRow'

class Games extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            games:            null,
            gamesDeleting:    [],
            hasLoadingError:  false,
            hasDeletingError: false,
            isDeleting:       false,
            isLoading:        false,
            league:           null,
            selectedGames:    [],

        }

    }

    componentDidMount() {

        this.load()

    }

    deleteGames( gameIds ) {

        if ( ! window.confirm( _( 'Spiel(e) löschen?' ) ) ) {

            return

        }

        this.setState( { gamesDeleting: gameIds, hasDeletingError: false, isDeleting: true }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const success = () => {

                const games         = deepCopy( this.state.games ).map( e => { return { ...e, isDeleted: e.isDeleted || this.state.gamesDeleting.indexOf( e.Id ) !== -1 } } )
                const selectedGames = this.state.selectedGames.filter( e => this.state.gamesDeleting.indexOf( e ) === -1 )

                this.setState( {

                    gamesDeleting: [],

                    games,
                    selectedGames,

                } )

            }

            ajax( getDataUrl( 'api/Game/Delete' ), { token: this.props.token, ids: { __arr: true, values: this.state.gamesDeleting } }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorDeleting() )
                .catch( () => this.errorDeleting() )
                .finally( () => this.setState( { isDeleting: false }, () => this.props.onToggleIsLoading( false ) ) )

        }, 400 ) )

    }

    dismissDeletingError() {

        this.setState( { hasDeletingError: false } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorDeleting() {

        this.setState( { hasDeletingError: true } )

    }

    handleAllGamesSelect( e ) {

        const selectedGames = []

        e.target.checked && this.state.games && this.state.games.forEach( e => selectedGames.push( e.Id ) )

        this.setState( { selectedGames } )

    }

    handleGameSelect( e ) {

          let selectedGames = deepCopy( this.state.selectedGames )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { selectedGames } )

    }

    load() {

        this.setState( { hasLoadingError: false, isLoading: true }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/League/GetGameToLeague', name: 'games',  data: { leagueId: this.props.match.params.leagueId }               } ),
                this.loadItems( { url: 'api/League/Get',             name: 'league', data: { id:       this.props.match.params.leagueId }, league: true } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( this.setState( { isLoading: false }, () => this.props.onToggleIsLoading( false ) ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token, ...( options.data || {} ) }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: options.league ? e.Data[ 0 ].League : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='play' className='subheader-icon' /> { _( 'Spiele' ) } { this.state.league && <span className='fw-300'>{ this.state.league.Name }</span> }

                    </h1>

                    <Link to='/ligaverwaltung' className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Form>

                    <Row className='align-items-center mb-3 px-3'>

                        <Col className='mb-1'>

                            <Form.Check inline disabled={ ! this.state.games || ! this.state.games.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e ) } checked={ this.state.games && this.state.games.length ? this.state.selectedGames.length === this.state.games.length : false } />

                        </Col>

                        <Col className='mb-1 text-right'>

                            <Button onClick={ () => this.deleteGames( this.state.selectedGames ) } disabled={ ! this.state.selectedGames.length || this.state.isDeleting }>{ _( 'Auswahl löschen' ) }</Button>

                        </Col>

                    </Row>

                </Form>

                {

                    this.state.isLoading ?

                        Array.from( { length: 3 } ).map( ( e, i ) => <GameRow key={ i } /> )

                    : this.state.games && ! this.state.games.length ?

                        <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                    : this.state.games ?

                        <div>

                            { this.state.games.map( ( game, i ) =>

                                <GameRow
                                    game={ game }
                                    isDeleting={ this.state.gamesDeleting.indexOf( game.Id ) !== -1 }
                                    isSelected={ this.state.selectedGames.indexOf( game.Id ) !== -1 }
                                    key={ i }
                                    onDeleteGame={ e => this.deleteGames( [ e ] ) }
                                    onSelectGame={ e => this.handleGameSelect( e ) }
                                />

                            ) }

                        </div>

                    : ''

                }

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissDeletingError() } show={ this.state.hasDeletingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Spiel(e) konnte(n) nicht gelöscht werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( Games )