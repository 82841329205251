import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Button, Col, Dropdown, DropdownButton, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import { fillDate } from '../../util/date'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { convertLeagues, deepCopy, getDateRangePickerLocale } from '../../util'
import ReleaseGameRow from '../elements/ReleaseGameRow'
import TreeSelect from '../controls/TreeSelect'
import UrgentChanges from '../../containers/elements/UrgentChanges'
import { hasPrivilege } from '../../util/permissions'
import { PRIV_LOAD_RELEASE_GAMES, PRIV_LOAD_URGENT_CHANGES, PRIV_NOTIFY_ASSIGNMENT_USERS, PRIV_RELEASE_GAMES } from '../../util/constants'

class Release extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            divisions:               null,
            federations:             null,
            hasLoadingError:         false,
            hasReleasingError:       false,
            gamesToRelease:          null,
            gamesReleasing:          [],
            isLoadingGamesToRelease: false,
            isReleasing:             false,
            selectedGames:           [],

        }

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissReleasingError() {

        this.setState( { hasReleasingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    errorReleasing() {

        this.setState( { gamesReleasing: [], hasReleasingError: true } )

    }

    handleAllGamesSelect( e ) {

        const selectedGames = []

        e.target.checked && this.state.gamesToRelease && this.state.gamesToRelease.forEach( e => selectedGames.push( e.Id ) )

        this.setState( { selectedGames } )

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.load( { gamesToRelease: true } ), 200 )

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

        setTimeout( () => this.load( { gamesToRelease: true } ), 200 )

    }

    handleGameSelect( e ) {

          let selectedGames = deepCopy( this.state.selectedGames )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { selectedGames } )

    }

    load( e ) {

        const state = { hasLoadingError: false }

        if ( ! e ) {

            e = {

                federations:    true,
                gamesToRelease: true,
                leagues:        true,

            }

        }

        if ( e.gamesToRelease ) {

            state.isLoadingGamesToRelease = true
            state.selectedGames           = []
            state.gamesReleasing          = []

        }

        this.setState( state, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const getGamesData = {

                divIds:   { __arr: true, values: this.props.divisions },
                from:     this.props.dateRangeFrom.toJSON(),
                released: false,
                to:       this.props.dateRangeTo.toJSON(),

            }

            const itemsToLoad  = []

            e.federations    && itemsToLoad.push( this.loadItems( { url: 'api/Federation/Get',                  name: 'federations'                                                                                    } ) )
            e.gamesToRelease && itemsToLoad.push( this.loadItems( { url: 'api/Schedule/getGames',               name: 'gamesToRelease', nameLoading: 'isLoadingGamesToRelease', data: getGamesData                     } ) )
            e.leagues        && itemsToLoad.push( this.loadItems( { url: 'api/Schedule/getScheduleableLeagues', name: 'divisions',                                                                  fn: convertLeagues } ) )

            if ( itemsToLoad.length ) {

                Promise
                    .all( itemsToLoad )
                    .catch( () => this.setState( { hasLoadingError: true } ) )
                    .finally( () => this.props.onToggleIsLoading( false ) )

            }

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token, ...options.data }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data, [ options.nameLoading ]: false }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    releaseGames( gameIds, notify ) {

        this.setState( { gamesReleasing: gameIds, hasReleasingError: false, isReleasing: true }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const success = () => {

                const gamesToRelease = deepCopy( this.state.gamesToRelease ).map( e => { return { ...e, isReleased: e.isReleased || this.state.gamesReleasing.indexOf( e.Id ) !== -1 } } )
                const selectedGames  = this.state.selectedGames.filter( e => this.state.gamesReleasing.indexOf( e ) === -1 )

                this.setState( {

                    gamesReleasing: [],

                    gamesToRelease,
                    selectedGames,

                } )

            }

            const url              = notify ? 'api/Schedule/releaseAndNotifyRefs' : 'api/Schedule/releaseGames'
            const gameIdsParamName = notify ? 'gameIds'                           : 'releaseableGameIds'

            ajax( getDataUrl( url ), { token: this.props.token, [ gameIdsParamName ]: { __arr: true, values: this.state.gamesReleasing } }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorReleasing() )
                .catch( () => this.errorReleasing() )
                .finally( () => this.setState( { isReleasing: false }, () => this.props.onToggleIsLoading( false ) ) )

        }, 400 ) )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='check' className='subheader-icon' /> { _( 'Freigabe' ) }

                    </h1>

                </div>

                { hasPrivilege( this.props.user, PRIV_LOAD_URGENT_CHANGES ) && <UrgentChanges /> }

                { hasPrivilege( this.props.user, PRIV_LOAD_RELEASE_GAMES ) &&

                    <Row className='my-5'>

                        <Col>

                            <h1>{ _( 'Freizugebende Spieltage' ) }</h1>

                            <Form>

                                <Row className='align-items-center mb-3 px-3'>

                                    <Col xs={ 1 } md={ 1 } xl={ 2 } className='mb-1'>

                                        { hasPrivilege( this.props.user, PRIV_RELEASE_GAMES ) && <Form.Check inline disabled={ ! this.state.gamesToRelease || ! this.state.gamesToRelease.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e ) } checked={ this.state.gamesToRelease && this.state.gamesToRelease.length ? this.state.selectedGames.length === this.state.gamesToRelease.length : false } /> }

                                    </Col>

                                    <Col xs={ 11 } md={ 4 } xl={ 3 } className='mb-1'>

                                        <Form.Group as={ Row } controlId='league'>

                                            <Form.Label column xs={ 4 } className='text-right'>{ _( 'Liga' ) }</Form.Label>

                                            <Col xs={ 8 }>

                                                <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                                            </Col>

                                        </Form.Group>

                                    </Col>

                                    <Col xs={ { span: 11, offset: 1 } } md={ { span: 4, offset: 0 } } xl={ 3 } className='mb-1'>

                                        <Form.Group as={ Row } controlId='date-range'>

                                            <Form.Label column xs={ 4 } className='text-right'>{ _( 'Zeitraum' ) }</Form.Label>

                                            <Col xs={ 8 }>

                                                <DateRangePicker initialSettings={ {

                                                    endDate:   this.props.dateRangeTo,
                                                    locale:    getDateRangePickerLocale(),
                                                    startDate: this.props.dateRangeFrom,

                                                } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                                    <Form.Control />

                                                </DateRangePicker>

                                            </Col>

                                        </Form.Group>

                                    </Col>

                                    <Col md={ 3 } xl={ 4 } className='mb-1 text-right'>

                                        {

                                            hasPrivilege( this.props.user, PRIV_RELEASE_GAMES ) && hasPrivilege( this.props.user, PRIV_NOTIFY_ASSIGNMENT_USERS ) ?

                                                <DropdownButton title={ _( 'Auswahl...' ) } disabled={ ! this.state.selectedGames.length || this.state.isReleasing } align='end'>

                                                    <Dropdown.Item as='button' type='button' onClick={ () => this.releaseGames( this.state.selectedGames       ) }>{ _( 'Freigeben'                   ) }</Dropdown.Item>
                                                    <Dropdown.Item as='button' type='button' onClick={ () => this.releaseGames( this.state.selectedGames, true ) }>{ _( 'Freigeben & Benachrichtigen' ) }</Dropdown.Item>

                                                </DropdownButton>

                                            : hasPrivilege( this.props.user, PRIV_RELEASE_GAMES ) ?

                                                <Button onClick={ () => this.releaseGames( this.state.selectedGames ) } disabled={ ! this.state.selectedGames.length || this.state.isReleasing }>{ _( 'Auswahl freigeben' ) }</Button>

                                            : ''

                                        }

                                    </Col>

                                </Row>

                            </Form>

                            {

                                this.state.isLoadingGamesToRelease ?

                                    Array.from( { length: 3 } ).map( ( e, i ) => <ReleaseGameRow isReleasable={ hasPrivilege( this.props.user, PRIV_RELEASE_GAMES ) } key={ i } /> )

                                : this.state.gamesToRelease && ! this.state.gamesToRelease.length ?

                                    <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                                : this.state.gamesToRelease ?

                                    <div>

                                        { this.state.gamesToRelease.map( ( game, i ) =>

                                            <ReleaseGameRow
                                                federations={ this.state.federations }
                                                game={ game }
                                                isNotifiable={ hasPrivilege( this.props.user, PRIV_NOTIFY_ASSIGNMENT_USERS ) }
                                                isReleasable={ hasPrivilege( this.props.user, PRIV_RELEASE_GAMES ) }
                                                isReleasing={ this.state.gamesReleasing.indexOf( game.Id ) !== -1 }
                                                isSelected={ this.state.selectedGames.indexOf( game.Id ) !== -1 }
                                                key={ i }
                                                onReleaseGame={ ( gameId, notify ) => this.releaseGames( [ gameId ], notify ) }
                                                onSelectGame={ e => this.handleGameSelect( e ) }
                                            />

                                        ) }

                                    </div>

                                : ''

                            }

                        </Col>

                    </Row>

                }

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissReleasingError() } show={ this.state.hasReleasingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Spiel(e) konnte(n) nicht freigegeben werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default Release