import { actionTypes } from '@hockeydata/skynet'
import { deepCopy, getDefaultDateRange } from '../util'
import { AVAILABLE_REFEREES_SORT_BY_OPTION_NAME } from '../util/constants'
import { createDate } from '../util/date'
import { SET_AVAILABLE_REFEREES_TOOLS_VALUES, SET_DATE_RANGE, SET_DIVISIONS, SET_SCHEDULE_LOCAL_CHANGES, SET_FILTER_LEAGUE_TYPE, SET_LEAGUES_FILTER_QUERY, SET_REGIONS, SET_USERS_FILTER_QUERY, TOGGLE_DISPLAY_NOT_SHOW_BY_DEFAULT_REFEREES_IS_ENABLED, TOGGLE_DIVISION_DISPLAY_IS_ENABLED, TOGGLE_SCHEDULE_HEADER_IS_HIDDEN, SET_USERS_FILTER_DATE } from './constants'

const initialStateLocalChanges = {

    schedule: {},

}

const initialStateUi = {

    availableRefereesFilterName:              '',
    availableRefereesFilterZipCode:           '',
    availableRefereesSortBy:                  AVAILABLE_REFEREES_SORT_BY_OPTION_NAME,
    dateRangeFrom:                            getDefaultDateRange().from,
    dateRangeFromPublic:                      getDefaultDateRange().from,
    dateRangeTo:                              getDefaultDateRange().to,
    dateRangeToPublic:                        getDefaultDateRange().to,
    displayNotShowByDefaultRefereesIsEnabled: false,
    divisionDisplayIsEnabled:                 false,
    divisions:                                [],
    divisionsPublic:                          [],
    filterLeagueType:                         '',
    leaguesFilterQuery:                       '',
    regions:                                  [],
    scheduleHeaderIsHidden:                   false,
    usersFilterDate:                          createDate(),
    usersFilterQuery:                         '',

}

export const localChanges = ( state = initialStateLocalChanges, action ) => {

    switch ( action.type ) {

        case actionTypes.LOGOUT: {

            return initialStateLocalChanges

        }

        case SET_SCHEDULE_LOCAL_CHANGES: {

            return { ...state, schedule: deepCopy( action.payload.localChanges ) }

        }

        default:

            return state

    }

}

export const ui = ( state = initialStateUi, action ) => {

    switch ( action.type ) {

        case actionTypes.LOGOUT: {

            return {

                ...state,

                availableRefereesFilterName:              initialStateUi.availableRefereesFilterName,
                availableRefereesFilterZipCode:           initialStateUi.availableRefereesFilterZipCode,
                availableRefereesSortBy:                  initialStateUi.availableRefereesSortBy,
                dateRangeFrom:                            initialStateUi.dateRangeFrom,
                dateRangeTo:                              initialStateUi.dateRangeTo,
                displayNotShowByDefaultRefereesIsEnabled: initialStateUi.displayNotShowByDefaultRefereesIsEnabled,
                divisions:                                initialStateUi.divisions,
                filterLeagueType:                         initialStateUi.filterLeagueType,
                leaguesFilterQuery:                       initialStateUi.leaguesFilterQuery,
                regions:                                  initialStateUi.regions,
                scheduleHeaderIsHidden:                   initialStateUi.scheduleHeaderIsHidden,
                usersFilterDate:                          initialStateUi.usersFilterDate,
                usersFilterQuery:                         initialStateUi.usersFilterQuery,

            }

        }

        case SET_AVAILABLE_REFEREES_TOOLS_VALUES: {

            return { ...state, availableRefereesFilterName: action.payload.filterName, availableRefereesFilterZipCode: action.payload.filterZipCode, availableRefereesSortBy: action.payload.sortBy }

        }

        case SET_DATE_RANGE: {

            return { ...state, [ action.payload.keyFrom ]: action.payload.from, [ action.payload.keyTo ]: action.payload.to }

        }

        case SET_DIVISIONS: {

            return { ...state, [ action.payload.key ]: action.payload.divisions }

        }

        case SET_FILTER_LEAGUE_TYPE: {

            return { ...state, filterLeagueType: action.payload.filterLeagueType }

        }

        case SET_LEAGUES_FILTER_QUERY: {

            return { ...state, leaguesFilterQuery: action.payload.filterQuery }

        }

        case SET_REGIONS: {

            return { ...state, regions: action.payload.regions }

        }

        case SET_USERS_FILTER_DATE: {

            return { ...state, usersFilterDate: action.payload.filterDate }

        }

        case SET_USERS_FILTER_QUERY: {

            return { ...state, usersFilterQuery: action.payload.filterQuery }

        }

        case TOGGLE_DISPLAY_NOT_SHOW_BY_DEFAULT_REFEREES_IS_ENABLED: {

            return { ...state, displayNotShowByDefaultRefereesIsEnabled: action.payload.isEnabled }

        }

        case TOGGLE_DIVISION_DISPLAY_IS_ENABLED: {

            return { ...state, divisionDisplayIsEnabled: action.payload.isEnabled }

        }

        case TOGGLE_SCHEDULE_HEADER_IS_HIDDEN: {

            return { ...state, scheduleHeaderIsHidden: action.payload.isHidden }

        }

        default:

            return state

    }

}