import React from 'react'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { Badge, FormCheck, OverlayTrigger, Placeholder } from 'react-bootstrap'
import { formatDate, formatTime } from '../../util/date'

class GameRowInfoColumn extends React.Component {

    renderDateTimeBadge() {

        return (

            <Badge className='me-3 fs-6' bg={ this.props.dateTimeBg || 'dark' }>

                { this.props.dateTimeHeader }

                <div>{ formatDate( this.props.game.ScheduleDate, { year: 'none', weekday: 'short' } ) }</div>
                <div>{ formatTime( this.props.game.ScheduleDate ) }</div>

            </Badge>

        )

    }

    render() {

        let location = ''

        if ( this.props.game && this.props.game.Location ) {

            if ( this.props.game.Location.Address && this.props.game.Location.Address.City ) {

                location = this.props.game.Location.Address.City

            } else if ( this.props.game.Location.Name ) {

                location = this.props.game.Location.Name

            }

        }

        return (

            this.props.game ?

                <div className='d-flex align-items-center'>

                    { ! this.props.noCheckbox && <FormCheck
                        checked={ this.props.isSelected }
                        className='align-middle'
                        disabled={ this.props.disabled }
                        inline
                        onChange={ e => this.props.onSelectGame( e ) }
                        value={ this.props.game.Id }
                    /> }

                    {

                        this.props.dateTimePopover ?

                            <OverlayTrigger overlay={ this.props.dateTimePopover }>

                                { this.renderDateTimeBadge() }

                            </OverlayTrigger>

                        :

                            this.renderDateTimeBadge()

                    }

                    <div className='d-inline-block'>

                        <div className='fs-6 line-height-1'>

                            { this.props.game.HomeTeam && this.props.game.HomeTeam.Name } <small className='text-muted'>{ ' ' + _( 'vs.' ) + ' ' }</small> { this.props.game.AwayTeam && this.props.game.AwayTeam.Name }

                        </div>

                        <div className='text-success'>

                            { location && <><Icon icon='map-marker-alt' /> { location } &bull; </> }

                            { this.props.game.LeagueName }

                        </div>

                    </div>

                </div>

            :

                <>

                    <Placeholder animation='wave'><Placeholder xs={ 6 } /></Placeholder>

                    <br />

                    <Placeholder animation='wave'><Placeholder xs={ 4 } /></Placeholder>

                </>

        )

    }

}

export default GameRowInfoColumn