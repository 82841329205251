import React from 'react'
import { Button, ButtonGroup, Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap'
import Cropper from 'react-cropper'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { isImage, getReadableFileSize, getReadableFileType } from '../../util/file'
import { cancelEvent, deepCopy } from '../../util'
import { FILE_UPLOAD_STATUS_ERROR, FILE_UPLOAD_STATUS_PENDING, FILE_UPLOAD_STATUS_UPLOADED, FILE_UPLOAD_STATUS_UPLOADING } from '../../util/constants'

class FileUploadModal extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            dnDAreaEntered: false,
            image:          null,
            isUploading:    false,
            selectedFiles:  [],
            show:           false,
            supportsDnD:    false,
            uploadComplete: false,

        }

        this.#dom = {

            cropper: React.createRef(),
            file:    React.createRef(),

        }

    }

    componentDidMount() {

        const div = document.createElement( 'div' )

        if ( ( ( 'draggable' in div ) || ( ( 'ondragstart' in div ) && ('ondrop' in div ) ) ) && ( 'FormData' in window ) && ( 'FileReader' in window ) ) {

            this.setState( { supportsDnD: true } )

        }

    }

    componentDidUpdate() {

        if ( ( ! this.state.show && this.props.show ) || ( this.state.show && ! this.props.show ) ) {

            this.setState( {

                image:          null,
                isUploading:    false,
                selectedFiles:  [],
                show:           this.props.show,
                uploadComplete: false,

            } )

        }

    }

    checkUploadStatus() {

        if ( ! this.state.isUploading ) {

            return

        }

        let uploadComplete = true
        let isUploading    = false

        this.state.selectedFiles.forEach( e => {

            if ( e.status !== FILE_UPLOAD_STATUS_UPLOADED ) {

                uploadComplete = false

            }

            if ( e.status === FILE_UPLOAD_STATUS_UPLOADING ) {

                isUploading = true

            }

        } )

        this.setState( { isUploading, uploadComplete }, () => uploadComplete && setTimeout( () => this.props.onUpload( this.state.selectedFiles ), 1000 ) )

    }

    getReadableFileTypes() {

        const ret = []

        this.props.acceptedFileTypes.forEach( e => ret.push( getReadableFileType( e ) ) )

        return ret.join( ', ' )

    }

    handleDndDrop( e ) {

        cancelEvent( e )

        this.setState( { dnDAreaEntered: false }, () => this.setFiles( e.dataTransfer.files ) )

    }

    handleDnDEnter( e ) {

        cancelEvent( e )

        ! this.state.dnDAreaEntered && this.setState( { dnDAreaEntered: true } )

    }

    handleDnDLeave( e ) {

        cancelEvent( e )

        this.state.dnDAreaEntered && this.setState( { dnDAreaEntered: false } )

    }

    handleFilesSelect( e ) {

        this.setFiles( e.target.files )

    }

    selectFiles() {

        this.#dom.file.current.click()

    }

    setFiles( files ) {

        if ( ! files ) {

            return

        }

        const selectedFiles = []

        Array.from( files ).forEach( file => ( ! this.props.acceptedFileTypes || this.props.acceptedFileTypes.indexOf( file.type ) !== -1 ) && selectedFiles.push( { progress: 0, status: FILE_UPLOAD_STATUS_PENDING, file } ) )

        if ( ( ! this.props.multiple || this.props.imageEditor ) && selectedFiles.length > 1 ) {

            selectedFiles.splice( 1 )

        }

        if ( this.props.imageEditor && selectedFiles.length ) {

            const imageFile = selectedFiles[ 0 ].file

            if ( isImage( imageFile ) ) {

                const fileReader        = new FileReader()
                      fileReader.onload = () => {

                    const image = new Image()

                    image.onload = () => {

                        if ( this.props.minImageHeight && image.height < this.props.minImageHeight ) {

                            window.alert( _( 'Das Bild ist zu niedrig' ) + ' (' + _( 'Mindesthöhe' ) + ': ' + this.props.minImageHeight + ' ' + _( 'Pixel' ) + ')' )

                        } else if ( this.props.minImageWidth && image.width < this.props.minImageWidth ) {

                            window.alert( _( 'Das Bild ist zu schmal' ) + ' (' + _( 'Mindestbreite' ) + ': ' + this.props.minImageWidth + ' ' + _( 'Pixel' ) + ')' )

                        } else {

                            this.setState( { selectedFiles, image: fileReader.result } )

                        }

                    }

                    image.src = fileReader.result;

                }

                fileReader.readAsDataURL( imageFile )

            } else {

                window.alert( _( 'Bitte eine Bilddatei wählen.' ) )

            }

        } else {

            this.setState( { selectedFiles } )

        }

    }

    updateFile( fileIndex, props, callback ) {

        const selectedFiles = deepCopy( this.state.selectedFiles )

        Object.keys( props ).forEach( prop => {

            selectedFiles[ fileIndex ][ prop ] = props[ prop ]

        } )

        this.setState( { selectedFiles }, callback )

    }

    upload() {

        const selectedFiles = deepCopy( this.state.selectedFiles ).map( e => {

            if ( e.status !== FILE_UPLOAD_STATUS_UPLOADED ) {

                e.status   = FILE_UPLOAD_STATUS_UPLOADING
                e.progress = 0

            }

            return e

        } )

        const startUpload = () => {

            this.setState( { isUploading: true, selectedFiles }, () => {

                this.state.selectedFiles.forEach( ( e, i ) => e.status === FILE_UPLOAD_STATUS_UPLOADING && this.uploadFile( i ) )

            } )

        }

        if ( selectedFiles.length ) {

            if ( this.props.imageEditor ) {

                if ( ! this.state.image ) {

                    return

                }

                this.#dom.cropper.current.cropper.getCroppedCanvas().toBlob( blob => {

                    selectedFiles[ 0 ].file = new File( [ blob ], selectedFiles[ 0 ].file.name, { type: selectedFiles[ 0 ].file.type } )

                    startUpload()

                } )

            } else {

                startUpload()

            }

        } else {

            this.setState( { show: false } )

        }

    }

    uploadFile( fileIndex ) {

        const progressTimer = window.setInterval( () => this.updateFile( fileIndex, { progress: this.state.selectedFiles[ fileIndex ].progress + ( ( 100 - this.state.selectedFiles[ fileIndex ].progress ) / 3 ) } ), 300 )

        setTimeout( () => {

            const error         = () => this.updateFile( fileIndex, { status: FILE_UPLOAD_STATUS_ERROR } )
                let url           = getDataUrl( 'api/File/UploadFile?token=' + this.props.token )

            if ( this.props.imageHeight ) { url += '&resizeHeight=' + this.props.imageHeight }
            if ( this.props.imageWidth  ) { url += '&resizeWidth='  + this.props.imageWidth  }

            ajax( url, { file: this.state.selectedFiles[ fileIndex ].file }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.updateFile( fileIndex, { status: FILE_UPLOAD_STATUS_UPLOADED, fileUrl: e.Data[ 0 ] } ) : error() )
                .catch( error )
                .finally( () => {

                    setTimeout( () => {

                        window.clearInterval( progressTimer )

                        this.checkUploadStatus()

                    }, 1000 )

                } )

        }, Math.round( 1000 + ( Math.random() * 1000 ) ) )

    }

    zoomImage( e ) {

        this.#dom.cropper.current.cropper.zoom( e )

    }

    render() {

        const dndAreaClasses = [ 'form-control', 'dnd-area' ]
        const info           = []

        this.props.acceptedFileTypes && info.push( _( 'Erlaubte Dateitypen' ) + ': ' + this.getReadableFileTypes() )

        if ( this.props.minImageHeight && this.props.minImageWidth ) {

            info.push( _( 'Mindestabmessungen' ) + ': ' + this.props.minImageWidth + 'x' + this.props.minImageHeight + ' ' + _( 'Pixel' ) )

        } else {

            this.props.minImageHeight && info.push( _( 'Mindestbreite' ) + ': ' + this.props.minImageHeight + ' ' + _( 'Pixel' ) )
            this.props.minImageWidth  && info.push( _( 'Mindesthöhe'   ) + ': ' + this.props.minImageWidth  + ' ' + _( 'Pixel' ) )

        }

        ! this.state.supportsDnD    && dndAreaClasses.push( 'd-none'  )
          this.state.dnDAreaEntered && dndAreaClasses.push( 'entered' )

        return (

            <Modal show={ this.state.show } onHide={ () => this.props.onCancel() } centered backdrop='static' size={ this.props.modalSize }>

                <Modal.Header />

                <Modal.Body>

                    {

                        this.state.selectedFiles.length ? (

                            this.state.image && ! this.state.isUploading && this.state.selectedFiles[ 0 ].status === FILE_UPLOAD_STATUS_PENDING ?

                                <>

                                    <Cropper
                                        aspectRatio={ this.props.aspectRatio }
                                        className={ this.props.cropBoxCircle ? 'cropper-circle' : '' }
                                        cropBoxResizable={ ( 'cropBoxResizable' in this.props ) ? this.props.cropBoxResizable : true  }
                                        dragMode='move'
                                        guides={ ( 'guides' in this.props ) ? this.props.guides : true  }
                                        ref={ this.#dom.cropper }
                                        src={ this.state.image }
                                        toggleDragModeOnDblclick={ false }
                                        viewMode={ 1 }
                                    />

                                    <div className='text-center mt-2 fs-2'>

                                        <ButtonGroup>

                                            <Button onClick={ () => this.zoomImage(  0.1 ) } variant='outline-secondary' size='sm' className='fs-5'><Icon icon='search-plus'  /></Button>
                                            <Button onClick={ () => this.zoomImage( -0.1 ) } variant='outline-secondary' size='sm' className='fs-5'><Icon icon='search-minus' /></Button>

                                        </ButtonGroup>

                                    </div>

                                </>

                            :

                                <div className='mb-3'>

                                    { this.state.selectedFiles.map( ( e, i ) =>

                                        <Row className='mt-1 pb-1 align-items-center border-bottom' key={ i }>

                                            <Col>

                                                <div className='text-nowrap overflow-hidden text-overflow-ellipsis' title={ e.file.name }>{ e.file.name }</div>
                                                <div className='text-muted'>{ getReadableFileType( e.file.type ) }, { getReadableFileSize( e.file.size ) }</div>

                                            </Col>

                                            <Col className='text-right'>

                                                {

                                                    e.status === FILE_UPLOAD_STATUS_UPLOADED ?

                                                        <Icon icon='check-circle' className='text-success fs-4' />

                                                    : e.status === FILE_UPLOAD_STATUS_ERROR ?

                                                        <Icon icon='exclamation-triangle' className='text-danger fs-4' />

                                                    : e.status === FILE_UPLOAD_STATUS_UPLOADING ?

                                                        <ProgressBar animated striped now={ e.progress } />

                                                    :

                                                        _( 'Bereit' )

                                                }

                                            </Col>

                                        </Row>

                                    ) }

                                    { this.state.isUploading && <p className='mt-3 text-center fw-700'>{ this.state.selectedFiles.length === 1 ? _( 'Datei wird hochgeladen...' ) : _( 'Dateien werden hochgeladen...' ) }</p> }

                                    { this.state.uploadComplete && <p className='mt-3 text-center fw-700 text-success'><Icon icon='check' /> { _( 'Upload erfolgreich.' ) }</p> }

                                </div>

                        ) :

                            <>

                                <Form.Control
                                    accept={ this.props.acceptedFileTypes && this.props.acceptedFileTypes.join( ',' ) }
                                    className={ this.state.supportsDnD ? 'd-none' : '' }
                                    multiple={ this.props.multiple }
                                    onChange={ e => this.handleFilesSelect( e ) }
                                    ref={ this.#dom.file }
                                    size='lg'
                                    type='file'
                                />

                                <div
                                    className={ dndAreaClasses.join( ' ' ) }
                                    onClick={ () => this.selectFiles() }
                                    onDrag={ cancelEvent }
                                    onDragEnd={ e => this.handleDnDLeave( e ) }
                                    onDragEnter={ e => this.handleDnDEnter( e ) }
                                    onDragLeave={ e => this.handleDnDLeave( e ) }
                                    onDragOver={ e => this.handleDnDEnter( e ) }
                                    onDragStart={ cancelEvent }
                                    onDrop={ e => this.handleDndDrop( e ) }
                                >

                                    <p><Icon icon='download' size='2' /></p>

                                    <p>{ _( 'Datei auswählen oder hierherziehen' ) }</p>

                                </div>

                                { info.length > 0 && info.map( ( e, i ) => <p className='text-muted my-1' key={ i }><Icon icon='info-circle' /> { e }<br /></p> ) }

                            </>

                    }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onCancel() } disabled={ this.state.isUploading || this.state.uploadComplete } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                    <Button onClick={ () => this.upload() } disabled={ this.state.selectedFiles.length === 0 || this.state.isUploading || this.state.uploadComplete }>{ _( 'Hochladen' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default FileUploadModal