import { lz } from '.'
import { weekdaysShort } from './enum'

export const createDate = e => {

    if ( e === null || e === undefined ) {

        return new Date()

    }

    return new Date( e )

}

export const createTimeWithOffset = e => createDate( createDate().getTime() + e )

export const fillDate = e => {

    e.setMilliseconds( 999 )
    e.setSeconds(       59 )
    e.setMinutes(       59 )
    e.setHours(         23 )

    return e

}

export const formatDate = ( e, options ) => {

    const defaultOptions = {

        month:   '', // String; 'none' = empty, everything else = 03
        weekday: '', // String; 'short' = Mo, everything else = empty
        year:    '', // String; 'long' = 2022, 'none' = empty, everything else = 22

    }

    e       = createDate( e )
    options = options ? { ...defaultOptions, ...options } : defaultOptions

    return (

        (

            options.weekday === 'short' ?

                weekdaysShort[ e.getDay() ] + ', '

            :

                ''

        )

        +

        lz( e.getDate() ) + '.' +

        (

            options.month === 'none' ?

                ''

            :

                lz( e.getMonth() + 1 ) + '.'

        )

        +

        (
            options.year === 'long' ?

                e.getFullYear()

            : options.year === 'none' ?

                ''

            :

                String( e.getFullYear() ).substring( 2 )

        )

    )

}

export const formatDateInput = e => {

    e = createDate( e )

    return lz( e.getFullYear(), 4 ) + '-' + lz( e.getMonth() + 1 ) + '-' + lz( e.getDate() )

}

export const formatDuration = e => {

    e = e / 1000 / 60

    return Math.floor( e / 60 ) + 'h ' + Math.floor( e % 60 ) + 'm'

}

export const formatTime = e => {

    e = createDate( e )

    return lz( e.getHours() ) + ':' + lz( e.getMinutes() )

}

export const parseTime = e => {

    let hours   = 0
    let minutes = 0

    if ( typeof e === 'string' && e ) {

        e = e.split( ':' )

        hours   = e[ 0 ]
        minutes = e[ 1 ]

    }

    return { hours, minutes }

}

export const stripDate = e => {

    e.setMilliseconds( 0 )
    e.setSeconds(      0 )
    e.setMinutes(      0 )
    e.setHours(        0 )

    return e

}