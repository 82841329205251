export const getAbsoluteHeight = el => {

    el = typeof el === 'string' ? document.querySelector( el ) : el

    const style  = getComputedStyle( el )
    const margin = parseFloat( style.marginTop ) + parseFloat( style.marginBottom )

    return Math.ceil( el.offsetHeight + margin )

}

// export const getInnerHeight = el => {

//     el = typeof el === 'string' ? document.querySelector( el ) : el

//     const style = getComputedStyle( el )

//     return style.getPropertyValue( 'height' )

// }

export const getScreenWidth = () => window.screen.width

export const getStyleProperty = ( el, propName ) => {

    el = typeof el === 'string' ? document.querySelector( el ) : el

    const style = getComputedStyle( el )

    return style.getPropertyValue( propName )

}