export const fileTypes = {

    'application/pdf': 'PDF',
    'image/jpeg':      'JPG',
    'image/png':       'PNG',

}

export const getReadableFileSize = bytes => {

    const thresh = 1000

    if ( Math.abs( bytes ) < thresh ) {

        return bytes + ' B'

    }

    const units = [ 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ]
    const r     = 2
      let u     = -1

    do {

        bytes /= thresh

        ++u

    } while ( Math.round( Math.abs( bytes) * r ) / r >= thresh && u < units.length - 1 )

    return bytes.toFixed( 2 ).replace( '.', ',' ) + ' ' + units[ u ]

}

export const getReadableFileType = e => ( e in fileTypes ) ? fileTypes[ e ] : e

export const imageFileTypes = [ 'image/gif', 'image/jpeg', 'image/png' ]

export const isImage = e => e && imageFileTypes.indexOf( e.type ) !== -1