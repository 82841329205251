export const coachResultStatus = [ 'Ausstehend', 'Bewertet', 'Freigegeben' ]

export const feedbackStatus    = [ 'Ausstehend', 'Gesendet', 'Gelesen', 'Zugesagt', 'Abgesagt', 'Nicht zustellbar' ]

export const clothingSizes     = [ 'XS', 'S', 'M', 'L', 'XL', 'XXL' ]

export const refereeCategories = [ 'A', 'B', 'C' ]

export const relationTypes     = [ 'Prio A', 'Prio B', 'Prio C', 'Block' ]

export const timeIntervals     = [ 'Minuten', 'Stunden', 'Tage' ]

export const weekdaysShort     = [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ]