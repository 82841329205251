import React from 'react'
import { Placeholder } from 'react-bootstrap'
import { translate as _ } from '@hockeydata/skynet'
import RefereeBadge from './RefereeBadge'
import { getFeedbackStatusIcon, getRefereeFullName } from '../../util'
import { feedbackStatus } from '../../util/enum'
import { Icon } from '@hockeydata/skynet'
import { ASSIGNMENT_STATUS_DECLINED } from '../../util/constants'

class GameRowRefereesColumn extends React.Component {

    renderReferee( e, i ) {

        const classes     = [ 'me-1', 'my-2', 'with-icon' ]
        const displayName = []
          let referee     = null
          let title       = ''
          let label       = ''

        if ( e.FederationId ) {

            if ( this.props.federations ) {

                const federation = this.props.federations.find( federation => federation.Id === e.FederationId )

                if ( federation ) {

                    displayName.push( <React.Fragment key='federationShortname'>{ federation.Shortname }</React.Fragment> )

                    title = federation.Name

                }

            }

        } else if ( e.RefereeId ) {

            referee = {

                Firstname: e.RefereeFirstName,
                Lastname:  e.RefereeLastName,

            }

            displayName.push( <React.Fragment key='referee'>{ ! this.props.ignoreStatus && getFeedbackStatusIcon( e.Status ) } { getRefereeFullName( referee ) }</React.Fragment> )
            this.props.ignoreStatus ? classes.push( 'outline' ) : classes.push( 'notification-status', 'notification-status-' + e.Status )

            if ( ! this.props.ignoreStatus ) {

                title = getRefereeFullName( referee ) + ( this.props.ignoreStatus ? '' : ' (' + feedbackStatus[ e.Status ] + ( e.Status === ASSIGNMENT_STATUS_DECLINED && e.DeclineReason !== null ? ', ' + e.DeclineReason : '' ) + ')' )

            }

        } else if ( e.RefereeSystemJob && e.RefereeSystemJob.Required && ! this.props.ignoreStatus ) {

            displayName.push( <Icon icon='exclamation-triangle' className='text-muted' key='warning-icon' /> )

            title = _( 'Einteilung fehlt' )

        }

        ! referee && classes.push( 'outline' )
        e.Force && ! this.props.ignoreStatus && classes.push( 'forced'  )

        if ( e.RefereeSystemJob ) {

            label = e.RefereeSystemJob.Shortname

            if ( ! this.props.ignoreStatus ) {

                ! e.RefereeSystemJob.Required && classes.push( 'not-required' )
                e.RefereeSystemJob.Remoteable && e.Remote && displayName.push( <Icon icon='rss' className='ms-1' key='remote-icon' /> )

            }

        }

        return (

            ( ! this.props.ignoreStatus || displayName.length > 0 ) &&

            <React.Fragment key={ i }>

                {

                    this.props.render ?

                        this.props.render( e, { classes, displayName, label, title } )

                    :

                        <RefereeBadge
                            className={ classes.join( ' ' ) }
                            displayName={ displayName }
                            label={ label }
                            title={ title }
                        />

                }

            </React.Fragment>

        )

    }

    render() {

        return (

            <div className='my-3'>

                {

                    this.props.game ?

                        <>

                            { this.props.referees.map( ( e, i ) => this.renderReferee( e, i ) ) }

                            { this.props.children }

                        </>

                    :

                        <Placeholder animation='wave'><Placeholder xs={ 12 } /></Placeholder>

                }

            </div>

        )

    }

}

export default GameRowRefereesColumn