import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import LeagueManagement from '../../components/pages/LeagueManagement'
import { setFilterLeagueType, setLeaguesFilterQuery } from '../../store/actions'

const mapStateToProps = state => {

    return {

        filterLeagueType: state.ui.filterLeagueType   || '',
        filterQuery:      state.ui.leaguesFilterQuery || '',
        isLoading:        state.app.isLoading,
        token:            state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onSetFilterLeagueType: filterLeagueType => dispatch( setFilterLeagueType(              filterLeagueType ) ),
        onSetFilterQuery:      filterQuery      => dispatch( setLeaguesFilterQuery(            filterQuery      ) ),
        onToggleIsLoading:     isLoading        => dispatch( storeActions.app.toggleIsLoading( isLoading        ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( LeagueManagement )