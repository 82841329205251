import React from 'react'
import { ajax, avatars, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Badge, Button, Card, Col, Form, InputGroup, Modal, Nav, OverlayTrigger, Row, Tab, Toast, ToastContainer, Tooltip } from 'react-bootstrap'
import Select from 'react-select'
import { electronicFormatIBAN, friendlyFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools'
import RefereeDto from '../../classes/RefereeDto'
import MultiTextInput from '../controls/MultiTextInput'
import { capitalizeFirstLetter, convertEnumToOptions, convertOptions, deepCopy, getRefereeFullName, getRefereeShortName, getStyle, sortByLabel, sortByLastnameAndFirstname, sortByName } from '../../util'
import { clothingSizes, refereeCategories, relationTypes } from '../../util/enum'
import LeaguePermission from '../elements/LeaguePermission'
import MultiDateInput from '../controls/MultiDateInput'
import MultiSelect from '../controls/MultiSelect'
import { formatDateInput } from '../../util/date'
import { hasPrivilege } from '../../util/permissions'
import FileUploadModal from '../../containers/elements/FileUploadModal'
import { Link, Prompt, withRouter } from 'react-router-dom'
import { PRIV_DELETE_REFEREE, PRIV_LOAD_REGIONS, PRIV_SAVE_REFEREE_ALL, PRIV_SAVE_REFEREE_LEAGUE } from '../../util/constants'
import Lightbox from '../elements/Lightbox'

class User extends React.Component {

    #clothingSizes
    #dom
    #enableRegions
    #uploadSettings

    constructor( props ) {

        super( props )

        this.state = {

            absenceReasons:                null,
            absences:                      [],
            activeTab:                     'basicData',
            clubs:                         null,
            deleteRefereeModalOpen:        false,
            federations:                   null,
            fileUploadProp:                null,
            hasDeletingRefereeError:       false,
            hasLoadingError:               false,
            hasSavingError:                false,
            hasUnsavedChanges:             false,
            isDeletingReferee:             false,
            isSaving:                      false,
            leaguePermissions:             [],
            leagueTypes:                   null,
            licenseTypes:                  null,
            lightboxOpen:                  false,
            refereeBans:                   [],
            refereeRelations:              [],
            referees:                      null,
            regions:                       null,
            roles:                         null,
            showDeleteImageSuccessMessage: false,
            showSaveSuccessMessage:        false,
            showUploadImageSuccessMessage: false,
            teams:                         null,
            user:                          new RefereeDto().get(),

        }

        this.#clothingSizes = convertEnumToOptions( clothingSizes )
        this.#enableRegions = hasPrivilege( props.user, PRIV_LOAD_REGIONS )

        this.#dom = {

            accountEmail:                React.createRef(),
            firstname:                   React.createRef(),
            lastname:                    React.createRef(),
            leaguePermissionPercentages: [],

        }

        this.#uploadSettings = {

            basic: {

                acceptedFileTypes: [ 'image/jpeg' ],
                onCancel:          () => this.cancelFileUpload(),
                onUpload:          e => this.handleFileUpload( e ),

            },

            image: {

                aspectRatio:      1,
                cropBoxResizable: false,
                cropBoxCircle:    true,
                guides:           false,
                imageEditor:      true,
                imageHeight:      100,
                imageWidth:       100,
                minImageHeight:   100,
                minImageWidth:    100,

            },

            pressImage: {

                cropBoxResizable: true,
                minImageHeight:   400,
                minImageWidth:    400,

            }

        }

        this.checkUnsavedChanges = this.checkUnsavedChanges.bind( this )

    }

    componentDidMount() {

        this.load()

        window.addEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    componentWillUnmount() {

        window.removeEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    cancelFileUpload( e ) {

        this.setState( { fileUploadProp: null } )

    }

    changeIBAN( e ) {

        this.changeUser( { target: { value: friendlyFormatIBAN( e.target.value ), name: 'iban' } } )

    }

    changeLeaguePermission( permission, licenseType, value ) {

        permission = deepCopy( permission )

        if ( ! permission.Classifications ) {

            permission.Classifications = []

        }

        const classificationIndex = permission.Classifications.findIndex( e => e.LicenseTypeId === licenseType.Id )

        if ( classificationIndex === -1 ) {

            permission.Classifications.push( {

                Category:        value,
                Id:              null,
                LicenseTypeId:   licenseType.Id,
                LicenseTypeName: licenseType.Name,

            } )

        } else {

            permission.Classifications[ classificationIndex ].Category = value

        }

        const leaguePermissions     = deepCopy( this.state.leaguePermissions )
        const leaguePermissionIndex = leaguePermissions.findIndex( e => e.LeagueType === permission.LeagueType )

        if ( leaguePermissionIndex === -1 ) {

            leaguePermissions.push( permission )

        } else {

            leaguePermissions[ leaguePermissionIndex ] = permission

        }

        this.setState( { leaguePermissions, hasUnsavedChanges: true } )

    }

    changeUser( e ) {

        let value = e.target.value

        if ( e.target.type === 'checkbox' ) {

            value = e.target.checked

        } else if ( e.target.type === 'number' && value === '' ) {

            value = null

        } else if ( e.target.type === 'select-one' && value === '' ) {

            value = null

        }

        this.setState( { user: { ...this.state.user, [ e.target.name ]: value }, hasUnsavedChanges: true } )

    }

    checkUnsavedChanges( e ) {

        if ( this.state.hasUnsavedChanges ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' )

        }

    }

    deleteAbsence( e ) {

        if ( ! window.confirm( _( 'Abwesenheit löschen? Die Abwesenheit wird sofort gelöscht, nicht erst beim Speichern des Benutzers/der Benutzerin.' ) ) ) {

            return

        }

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const success = () => {

                const absences = deepCopy( this.state.absences || [] )
                const index    = absences.findIndex( absence => absence.Id === e.Id )

                if ( index !== -1 ) {

                    absences.splice( index, 1 )

                }

                this.setState(

                    {

                        absences,
                        showSaveSuccessMessage: true,

                    },

                    () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) )

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Referee/DeleteAbsence' ), { absenceId: e.Id, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    deleteImage( prop ) {

        if ( window.confirm( _( 'Bild löschen?' ) ) ) {

            this.setState( { hasSavingError: false, isSaving: true, showDeleteImageSuccessMessage: false }, () => {

                this.props.onToggleIsLoading( true )

                ajax( getDataUrl( 'api/File/DeleteFile?fileUrl=' + encodeURIComponent( this.state.user[ prop ] ) + '&token=' + this.props.token ), null, { method: 'POST' } )
                    .finally( () => {

                        const data = {

                            id:           this.state.user.id,
                            propertyName: capitalizeFirstLetter( prop ),
                            token:        this.props.token,

                        }

                        ajax( getDataUrl( 'api/Referee/setProperty' ), data, { method: 'POST' } )
                            .then( e => e.StatusId > 0 ? this.setState( { user: { ...this.state.user, [ prop ]: null }, showDeleteImageSuccessMessage: true }, () => setTimeout( () => this.state.showDeleteImageSuccessMessage && this.setState( { showDeleteImageSuccessMessage: false } ), 3000 ) ) : this.errorSaving() )
                            .catch( () => this.errorSaving() )
                            .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

                    } )

            } )

        }

    }

    deleteReferee() {

        if ( ! window.confirm( _( 'Benutzer:in löschen?' ) ) ) {

            return

        }

        this.setState( { hasDeletingRefereeError: false, isDeletingReferee: true }, () => {

            ajax( getDataUrl( 'api/Referee/Delete' ), { id: this.getUserId(), token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.props.history.push( '/user' ) : this.errorDeletingReferee() )
                .catch( () => this.errorDeletingReferee() )
                .finally( () => this.setState( { isDeletingReferee: false } ) )

        } )

    }

    deleteRefereeBan( e ) {

        if ( ! window.confirm( _( 'Eintrag löschen? Der Eintrag wird sofort gelöscht, nicht erst beim Speichern des Benutzers/der Benutzerin.' ) ) ) {

            return

        }

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const success = () => {

                const refereeBans = deepCopy( this.state.refereeBans || [] )
                const index       = refereeBans.findIndex( refereeBan => refereeBan.Id === e.id )

                if ( index !== -1 ) {

                    refereeBans.splice( index, 1 )

                }

                this.setState(

                    {

                        refereeBans,
                        showSaveSuccessMessage: true,

                    },

                    () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) )

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Referee/DeleteRefereeBan' ), { refereeBanId: e.id, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    deleteRefereeRelation( e ) {

        if ( ! window.confirm( _( 'Eintrag löschen? Der Eintrag wird sofort gelöscht, nicht erst beim Speichern des Benutzers/der Benutzerin.' ) ) ) {

            return

        }

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const success = () => {

                const refereeRelations = deepCopy( this.state.refereeRelations || [] )
                const index            = refereeRelations.findIndex( refereeRelation => refereeRelation.Id === e.id )

                if ( index !== -1 ) {

                    refereeRelations.splice( index, 1 )

                }

                this.setState(

                    {

                        refereeRelations,
                        showSaveSuccessMessage: true,

                    },

                    () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) )

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Referee/DeleteRefereeRelation' ), { relationId: e.id, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    dismissDeleteImageSuccessMessage() {

        this.setState( { showDeleteImageSuccessMessage: false } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    dismissUploadImageSuccessMessage() {

        this.setState( { showUploadImageSuccessMessage: false } )

    }

    errorDeletingReferee() {

        this.setState( { hasDeletingRefereeError: true } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    getUserId() {

        return this.state.user.id || this.props.match.params.userId || 0

    }

    handleDeleteRefereeClick() {

        this.setState( { deleteRefereeModalOpen: true } )

    }

    handleFileUpload( e ) {

        const fileUploadProp = this.state.fileUploadProp

        this.setState( { fileUploadProp: null, hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const fileUrl = e && e[ 0 ] && e[ 0 ].fileUrl

            if ( fileUrl ) {

                this.props.onToggleIsLoading( true )

                const data = {

                    id:            this.state.user.id,
                    propertyName:  capitalizeFirstLetter( fileUploadProp ),
                    propertyValue: fileUrl,
                    token:         this.props.token,

                }

                ajax( getDataUrl( 'api/Referee/setProperty' ), data, { method: 'POST' } )
                    .then( e => e.StatusId > 0 ? this.setState( { user: { ...this.state.user, [ fileUploadProp ]: fileUrl }, showUploadImageSuccessMessage: true }, () => setTimeout( () => this.state.showUploadImageSuccessMessage && this.setState( { showUploadImageSuccessMessage: false } ), 3000 ) ) : this.errorSaving() )
                    .catch( () => this.errorSaving() )
                    .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

            }

        } )

    }

    handleFormSubmit( e ) {

        e.preventDefault()

        this.saveUser()

    }

    handleUploadPressImageClick() {

        this.setState( { fileUploadProp: 'pressImage' } )

    }

    handleUploadProfileImageClick() {

        this.setState( { fileUploadProp: 'image' } )

    }

    hideDeleteRefereeModal() {

        this.setState( { deleteRefereeModalOpen: false } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const setRegions = ( regions, resolve ) => {

                sortByName( regions )

                this.setState( { regions: regions.map( e => { return { label: e.Name, value: e.Id } } ) }, resolve )

            }

            const userId      = this.getUserId()
            const itemsToLoad = [

                this.loadItems( { url: 'api/Absence/GetReasons', name: 'absenceReasons'                } ),
                this.loadItems( { url: 'api/Club/Get',           name: 'clubs',         fn: sortByName } ),
                this.loadItems( { url: 'api/Federation/Get',     name: 'federations',   fn: sortByName } ),
                this.loadItems( { url: 'api/LeagueType/Get',     name: 'leagueTypes'                   } ),

            ]

            hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE ) && itemsToLoad.push( this.loadItems( { url: 'api/Team/Get',        name: 'teams'                                      } ) )
            hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE ) && itemsToLoad.push( this.loadItems( { url: 'api/Referee/Get',     name: 'referees',    data: { shortResponse: true } } ) )
            hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE ) && itemsToLoad.push( this.loadItems( { url: 'api/LicenseType/Get', name: 'licenseTypes'                               } ) )
            hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_ALL    ) && itemsToLoad.push( this.loadItems( { url: 'api/Role/Get',        name: 'roles'                                      } ) )

            userId !== 'neu' && itemsToLoad.push( this.loadUser() )
            this.#enableRegions && itemsToLoad.push( this.loadItems( { url: 'api/Schedule/getScheduleableRegions', success: setRegions } ) )

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleIsLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        let data = { token: this.props.token }

        if ( options.data ) {

            data = { ...data, ...options.data }

        }

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data, resolve ) : this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    loadUser() {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( ( 'self' in this.props ) ? 'api/Referee/GetOwn' :  'api/Referee/Get' ), { token: this.props.token, id: this.getUserId() }, { method: 'POST' } )
                .then( e => {

                    if ( e.StatusId > 0 && e.Data && e.Data.length ) {

                        const user = new RefereeDto( e.Data[ 0 ] ).get()

                        this.setState( {

                            user,
                            absences:          user.absences,
                            leaguePermissions: user.leaguePermissions,
                            refereeBans:       user.refereeBans,
                            refereeRelations:  user.refereeRelations,

                        }, resolve )

                    } else {

                        reject()

                    }

                } )
                .catch( reject )

        } )

    }

    saveAbsence( e ) {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const data = {

                absenceId:       e.Id,
                absenceReasonId: e.Type,
                from:            formatDateInput( e.From ),
                fullday:         e.FullDay,
                intervalEnd:     '',                                   // TODO Use from e
                intervalId:      '',                                   // TODO Use from e
                // intervalMonths:  '',                                // TODO Use from e
                intervalStart:   '',                                   // TODO Use from e
                // monthDaysList:   '',                                // TODO Use from e
                refereeId:       this.getUserId(),
                serial:          e.Serial,
                to:              formatDateInput( e.To ),
                token:           this.props.token,
                type:            '',                                   // TODO Use from e
                // weekDaysList:    '',                                // TODO Use from e

            }

            const success = e => {

                const absences = deepCopy( this.state.absences || [] )
                const index    = absences.findIndex( absence => absence.Id === e.Id )

                if ( index === -1 ) {

                    absences.push( e )

                } else {

                    absences[ index ] = e

                }

                this.setState(

                    {

                        absences,
                        showSaveSuccessMessage: true,

                    },

                    () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) )

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Referee/AddOrEditAbsence' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data.length ? success( e.Data[ 0 ] ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    saveRefereeBan( e ) {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const data = {

                refereeBanId: e.id,
                refId:        Number( this.getUserId() ),
                teamId:       e.teamId,
                token:        this.props.token,

            }

            const success = e => {

                const refereeBans = deepCopy( this.state.refereeBans || [] )
                const index       = refereeBans.findIndex( refereeBan => refereeBan.Id === e.Id )

                if ( index === -1 ) {

                    refereeBans.push( e )

                } else {

                    refereeBans[ index ] = e

                }

                this.setState(

                    {

                        refereeBans,
                        showSaveSuccessMessage: true,

                    },

                    () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) )

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Referee/AddOrEditRefereeBan' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data.length ? success( e.Data[ 0 ] ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    saveRefereeRelation( e ) {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const data = {

                relationId:   e.id,
                relationType: Number( e.relationType ),
                token:        this.props.token,

            }

            data[ 'ref' +   e.refereeIndex                 + 'Id' ] = Number( e.refereeId      )
            data[ 'ref' + ( e.refereeIndex === 1 ? 2 : 1 ) + 'Id' ] = Number( this.getUserId() )

            const success = e => {

                const refereeRelations = deepCopy( this.state.refereeRelations || [] )
                const index            = refereeRelations.findIndex( refereeRelation => refereeRelation.Id === e.Id )

                if ( index === -1 ) {

                    refereeRelations.push( e )

                } else {

                    refereeRelations[ index ] = e

                }

                this.setState(

                    {

                        refereeRelations,
                        showSaveSuccessMessage: true,

                    },

                    () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) )

            }

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Referee/AddOrEditRefereeRelation' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data.length ? success( e.Data[ 0 ] ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    saveUser() {

        if ( this.validateUser() ) {

            this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

                  let url  = 'AddOrEditRef'
                const data = {

                    accountMail:        this.state.user.accountEmail,
                    bic:                this.state.user.bic,
                    city:               this.state.user.city,
                    firstLicenseYear:   0,
                    firstName:          this.state.user.firstname,
                    houseNo:            this.state.user.houseNumber,
                    iban:               this.state.user.iban,
                    id:                 this.state.user.id,
                    lastName:           this.state.user.lastname,
                    mails:              JSON.stringify( convertOptions( this.state.user.email ) ),
                    nationalFederation: this.state.user.nationalFederation,
                    PassNr:             this.state.user.passNumber,
                    phoneNumbers:       JSON.stringify( convertOptions( this.state.user.phone ) ),
                    postalCode:         this.state.user.postalCode,
                    refereeNote:        this.state.user.refereeNote,
                    shoeSize:           this.state.user.shoeSize === null ? '' : this.state.user.shoeSize,
                    street:             this.state.user.street,
                    taxable:            this.state.user.isTaxable,
                    token:              this.props.token,

                }

                if ( this.state.user.addressId          !== null ) { data.addressId       = this.state.user.addressId          }
                if ( this.state.user.birthday                    ) { data.birthday        = this.state.user.birthday           }
                if ( this.state.user.clubId             !== null ) { data.clubId          = this.state.user.clubId             }
                if ( this.state.user.federationId       !== null ) { data.federationId    = this.state.user.federationId       }
                if ( this.state.user.headSize           !== null ) { data.headSize        = this.state.user.headSize           }
                if ( this.state.user.image              !== null ) { data.image           = this.state.user.image              }
                if ( this.state.user.jacketSize         !== null ) { data.jacket          = this.state.user.jacketSize         }
                if ( this.state.user.poloSize           !== null ) { data.polo            = this.state.user.poloSize           }
                if ( this.state.user.pressImage         !== null ) { data.pressImage      = this.state.user.pressImage         }
                if ( this.state.user.refereeJerseySize  !== null ) { data.refereeJersey   = this.state.user.refereeJerseySize  }
                if ( this.state.user.refereePantsSize   !== null ) { data.refereePants    = this.state.user.refereePantsSize   }
                if ( this.state.user.trainingJacketSize !== null ) { data.trainingsJacket = this.state.user.trainingJacketSize }
                if ( this.state.user.trainingPantsSize  !== null ) { data.trainingsPants  = this.state.user.trainingPantsSize  }

                if ( this.state.user.id && hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE ) ) {

                    url = 'AddOrEditWithLeague'

                    data.leaguePermissionsJson = JSON.stringify( this.state.leaguePermissions )
                    data.leagueManagerNote     = this.state.user.leagueManagerNote

                    if ( this.#enableRegions ) {

                        data.regionIds = { __arr: true, values: this.state.user.regions.map( e => { return e.value } ) }

                    }

                }

                if ( this.state.user.id && hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_ALL ) ) {

                    url = 'AddOrEditAll'

                    data.licenseIds         = { __arr: true, values: this.state.user.licenseTypes.map( e => { return e.value } ) }
                    data.internationalCoach = this.state.user.internationalCoach
                    data.paused             = this.state.user.isPaused
                    data.roleIds            = { __arr: true, values: this.state.user.roles.map( e => { return e.value } ) }
                    data.trainee            = this.state.user.isTrainee

                    if ( this.state.user.internationalLinespersonLicense !== null ) { data.internationalLinesmenCategory = this.state.user.internationalLinespersonLicense }
                    if ( this.state.user.internationalRefereeLicense     !== null ) { data.internationalRefereeCategory  = this.state.user.internationalRefereeLicense     }

                }

                const success = user => {

                    const userId = this.getUserId()
                    user         = new RefereeDto( user ).get()

                    this.setState(

                        {

                            user,
                            hasUnsavedChanges:      false,
                            leaguePermissions:      user.MapLeagueReferees || [],
                            showSaveSuccessMessage: true,

                        },

                        () => {

                            userId === 'neu' && this.props.history.push( '/user/' + user.id )

                            setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 )

                        }

                    )

                }

                this.props.onToggleIsLoading( true )

                ajax( getDataUrl( 'api/Referee/' + url ), data, { method: 'POST' } )
                    .then( e => e.StatusId > 0 && e.Data && e.Data.length ? success( e.Data[ 0 ] ) : this.errorSaving() )
                    .catch( () => this.errorSaving() )
                    .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleIsLoading( false ) ) )

            } )

        }

    }

    selectTab( activeTab ) {

        this.setState( { activeTab } )

    }

    toggleLightbox() {

        this.setState( { lightboxOpen: ! this.state.lightboxOpen } )

    }

    validateField( name, activeTab ) {

        if ( ! this.state.user[ name ] ) {

            return this.setState( { activeTab }, () => this.#dom[ name ].current.focus() )

        }

        return true

    }

    validateLeaguePermissions() {

        let totalPercentage = 0

        this.state.leaguePermissions.forEach( e => totalPercentage += e.Percentage )

        if ( totalPercentage !== 100 && totalPercentage !== 0 && this.#dom.leaguePermissionPercentages[ 0 ] ) {

            return this.setState( { activeTab: 'leaguePermissions' }, () => this.#dom.leaguePermissionPercentages[ 0 ].dom.percentage.current.focus() )

        }

        return true

    }

    validateUser() {

        if ( ! this.state.user ) {

            return false

        }

        if ( ! this.validateField( 'firstname',    'basicData' ) ) { return false }
        if ( ! this.validateField( 'lastname',     'basicData' ) ) { return false }
        if ( ! this.validateField( 'accountEmail', 'basicData' ) ) { return false }

        if ( hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE ) && ! this.validateLeaguePermissions() ) {

            return false

        }

        return true

    }

    renderInput( options ) {

        let value    = this.state.user[ options.name ]
        let colClass = ''

        if ( options.type === 'switch' ) {

            colClass = 'fs-2 standalone-switch'

        } else if ( options.inputType === 'number' && typeof value === 'string' ) {

            value = value.replaceAll( ',', '.' )

        }

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ options.name }>

                <Form.Label column sm={ 3 } xl={ options.labelWidthXl }>{ options.label }</Form.Label>

                <Col sm={ 9 } xl={ options.inputWidthXl } className={ colClass }>

                    {

                        options.type === 'switch' ?

                            <Form.Check
                                checked={ value }
                                name={ options.name }
                                onChange={ e => this.changeUser( e ) }
                                type='switch'
                            />

                        : options.type === 'select' ?

                            <Form.Select value={ value === null ? '' : value } name={ options.name } onChange={ e => this.changeUser( e ) }>

                                <option value=''></option>

                                { options.options && options.options.map( ( option, i ) => <option value={ ( 'value' in option ) ? option.value : option.Id } key={ i }>{ option.label || option.Name }</option> ) }

                            </Form.Select>

                        : options.type === 'multiSelect' ?

                            <Select
                                isDisabled={ options.disabled }
                                isMulti
                                name={ options.name }
                                noOptionsMessage={ () => _( 'Keine Optionen' ) }
                                onChange={ options.onChange }
                                options={ options.options }
                                placeholder=''
                                value={ options.value }
                            />

                        : options.type === 'multiText' ?

                            <MultiTextInput
                                disabled={ options.disabled }
                                name={ options.name }
                                onChange={ e => this.changeUser( e ) }
                                value={ value || [] }
                            />

                        : options.type === 'absences' ?

                            <MultiDateInput
                                disabled={ options.disabled }
                                editable={ true }
                                initialValue={ this.state.absences && this.state.absences.map( e => { return { ...e, bg: e.Reason && this.state.absenceReasons ? getStyle( this.state.absenceReasons.findIndex( f => f.Id === e.Reason.Id ) ) : null, Type: e.Reason ? e.Reason.Id : 0 } } ) }
                                onChange={ e => this.saveAbsence( e ) }
                                onDelete={ e => this.deleteAbsence( e ) }
                                typeOptions={ this.state.absenceReasons && this.state.absenceReasons.map( e => { return { value: e.Id, label: e.Name } } ) }
                            />

                        :

                            <Form.Control
                                as={ options.as || 'input' }
                                disabled={ options.disabled }
                                name={ options.name }
                                onBlur={ options.onBlur }
                                onChange={ e => this.changeUser( e ) }
                                ref={ options.ref }
                                rows={ options.rows }
                                step={ options.step || '1' }
                                type={ options.inputType || 'text' }
                                value={ value === null ? '' : value }
                            />

                    }

                    { options.infoText }

                </Col>

            </Form.Group>

        )

    }

    renderRefereeCategories() {

        return refereeCategories.map( ( e, i ) => <div className='flex-fill' key={ i }>{ e }</div> )

    }

    renderImages() {

        return (

            <div className='user-images-container'>

                { this.state.user.pressImage ? <a href={ this.state.user.pressImage } target='_blank' rel='noreferrer'><img src={ this.state.user.pressImage } alt='' className='press-image rounded' /></a> : <div className='press-image-empty border rounded' /> }

                <img src={ this.state.user.image || avatars.male } alt='' className={ 'border profile-image rounded-circle' + ( this.state.user.image ? ' cursor-pointer' : '' ) } onClick={ this.state.user.image ? () => this.toggleLightbox() : null } />

            </div>

        )

    }

    renderTabButton( eventKey, label ) {

        return (

            <Nav.Item role='button'>

            {

                this.state.user.id ?

                    <Nav.Link eventKey={ eventKey }>{ label }</Nav.Link>

                :

                    <OverlayTrigger overlay={ <Tooltip>{ _( 'Speichern zum Bearbeiten' ) }</Tooltip> }>

                        <span className='cursor-not-allowed'>

                            <Nav.Link eventKey={ eventKey } disabled>{ label }</Nav.Link>

                        </span>

                    </OverlayTrigger>

            }

            </Nav.Item>

        )

    }

    render() {

        const refereeId         = Number( this.getUserId() )
        const formDisabled      = this.props.isLoading || this.state.hasLoadingError || this.state.isSaving
        const referees          = []
        const teams             = []
          let fileUploadOptions = { ...this.#uploadSettings.basic, show: this.state.fileUploadProp !== null }

        if ( this.state.fileUploadProp ) {

            fileUploadOptions = { ...fileUploadOptions, ...this.#uploadSettings[ this.state.fileUploadProp ] }

        }

        const refereeBans = this.state.refereeBans && this.state.refereeBans.map( e => {

            return {

                editable: true,
                id:       e.Id,
                label:    e.Team ? ( e.Team.ShortName + ( e.Team.League ? ( ' (' + ( e.Team.League.ShortName || e.Team.League.Name ) + ')' ) : '' ) ) : '???',
                teamId:   e.Team ? e.Team.Id : 0,
                title:    e.Team ? e.Team.Name : '',

            }

        } )

        const refereeRelations = this.state.refereeRelations && this.state.refereeRelations.map( e => {

            const refereeIndex = e.Referee1 && e.Referee1.Id === refereeId ? 2 : e.Referee2 && e.Referee2.Id === refereeId ? 1 : -1

            return {

                className:    'relation-type-' + e.RelationType,
                editable:     true,
                id:           e.Id,
                label:        <>{ e.RelationType === 3 && <Icon icon='ban' /> } { getRefereeShortName( e[ 'Referee' + refereeIndex ] ) }</>,
                refereeId:    e[ 'Referee' + refereeIndex ] ? e[ 'Referee' + refereeIndex ].Id : 0,
                relationType: e.RelationType,
                title:        getRefereeFullName( e[ 'Referee' + refereeIndex ] ) + ' (' + relationTypes[ e.RelationType ] + ')',

                refereeIndex,

            }

        } )

        refereeBans      && sortByLabel( refereeBans      )
        refereeRelations && sortByLabel( refereeRelations )

        if ( this.state.referees ) {

            sortByLastnameAndFirstname( this.state.referees )

            this.state.referees.forEach( referee => refereeId !== referee.Id && ( ! refereeRelations || refereeRelations.findIndex( e => e.refereeId === referee.Id ) === -1 ) && referees.push( { value: referee.Id, label: getRefereeFullName( referee ) } ) )

        }

        if ( this.state.teams ) {

            this.state.teams.forEach( team => ( ! refereeBans || refereeBans.findIndex( e => e.teamId === team.Id ) === -1 ) && teams.push( { value: team.Id, label: team.Name + ( team.League && ( ' (' + ( team.League.ShortName || team.League.Name ) + ')' ) ) } ) )

            sortByLabel( teams )

        }

        const refereeRelationsFields = [

            { name: 'refereeId',    label: _( 'Schiedsrichter:in' ), options: referees                              },
            { name: 'relationType', label: _( 'Typ'               ), options: convertEnumToOptions( relationTypes ) },

        ]

        const refereeBansFields = [

            { name: 'teamId', label: _( 'Team' ), options: teams },

        ]

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='users' className='subheader-icon' /> { _( 'Benutzer:in' ) } { this.state.user && ( this.state.user.firstname || this.state.user.lastname ) && <span className='fw-300'>{ this.state.user.firstname || '' } { this.state.user.lastname || '' }</span> }

                    </h1>

                </div>

                <Card>

                    <Form onSubmit={ e => this.handleFormSubmit( e ) }>

                        <fieldset disabled={ formDisabled }>

                            <Tab.Container defaultActiveKey='basicData' onSelect={ e => this.selectTab( e ) } activeKey={ this.state.activeTab }>

                                { ( hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE ) || hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_ALL ) ) &&

                                    <Card.Header>

                                        <Nav variant='pills'>

                                            <Nav.Item role='button'><Nav.Link eventKey='basicData'>{ _( 'Stammdaten' ) }</Nav.Link></Nav.Item>

                                            { hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_LEAGUE  ) && this.renderTabButton( 'leaguePermissions',  _( 'Ligenberechtigungen'    ) ) }

                                            { hasPrivilege( this.props.user, PRIV_SAVE_REFEREE_ALL ) && this.renderTabButton( 'accountPermissions', _( 'Account Berechtigungen' ) ) }

                                            { this.props.showCloseButton && <Link to='/user' className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link> }

                                        </Nav>

                                    </Card.Header>

                                }

                                <Card.Body>

                                    <Tab.Content>

                                        <Tab.Pane eventKey='basicData'>

                                            <Row>

                                                <Col xl={ 6 }>

                                                    { this.renderInput( { name: 'firstname',    label: _( 'Vorname'                   ), ref: this.#dom.firstname                                                  } ) }

                                                    { this.renderInput( { name: 'lastname',     label: _( 'Nachname'                  ), ref: this.#dom.lastname                                                   } ) }

                                                    { this.renderInput( { name: 'passNumber',   label: _( 'Passnummer'                )                                                                            } ) }

                                                    { this.renderInput( { name: 'federationId', label: _( 'LEV'                       ), type: 'select',              options: this.state.federations              } ) }

                                                    { this.renderInput( { name: 'clubId',       label: _( 'Verein'                    ), type: 'select',              options: this.state.clubs                    } ) }

                                                    { this.renderInput( { name: 'accountEmail', label: _( 'Account E-Mail-Adresse'    ), ref: this.#dom.accountEmail                                               } ) }

                                                    { this.renderInput( { name: 'email',        label: _( 'Weitere E-Mail-Adresse(n)' ), type: 'multiText',           disabled: formDisabled                       } ) }

                                                    <Form.Group as={ Row } className='mb-3'>

                                                        <Form.Label column sm={ 3 }>{ _( 'Straße / Hausnummer' ) }</Form.Label>

                                                        <Col sm={ 9 }>

                                                            <InputGroup>

                                                                <Form.Control value={ this.state.user.street } name='street' onChange={ e => this.changeUser( e ) } />

                                                                <Form.Control value={ this.state.user.houseNumber } name='houseNumber' onChange={ e => this.changeUser( e ) } className='flex-grow-0' style={ { width: '8rem' } } />

                                                            </InputGroup>

                                                        </Col>

                                                    </Form.Group>

                                                    { this.renderInput( { name: 'postalCode',         label: _( 'PLZ'                )                                                                                                                                                                                                                       } ) }

                                                    { this.renderInput( { name: 'city',               label: _( 'Ort'                )                                                                                                                                                                                                                       } ) }

                                                    { this.renderInput( { name: 'nationalFederation', label: _( 'Nationaler Verband' )                                                                                                                                                                                                                       } ) }

                                                    { this.renderInput( { name: 'phone',              label: _( 'Telefonnummer(n)'   ), type: 'multiText', disabled: formDisabled                                                                                                                                                                            } ) }

                                                    { this.renderInput( { name: 'birthday',           label: _( 'Geburtsdatum'       ), inputType: 'date'                                                                                                                                                                                                    } ) }

                                                    { this.renderInput( { name: 'iban',               label: _( 'IBAN'               ), infoText: this.state.user.iban && ! isValidIBAN( electronicFormatIBAN( this.state.user.iban ) ) && <div className='invalid-feedback d-block'>{ _( 'Kein gültiger IBAN.' ) }</div>, onBlur: e => this.changeIBAN( e ) } ) }

                                                    { this.renderInput( { name: 'bic',                label: _( 'BIC'                ), infoText: this.state.user.bic  && ! isValidBIC(  this.state.user.bic  ) && <div className='invalid-feedback d-block'>{ _( 'Kein gültiger BIC.'  ) }</div>                                                            } ) }

                                                    { this.renderInput( { name: 'isTaxable',          label: _( 'Steuerpflichtig'    ), type: 'switch'                                                                                                                                                                                                       } ) }

                                                </Col>

                                                <Col xl={ 6 }>

                                                    <Row>

                                                        <Col xl={ 6 }>

                                                            { this.renderInput( { name: 'refereeJerseySize',  label: _( 'SR-Trikot'       ), type: 'select', options: this.#clothingSizes, labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            { this.renderInput( { name: 'refereePantsSize',   label: _( 'SR-Hose'         ), type: 'select', options: this.#clothingSizes, labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            { this.renderInput( { name: 'trainingJacketSize', label: _( 'Trainingsjacke'  ), type: 'select', options: this.#clothingSizes, labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            { this.renderInput( { name: 'trainingPantsSize',  label: _( 'Trainingshose'   ), type: 'select', options: this.#clothingSizes, labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            { this.renderInput( { name: 'poloSize',           label: _( 'Polo'            ), type: 'select', options: this.#clothingSizes, labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            { this.renderInput( { name: 'jacketSize',         label: _( 'Jacke'           ), type: 'select', options: this.#clothingSizes, labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            { this.renderInput( { name: 'shoeSize',           label: _( 'Schuhgröße (US)' ), inputType: 'number', step: '0.1',             labelWidthXl: 6, inputWidthXl: 6 } ) }

                                                            <Form.Group as={ Row } className='mb-3' controlId='head-size'>

                                                                <Form.Label column sm={ 3 } xl={ 6 }>

                                                                    { _( 'Kopfgröße' ) }

                                                                    <a href='https://www.sportega.de/s/wie-waehlt-man-den-richtigen-eishockey-helm-945' target='_blank' rel='noreferrer'><Icon icon='info-circle' className='ms-1' /></a>

                                                                </Form.Label>

                                                                <Col sm={ 9 } xl={ 6 }>

                                                                    <InputGroup>

                                                                        <Form.Control value={ this.state.user.headSize === null ? '' : this.state.user.headSize } name='headSize' onChange={ e => this.changeUser( e ) } type='number' />

                                                                        <InputGroup.Text>cm</InputGroup.Text>

                                                                    </InputGroup>

                                                                </Col>

                                                            </Form.Group>

                                                        </Col>

                                                        <Col xl={ 6 }>

                                                            <div className='text-center'>

                                                                { this.renderImages() }

                                                            </div>

                                                            <div className='text-center mt-3'>

                                                                {

                                                                    this.state.user.image ?

                                                                        <Button onClick={ () => this.deleteImage( 'image' ) } className='mx-1 mt-1' variant='warning'>{ _( 'Profilbild löschen' ) }</Button>

                                                                    :

                                                                        <Button onClick={ () => this.handleUploadProfileImageClick() } className='mx-1 mt-1' disabled={ ! this.state.user.id }>{ _( 'Profilbild hochladen' ) }</Button>

                                                                }

                                                                {

                                                                    this.state.user.pressImage ?

                                                                        <Button onClick={ () => this.deleteImage( 'pressImage' ) } className='mx-1 mt-1' variant='warning'>{ _( 'Pressefoto löschen' ) }</Button>

                                                                    :

                                                                        <Button onClick={ () => this.handleUploadPressImageClick() } className='mx-1 mt-1' variant='secondary' disabled={ ! this.state.user.id }>{ _( 'Pressefoto hochladen' ) }</Button>

                                                                }

                                                                <div className='mt-2 mb-3'><a href='https://www.persofoto.de/' target='_blank' rel='noreferrer'><Icon icon='arrow-up-right-from-square' /> { _( 'Foto erstellen' ) }</a></div>

                                                                { ! this.state.user.id && <div className='mt-1 text-muted'><Icon icon='info-circle' /> { _( 'Benutzer:in speichern, um Bilder hochladen zu können.' ) }</div> }

                                                            </div>

                                                        </Col>

                                                    </Row>

                                                    <Row>

                                                        <Col>

                                                            { this.renderInput( { name: 'refereeNote', label: _( 'Notizen' ), as: 'textarea', rows: 8  } ) }

                                                        </Col>

                                                    </Row>

                                                    <Row>

                                                        <Col>

                                                            { this.renderInput( {

                                                                disabled: formDisabled || ! this.state.user.id,
                                                                infoText: this.state.user.id ? <div className='mt-2 p-1'>{ this.state.absenceReasons && this.state.absenceReasons.map( ( e, i ) => <span className='me-3 text-nowrap' key={ i }><Badge bg={ getStyle( i ) } className='labeled-badge'> </Badge> { e.Name }</span> ) }</div> : <div className='mt-1 text-muted'><Icon icon='info-circle' /> { _( 'Benutzer:in speichern, um Abwesenheit eintragen zu können.' ) }</div>,
                                                                label:    _( 'Abwesenheit' ),
                                                                name:     'absences',
                                                                type:     'absences',

                                                            } ) }

                                                        </Col>

                                                    </Row>

                                                </Col>

                                            </Row>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey='leaguePermissions'>

                                            <Row className='league-permissions'>

                                                <Col xl={ 9 } lg={ 12 }>

                                                    <Row className='d-none d-lg-flex'>

                                                        <Col lg={ 2 }></Col>

                                                        <Col lg={ 6 }>

                                                            <Row>

                                                                { this.state.licenseTypes && this.state.licenseTypes.map( ( licenseType, i ) =>

                                                                    licenseType.Classifiable && this.state.user.licenseTypes.find( e => e.value === licenseType.Id ) &&

                                                                    <Col className={ 'text-center fw-900' + ( i ? ' border-left' : '' ) } key={ i }>

                                                                        { licenseType.Name }

                                                                        <div className='d-flex mt-2'>

                                                                            { this.renderRefereeCategories() }

                                                                        </div>

                                                                    </Col>

                                                                ) }

                                                            </Row>

                                                        </Col>

                                                        <Col lg={ 2 } className='text-center fw-900 border-left'>%</Col>

                                                        <Col lg={ 2 } className='text-center fw-900 border-left'>{ _( 'Kilometer' ) }</Col>

                                                    </Row>

                                                    { this.state.leagueTypes && this.state.leagueTypes.map( ( leagueType, i ) =>

                                                        <LeaguePermission
                                                            key={ i }
                                                            leagueType={ leagueType }
                                                            licenseTypes={ this.state.licenseTypes }
                                                            onChange={ ( permission, licenseType, value ) => this.changeLeaguePermission( permission, licenseType, value ) }
                                                            permission={ this.state.leaguePermissions.find( f => f.LeagueType === leagueType.Id ) }
                                                            ref={ e => this.#dom.leaguePermissionPercentages[ i ] = e }
                                                            userLicenseTypes={ this.state.user.licenseTypes }
                                                        />

                                                    ) }

                                                </Col>

                                                <Col xl={ 3 } className='text-center d-none d-xl-block'>

                                                    { this.renderImages() }

                                                </Col>

                                            </Row>

                                            <Row className='mt-5 d-none d-xl-flex'></Row>
                                            <Row className='mt-5 d-none d-xl-flex'></Row>

                                            <Row className='mt-5'>

                                                <Col xl={ 6 }>

                                                    <Form.Group as={ Row } className='mb-3' controlId='refereeRelations'>

                                                        <Form.Label column sm={ 3 }>{ _( 'Partner' ) }</Form.Label>

                                                        <Col sm={ 9 }>

                                                            <MultiSelect
                                                                disabled={ formDisabled }
                                                                editable={ true }
                                                                fields={ refereeRelationsFields }
                                                                initialValue={ refereeRelations }
                                                                onChange={ e => this.saveRefereeRelation( e ) }
                                                                onCreateItem={ () => { return { id: 0, refereeId: referees.length ? referees[ 0 ].value : 0, refereeIndex: 2, relationType: 0 } } }
                                                                onDelete={ e => this.deleteRefereeRelation( e ) }
                                                            />

                                                            <div className='mt-2 p-1'>{ relationTypes.map( ( e, i ) => <span className='me-3 text-nowrap' key={ i }><Badge className={ 'labeled-badge relation-type-' + i }> </Badge> { e }</span> ) }</div>

                                                        </Col>

                                                    </Form.Group>

                                                    <Form.Group as={ Row } className='mb-3' controlId='refereeBans'>

                                                        <Form.Label column sm={ 3 }>{ _( 'Teams blocken' ) }</Form.Label>

                                                        <Col sm={ 9 }>

                                                            <MultiSelect
                                                                disabled={ formDisabled }
                                                                editable={ true }
                                                                fields={ refereeBansFields }
                                                                initialValue={ refereeBans }
                                                                onChange={ e => this.saveRefereeBan( e ) }
                                                                onCreateItem={ () => { return { id: 0, teamId: teams.length ? teams[ 0 ].value : 0 } } }
                                                                onDelete={ e => this.deleteRefereeBan( e ) }
                                                            />

                                                        </Col>

                                                    </Form.Group>

                                                    { this.#enableRegions && this.renderInput( {

                                                        disabled: formDisabled,
                                                        label:    _( 'Regionen' ),
                                                        name:     'regions',
                                                        onChange: e => this.changeUser( { target: { name: 'regions', value: e } } ),
                                                        options:  this.state.regions,
                                                        type:     'multiSelect',
                                                        value:    this.state.user.regions,

                                                    } ) }

                                                </Col>

                                                <Col xl={ 6 }>

                                                    { this.renderInput( { name: 'leagueManagerNote', label: _( 'Notizen' ), as: 'textarea', rows: 8 } ) }

                                                </Col>

                                            </Row>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey='accountPermissions'>

                                            <Row className='mb-3'>

                                                <Col xl={ 9 } lg={ 12 }>

                                                    { this.renderInput( {

                                                        disabled: formDisabled,
                                                        label:    _( 'Lizenz' ),
                                                        name:     'licenseTypes',
                                                        onChange: e => this.changeUser( { target: { name: 'licenseTypes', value: e } } ),
                                                        options:  this.state.licenseTypes && this.state.licenseTypes.map( e => { return { value: e.Id, label: e.Name } } ),
                                                        type:     'multiSelect',
                                                        value:    this.state.user.licenseTypes,

                                                    } ) }

                                                    <Row className='mb-3'>

                                                        <Form.Label column sm={ 3 }>{ _( 'Int. Lizenz' ) }</Form.Label>

                                                        <Col sm={ 9 }>

                                                            <Row>

                                                                <Form.Group as={ Col } xl={ 4 } controlId='international-referee-license'>

                                                                    <Row>

                                                                        <Form.Label column xl={ 6 }>{ _( 'Schiedsrichter' ) }</Form.Label>

                                                                        <Col xl={ 6 }>

                                                                            <Form.Select value={ this.state.user.internationalRefereeLicense === null ? '' : this.state.user.internationalRefereeLicense } name='internationalRefereeLicense' onChange={ e => this.changeUser( e ) }>

                                                                                <option value=''></option>

                                                                                { refereeCategories.map( ( e, i ) => <option value={ i } key={ i }>{ e }</option> ) }

                                                                            </Form.Select>

                                                                        </Col>

                                                                    </Row>

                                                                </Form.Group>

                                                                <Form.Group as={ Col } xl={ 4 } controlId='international-linesmen-license'>

                                                                    <Row>

                                                                        <Form.Label column xl={ 6 }>{ _( 'Linesperson' ) }</Form.Label>

                                                                        <Col xl={ 6 }>

                                                                            <Form.Select value={ this.state.user.internationalLinespersonLicense === null ? '' : this.state.user.internationalLinespersonLicense } name='internationalLinespersonLicense' onChange={ e => this.changeUser( e ) }>

                                                                                <option value=''></option>

                                                                                { refereeCategories.map( ( e, i ) => <option value={ i } key={ i }>{ e }</option> ) }

                                                                            </Form.Select>

                                                                        </Col>

                                                                    </Row>

                                                                </Form.Group>

                                                                <Form.Group as={ Col } xl={ 4 } controlId='international-coach'>

                                                                    <Row>

                                                                        <Form.Label column xl={ 6 }>{ _( 'Coach' ) }</Form.Label>

                                                                        <Col xl={ 6 } className='fs-2 standalone-switch'>

                                                                            <Form.Check type='switch' checked={ this.state.user.internationalCoach } name='internationalCoach' onChange={ e => this.changeUser( e ) } />

                                                                        </Col>

                                                                    </Row>

                                                                </Form.Group>

                                                            </Row>

                                                        </Col>

                                                    </Row>

                                                    { this.renderInput( { name: 'isPaused', label: _( 'Pausiert' ), type: 'switch' } ) }

                                                    { this.renderInput( { name: 'isTrainee', label: _( 'Trainee' ), type: 'switch' } ) }

                                                    { this.renderInput( {

                                                        disabled: formDisabled,
                                                        label:    _( 'Rollen' ),
                                                        name:     'roles',
                                                        onChange: e => this.changeUser( { target: { name: 'roles', value: e } } ),
                                                        options:  this.state.roles && this.state.roles.map( e => { return { value: e.Id, label: e.Name } } ),
                                                        type:     'multiSelect',
                                                        value:    this.state.user.roles,

                                                    } ) }

                                                </Col>

                                                <Col xl={ 3 } className='text-center d-none d-xl-block'>

                                                    { this.renderImages() }

                                                </Col>

                                            </Row>

                                        </Tab.Pane>

                                    </Tab.Content>

                                </Card.Body>

                                <Card.Footer>

                                    <div className='d-flex'>

                                        <Button type='submit'>{ _( 'Speichern' ) }</Button>

                                        { refereeId ? hasPrivilege( this.props.user, PRIV_DELETE_REFEREE ) && <Button variant='danger' className='ms-auto' onClick={ () => this.handleDeleteRefereeClick() }>{ _( 'Löschen' ) }</Button> : '' }

                                    </div>

                                </Card.Footer>

                            </Tab.Container>

                        </fieldset>

                    </Form>

                </Card>

                <FileUploadModal { ...fileUploadOptions } />

                <Modal show={ this.state.deleteRefereeModalOpen } onHide={ () => this.hideDeleteRefereeModal() } backdrop='static' centered>

                    <Modal.Header>

                        <Modal.Title>{ _( 'Benutzer:in löschen?' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body className='lead'>

                        {

                            this.state.isDeletingReferee ?

                                <div className='my-5 text-center'>

                                    <Icon icon='spinner' spin /> { _( 'Benutzer:in wird gelöscht' ) }

                                </div>

                            :

                                <>

                                    <p>{ _( 'Es werden alle Daten und Einteilungen dieses Benutzers/dieser Benutzerin gelöscht.' ) }</p>

                                    <div className='my-5 text-center text-warning'>

                                        <p className='fs-1'><Icon icon='exclamation-triangle' size='2' /></p>

                                        <p className='fs-3 text-warning fw-bold'>{ _( 'Dieser Vorgang kann nicht rückgängig gemacht werden!' ) }</p>

                                    </div>

                                    { this.state.hasDeletingRefereeError && <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                                </>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideDeleteRefereeModal() } disabled={ this.state.isDeletingReferee } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.deleteReferee() } disabled={ this.state.isDeletingReferee } variant='danger'>{ _( 'Benutzer:in löschen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissDeleteImageSuccessMessage() } show={ this.state.showDeleteImageSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Bild wurde gelöscht.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissUploadImageSuccessMessage() } show={ this.state.showUploadImageSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Bild wurde hochgeladen.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

                <Prompt
                    message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' ) }
                    when={ this.state.hasUnsavedChanges }
                />

                { this.state.user.image &&

                    <Lightbox show={ this.state.lightboxOpen } onClose={ () => this.toggleLightbox() }>

                        <img src={ this.state.user.image } alt='' className='shadow' />

                    </Lightbox>

                }

            </>

        )

    }

}

export default withRouter( User )