import React from 'react'
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { createDate, formatDate, formatTime, parseTime, stripDate } from '../../util/date'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { getDateRangePickerLocale } from '../../util'

class MultiDateInput extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            activeItem:  null,
            focusedItem: null

        }

    }

    changeItem( e ) {

        let value = e.target.value

        if ( e.target.type === 'time' ) {

            const time = parseTime( e.target.value )
            value      = this.state.activeItem[ e.target.name ]

            if ( ! value ) {

                value = createDate()

            }

            value.setHours(   time.hours   )
            value.setMinutes( time.minutes )

        } else if ( e.target.type === 'checkbox' ) {

            value = e.target.checked

        }

        this.setState( { activeItem: { ...this.state.activeItem, [ e.target.name ]: value } } )

    }

    createItem() {

        return {

            Editable: true,
            From:     stripDate( createDate() ),
            FullDay:  true,
            Id:       0,
            Interval: null,
            Serial:   false,
            To:       stripDate( createDate() ),
            Type:     this.props.typeOptions && this.props.typeOptions.length ? this.props.typeOptions[ 0 ].value : 0

        }

    }

    deleteItem( item ) {

        this.props.onDelete && this.props.onDelete( item )

    }

    focusItem( focusedItem ) {

        this.props.editable && ! this.props.disabled && ( ! focusedItem || focusedItem.Editable ) && this.setState( { focusedItem } )

    }

    getLabel( item ) {

        const from     = formatDate( item.From )
        const to       = formatDate( item.To   )
          let label    = ''

        if ( formatDate( item.From ) === to ) {

            label += from

        } else {

            const fromDate = createDate( item.From )
            const toDate   = createDate( item.To   )

            label += formatDate( item.From, { year: fromDate.getFullYear() === toDate.getFullYear() ? 'none' : '', month: fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth() ? 'none' : '' } ) + '-' + to

        }

        if ( ! item.FullDay ) {

            label += ' ' + formatTime( item.From ) + '-' + formatTime( item.To )

        }

        return label

    }

    handleDateRangeSelect( e, f ) {

        this.setState( {

            activeItem: {

                ...this.state.activeItem,

                From: f.startDate ? f.startDate.toDate() : null,
                To:   f.endDate   ? f.endDate.toDate()   : null,

            }

        } )

    }

    hideModal() {

        this.setState( { activeItem: null } )

    }

    openItem( e ) {

        this.setState( {

            activeItem: {

                ...e,

                From: createDate( e.From ),
                To:   createDate( e.To   ),

            }

        } )

    }

    saveItem() {

        const activeItem = this.state.activeItem

        this.setState( { activeItem: null, focusedItem: null }, () => this.props.onChange && this.props.onChange( activeItem ) )

    }

    renderItem( item, i ) {

        return (

            <Badge key={ i } bg={ item.bg || 'primary' }>

                {

                    this.props.editable && ! this.props.disabled && this.state.focusedItem && this.state.focusedItem.Editable && this.state.focusedItem.Id === item.Id ?

                        <div className='mdi-controls'>

                            <span onClick={ () => this.openItem( item ) }>{ _( 'Bearbeiten' ) }</span>
                            <span> | </span>
                            <span onClick={ () => this.deleteItem( item ) }>{ _( 'Löschen' ) }</span>
                            <span onClick={ () => this.focusItem( null ) }><Icon icon='times' /></span>

                        </div>

                    :

                        <span onClick={ () => this.focusItem( item ) } className={ this.props.editable && ! this.props.disabled && item.Editable ? 'mdi-display' : '' }>{ this.getLabel( item ) }</span>

                }

            </Badge>

        )

    }

    render() {

        const classes = [ 'form-control', 'multi-date-input' ]

        this.props.disabled   && classes.push( 'disabled'   )
        this.props.editable   && classes.push( 'editable'   )
        this.props.standalone && classes.push( 'standalone' )

        return (

            <>

                <div className={ classes.join( ' ' ) }>

                    { this.props.initialValue && this.props.initialValue.map( ( e, i ) => this.renderItem( e, i ) ) }

                    { this.props.editable && ! this.props.disabled &&

                        <Button variant='link' onClick={ () => this.openItem( this.createItem() ) }><Icon icon='circle-plus' /></Button>

                    }

                </div>

                <Modal show={ this.state.activeItem !== null } onHide={ () => this.hideModal() } centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        <Form.Group as={ Row } className='mb-3' controlId='DateRange'>

                            <Form.Label column sm={ 3 }>{ _( 'Datum' ) }</Form.Label>

                            <Col sm={ 9 }>

                                { this.state.activeItem &&

                                    <DateRangePicker initialSettings={ {

                                        endDate:   this.state.activeItem.To,
                                        locale:    getDateRangePickerLocale(),
                                        startDate: this.state.activeItem.From,

                                    } } onApply={ ( e, f ) => this.handleDateRangeSelect( e, f ) }>

                                        <Form.Control />

                                    </DateRangePicker>

                                }

                            </Col>

                        </Form.Group>

                        <Form.Group as={ Row } className='mb-3' controlId='FullDay'>

                            <Form.Label column sm={ 3 }>{ _( 'Ganztägig' ) }</Form.Label>

                            <Col sm={ 9 } className='fs-2 standalone-switch'>

                                <Form.Check type='switch' checked={ this.state.activeItem ? this.state.activeItem.FullDay : false } name='FullDay' onChange={ e => this.changeItem( e ) } />

                            </Col>

                        </Form.Group>

                        { this.state.activeItem && ! this.state.activeItem.FullDay &&

                            <>

                                <Form.Group as={ Row } className='mb-3' controlId='TimeFrom'>

                                    <Form.Label column sm={ 3 }>{ _( 'Von' ) }</Form.Label>

                                    <Col sm={ 9 }>

                                        <Form.Control type='time' value={ this.state.activeItem ? formatTime( this.state.activeItem.From ) : '' } name='From' onChange={ e => this.changeItem( e ) } />

                                    </Col>

                                </Form.Group>

                                <Form.Group as={ Row } className='mb-3' controlId='TimeTo'>

                                    <Form.Label column sm={ 3 }>{ _( 'Bis' ) }</Form.Label>

                                    <Col sm={ 9 }>

                                        <Form.Control type='time' value={ this.state.activeItem ? formatTime( this.state.activeItem.To ) : '' } name='To' onChange={ e => this.changeItem( e ) } />

                                    </Col>

                                </Form.Group>

                            </>

                        }

                        { this.props.typeOptions &&

                            <Form.Group as={ Row } className='mb-3' controlId='Type'>

                                <Form.Label column sm={ 3 }>{ _( 'Art' ) }</Form.Label>

                                <Col sm={ 9 }>

                                    <Form.Select value={ this.state.activeItem ? this.state.activeItem.Type : '' } name='Type' onChange={ e => this.changeItem( e ) }>

                                        { this.props.typeOptions && this.props.typeOptions.map( ( option, i ) => <option value={ option.value } key={ i }>{ option.label }</option> ) }

                                    </Form.Select>

                                </Col>

                            </Form.Group>

                        }

                        {/* TODO Serial/Interval */}

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.saveItem() }>{ _( 'Speichern' ) }</Button>

                        <div className='border-top mt-3 pt-1 text-muted w-100'><Icon icon='info-circle' /> { _( 'Der Eintrag wird sofort gespeichert.' ) }</div>

                    </Modal.Footer>

                </Modal>

            </>

        )

    }

}

export default MultiDateInput