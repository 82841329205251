import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Users from '../../components/pages/Users'
import { setFilterLeagueType, setUsersFilterDate, setUsersFilterQuery } from '../../store/actions'
import { createDate } from '../../util/date'

const mapStateToProps = state => {

    return {

        filterDate:       createDate( state.ui.usersFilterDate ),
        filterLeagueType: state.ui.filterLeagueType || '',
        filterQuery:      state.ui.usersFilterQuery || '',
        isLoading:        state.app.isLoading,
        token:            state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onSetFilterLeagueType: filterLeagueType => dispatch( setFilterLeagueType(              filterLeagueType ) ),
        onSetFilterDate:       filterDate       => dispatch( setUsersFilterDate(               filterDate       ) ),
        onSetFilterQuery:      filterQuery      => dispatch( setUsersFilterQuery(              filterQuery      ) ),
        onToggleIsLoading:     isLoading        => dispatch( storeActions.app.toggleIsLoading( isLoading        ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Users )