import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import storage from 'redux-persist/lib/storage'
import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createRoot } from 'react-dom/client'
import { reducers } from '@hockeydata/skynet'
import App from './js/containers/App'
import { getUrlParameter } from './js/util'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { localChanges, ui } from './js/store/reducers'

import './css/imports.scss'
import '@hockeydata/skynet/dist/index.css'
import './css/index.scss'

const theme            = getUrlParameter( 'theme' )
const combinedReducers = combineReducers( { app: reducers.app, auth: reducers.auth, localChanges, ui } )
const root             = createRoot( document.getElementById( 'app-root' ) )
const persistConfig    = { key: 'easyref-root', storage, blacklist: [] }
const persistedReducer = persistReducer( persistConfig, combinedReducers )
const store            = createStore( persistedReducer )
const persistor        = persistStore( store )

if ( theme ) {

    import( '@hockeydata/skynet/dist/vendor/smartadmin-4.5.1/css/themes/cust-theme-' + theme + '.css' )

}

root.render(

    <Provider store={ store }>

      <PersistGate persistor={ persistor }>

          <App />

      </PersistGate>

  </Provider>

)

serviceWorkerRegistration.register( {

    onUpdate: registration => {

        if ( registration && registration.waiting ) {

            window.appUpdateAvailable = true

        }

    }

} )