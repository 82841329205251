import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import { Card, Col, Row } from 'react-bootstrap'
import { formatDate } from '../../util/date'
import RefereeBadge from './RefereeBadge'

class GameInfoCard extends React.Component {

    renderGameRow( game, i ) {

        return (

            <Row className='mb-1' key={ i }>

                <Col>{ formatDate( game.scheduledDate, { year: 'none', weekday: 'short' } ) }</Col>

                { this.props.neededReferees && this.props.neededReferees.map( ( e, i ) => <Col key={ i }>{ this.renderReferee( game, e ) }</Col> ) }

                <Col>{ game.homeTeam }</Col>
                <Col>{ game.awayTeam }</Col>

            </Row>

        )

    }

    renderReferee( game, neededReferee ) {

        if ( ! game.referees ) {

            return

        }

        const assignment = game.referees.find( referee => referee.RefereeSystemJob.ShortName === neededReferee.RefereeSystemJob && referee.RefereeSystemJob.LicenseTypeId === neededReferee.RefereeSystemLicenseTypeId )
          let federation = null
          let referee    = null

        if ( assignment ) {

            if ( assignment.FederationId ) {

                federation = this.props.federations.find( e => e.Id === assignment.FederationId )

            } else if ( assignment.RefereeFirstName || assignment.RefereeLastName ) {

                referee = {

                    Firstname: assignment.RefereeFirstName,
                    Lastname:  assignment.RefereeLastName,

                }

            }

        }

        if ( federation || referee ) {

            return (

                <RefereeBadge
                    className='outline'
                    displayName={ federation ? federation.ShortName : null }
                    referee={ referee }
                    title={ federation && federation.Name }
                />

            )

        }

        return ''

    }

    render() {

        return (

            <Card className={ 'mb-2 game-info-card text-center' + ( this.props.className ? ' ' + this.props.className : '' ) }>

                <Card.Header>{ this.props.title }</Card.Header>

                <Card.Body>

                    <Row className='border-bottom fw-700'>

                        <Col>{ _( 'Datum' ) }</Col>

                        { this.props.neededReferees && this.props.neededReferees.map( ( e, i ) => <Col key={ i }>{ e.RefereeSystemJob }</Col> ) }

                        <Col>{ _( 'Heim'  ) }</Col>
                        <Col>{ _( 'Gast'  ) }</Col>

                    </Row>

                    { this.props.games && <div className='games'>{ this.props.games.map( ( e, i ) => this.renderGameRow( e, i ) ) }</div> }

                </Card.Body>

            </Card>

        )

    }

}

export default GameInfoCard