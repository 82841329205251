import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Badge, Button, Col, Dropdown, Form, Modal, Row, SplitButton, Toast, ToastContainer } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { Editor } from '@tinymce/tinymce-react'
import TreeSelect from '../controls/TreeSelect'
import { convertLeagues, convertOptions, createOption, deepCopy, getDateRangePickerLocale, getEditorConfig } from '../../util'
import { fillDate, formatDate, formatTime } from '../../util/date'
import NotificationGameRow from '../elements/NotificationGameRow'
import MultiTextInput from '../controls/MultiTextInput'
import { hasPrivilege } from '../../util/permissions'
import { PRIV_LOAD_URGENT_CHANGES } from '../../util/constants'
import UrgentChanges from '../../containers/elements/UrgentChanges'

class Notification extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            customMessageContent:           '',
            customMessageGames:             [],
            customMessageModalOpen:         false,
            customMessageRecipients:        [],
            divisions:                      null,
            federations:                    null,
            games:                          null,
            hasCustomMessageSendingError:   false,
            hasLoadingError:                false,
            hasNotifyingError:              false,
            gamesNotified:                  null,
            gamesNotifying:                 [],
            gamesReleased:                  null,
            isLoadingGames:                 false,
            isNotifying:                    false,
            isSendingCustomMessage:         false,
            selectedGamesNotified:          [],
            selectedGamesReleased:          [],
            sendingCustomMessageSuccessful: false,

        }

    }

    closeCustomMessageModal() {

        this.setState( {

            customMessageContent:           '',
            customMessageGames:             [],
            customMessageModalOpen:         false,
            customMessageRecipients:        [],
            hasCustomMessageSendingError:   false,
            isSendingCustomMessage:         false,
            sendingCustomMessageSuccessful: false,

        } )

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissNotifyingError() {

        this.setState( { hasNotifyingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    errorNotifying() {

        this.setState( { gamesNotifying: [], hasNotifyingError: true } )

    }

    errorSendingCustomMessage() {

        this.setState( { hasCustomMessageSendingError: true } )

    }

    handleAllGamesSelect( e, games, selectedGamesName ) {

        const selectedGames = []

        e.target.checked && games && games.forEach( e => selectedGames.push( e.Id ) )

        this.setState( { [ selectedGamesName ]: selectedGames } )

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleGameSelect( e, selectedGamesName ) {

          let selectedGames = deepCopy( this.state[ selectedGamesName ] )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { [ selectedGamesName ]:  selectedGames } )

    }

    isGameNotified( game ) {

        return game.MapRefereeGames && game.MapRefereeGames.find( e => e.Status > 0 )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/Schedule/getScheduleableLeagues', name: 'divisions',  fn: convertLeagues } ),
                this.loadItems( { url: 'api/Federation/Get',                  name: 'federations'                    } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => {

                    this.props.onToggleIsLoading( false )

                    this.loadGames()

                } )

        }, 400 ) )

    }

    loadGames() {

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return

        }

        const success = games => {

            this.setState( { games }, () => this.splitGames() )

        }

        const data = {

            divIds:   { __arr: true, values: this.props.divisions },
            from:     this.props.dateRangeFrom.toJSON(),
            released: true,
            to:       this.props.dateRangeTo.toJSON(),
            token:    this.props.token,

        }

        this.setState( { hasLoadingError: false, isLoadingGames: true }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            ajax( getDataUrl( 'api/Schedule/getGames' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.setState( { isLoadingGames: false }, () => this.props.onToggleIsLoading( false ) ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    notifyGames( gameIds, selectedGamesName ) {

        this.setState( { gamesNotifying: gameIds, hasNotifyingError: false, isNotifying: true }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const success = updatedGames => {

                const selectedGames = this.state[ selectedGamesName ].filter( e => this.state.gamesNotifying.indexOf( e ) === -1 )
                const games         = this.state.games && deepCopy( this.state.games ).map( game => {

                    game            = updatedGames.find( updatedGame => updatedGame.Id === game.Id ) || game
                    game.isNotified = game.isNotified || this.state.gamesNotifying.indexOf( game.Id ) !== -1

                    return game

                } )

                this.setState( {

                    [ selectedGamesName ]: selectedGames,
                    gamesNotifying: [],

                    games,

                }, () => this.splitGames() )

            }

            ajax( getDataUrl( 'api/Schedule/notifyRefs' ), { token: this.props.token, notifiableGameIds: { __arr: true, values: this.state.gamesNotifying } }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success( e.Data ) : this.errorNotifying() )
                .catch( () => this.errorNotifying() )
                .finally( () => this.setState( { isNotifying: false }, () => this.props.onToggleIsLoading( false ) ) )

        }, 400 ) )

    }

    openCustomMessage( gameIds, games ) {

        const customMessageGames      = []
        const customMessageRecipients = []

        gameIds.forEach( gameId => {

            const game = games.find( e => e.Id === gameId )

            if ( game ) {

                customMessageGames.push( game )

                game.MapRefereeGames && game.MapRefereeGames.forEach( e => e.RefereeMail && customMessageRecipients.findIndex( f => f.value === e.RefereeMail ) === -1 && customMessageRecipients.push( createOption( e.RefereeMail ) ) )

            }

        } )

        this.setState( { customMessageModalOpen: true, customMessageGames, customMessageRecipients } )

    }

    sendCustomMessage() {

        this.setState( { hasCustomMessageSendingError: false, isSendingCustomMessage: true }, () => {

            const data = {

                notifiableGameIds: { __arr: true, values: this.state.customMessageGames.map( e => e.Id ) },
                jsonMailList:      JSON.stringify( convertOptions( this.state.customMessageRecipients ) ),
                htmlText:          this.state.customMessageContent,
                token:             this.props.token,

            }

            ajax( getDataUrl( 'api/Schedule/sendCustomMessage' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { sendingCustomMessageSuccessful: true } ) : this.errorSendingCustomMessage() )
                .catch( () => this.errorSendingCustomMessage() )
                .finally( () => this.setState( { isSendingCustomMessage: false } ) )

        } )

    }

    splitGames() {

        const gamesNotified = []
        const gamesReleased = []

        this.state.games && this.state.games.forEach( game => this.isGameNotified( game ) ? gamesNotified.push( game ) : gamesReleased.push( game ) )

        this.setState( { gamesNotified, gamesReleased } )

    }

    renderCustomMessageGame( game, i ) {

        return (

            <Badge key={ i } className='me-1 fs-6' bg='secondary'>

                { formatDate( game.ScheduleDate, { year: 'none', weekday: 'short' } ) }
                { ' - ' }
                { formatTime( game.ScheduleDate ) }
                { ' | ' }
                { this.renderTeamName( game.HomeTeam ) }
                { ' ' + _( 'vs.' ) + ' ' }
                { this.renderTeamName( game.AwayTeam ) }

            </Badge>

        )

    }

    renderGameList( options ) {

        const selectedGames = this.state[ options.selectedGamesName ]

        return (

            <Row className='my-5'>

                <Col>

                    <h1>{ options.title }</h1>

                    <Form>

                        <Row className='align-items-center mb-3 px-3'>

                            <Col>

                                <Form.Check inline disabled={ ! options.games || ! options.games.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e, options.games, options.selectedGamesName ) } checked={ options.games && options.games.length ? selectedGames.length === options.games.length : false } />

                            </Col>

                            <Col className='text-right'>

                                <SplitButton
                                    align='end'
                                    disabled={ ! selectedGames.length || this.state.isNotifying }
                                    onClick={ () => this.notifyGames( selectedGames, options.selectedGamesName ) }
                                    title={ _( 'Auswahl versenden' ) }
                                >

                                    <Dropdown.Item as='button' type='button' onClick={ () => this.openCustomMessage( selectedGames, options.games ) }>{ _( 'Eigene Nachricht' ) }</Dropdown.Item>

                                </SplitButton>

                            </Col>

                        </Row>

                    </Form>

                    {

                        this.state.isLoadingGames ?

                            Array.from( { length: 3 } ).map( ( e, i ) => <NotificationGameRow key={ i } /> )

                        : options.games && ! options.games.length ?

                            <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                        : options.games ?

                            <div>

                                { options.games.map( ( game, i ) =>

                                    <NotificationGameRow
                                        federations={ this.state.federations }
                                        game={ game }
                                        isNotifying={ this.state.gamesNotifying.indexOf( game.Id ) !== -1 }
                                        isSelected={ selectedGames.indexOf( game.Id ) !== -1 }
                                        key={ i }
                                        onNotifyGame={ e => this.notifyGames( [ e ], options.selectedGamesName ) }
                                        onOpenCustomMessage={ e => this.openCustomMessage( [ e ], options.games ) }
                                        onSelectGame={ e => this.handleGameSelect( e, options.selectedGamesName ) }
                                    />

                                ) }

                            </div>

                        : ''

                    }

                </Col>

            </Row>

        )

    }

    renderTeamName( team ) {

        return team && <span title={ team.Name }>{ team.ShortName }</span>

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='envelope' className='subheader-icon' /> { _( 'Benachrichtigung' ) }

                    </h1>

                </div>

                { hasPrivilege( this.props.user, PRIV_LOAD_URGENT_CHANGES ) && <UrgentChanges /> }

                <Form>

                    <Row className='align-items-center mb-3 px-3'>

                        <Col xs={ 12 } sm={ 6 } xl={ { span: 4, offset: 2 } } className='mb-1'>

                            <Form.Group as={ Row } controlId='league'>

                                <Form.Label column xs={ 4 } className='text-right'>{ _( 'Liga' ) }</Form.Label>

                                <Col xs={ 8 }>

                                    <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                                </Col>

                            </Form.Group>

                        </Col>

                        <Col xs={ 12 } sm={ 6 } xl={ 4 } className='mb-1'>

                            <Form.Group as={ Row } controlId='date-range'>

                                <Form.Label column xs={ 4 } className='text-right'>{ _( 'Zeitraum' ) }</Form.Label>

                                <Col xs={ 8 }>

                                    <DateRangePicker initialSettings={ {

                                        endDate:   this.props.dateRangeTo,
                                        locale:    getDateRangePickerLocale(),
                                        startDate: this.props.dateRangeFrom,

                                    } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                        <Form.Control />

                                    </DateRangePicker>

                                </Col>

                            </Form.Group>

                        </Col>

                    </Row>

                </Form>

                { this.renderGameList( {

                    games:             this.state.gamesReleased,
                    selectedGamesName: 'selectedGamesReleased',
                    title:             _( 'Freigegeben' ),

                } ) }

                { this.renderGameList( {

                    games:             this.state.gamesNotified,
                    selectedGamesName: 'selectedGamesNotified',
                    title:             _( 'Bereits benachrichtigt' ),

                } ) }

                <Modal show={ this.state.customMessageModalOpen } onHide={ () => this.closeCustomMessageModal() } backdrop='static' size='xl' scrollable>

                    <Modal.Header closeButton>

                        <Modal.Title>{ _( 'Eigene Nachricht' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {

                            this.state.sendingCustomMessageSuccessful ?

                                <Alert variant='success' className='my-5 text-center fs-3'><Icon icon='check' /> { _( 'Nachricht wurde gesendet.' ) }</Alert>

                            :

                                <>

                                    <Row className='mb-2 align-items-center'>

                                        <Form.Label column sm={ 3 } lg={ 2 }>{ _( 'Betrifft' ) }:</Form.Label>

                                        <Col sm={ 9 } lg={ 10 }>

                                            { this.state.customMessageGames.map( ( e, i ) => this.renderCustomMessageGame( e, i ) ) }

                                        </Col>

                                    </Row>

                                    <Form.Group as={ Row } className='mb-3 pb-3 border-bottom'>

                                        <Form.Label column sm={ 3 } lg={ 2 }>{ _( 'Empfänger' ) }:</Form.Label>

                                        <Col sm={ 9 } lg={ 10 }>

                                            <MultiTextInput
                                                disabled={ this.state.isSendingCustomMessage }
                                                onChange={ e => this.setState( { customMessageRecipients: e.target.value } ) }
                                                value={ this.state.customMessageRecipients }
                                            />

                                        </Col>

                                    </Form.Group>

                                    <Editor
                                        disabled={ this.state.isSendingCustomMessage }
                                        init={ getEditorConfig() }
                                        onEditorChange={ customMessageContent => this.setState( { customMessageContent } ) }
                                        tinymceScriptSrc={ process.env.PUBLIC_URL + '/assets/vendor/tinymce/tinymce.min.js' }
                                    />

                                    { this.state.hasCustomMessageSendingError && <Alert variant='warning' className='mt-3'><Icon icon='exclamation-triangle' /> { _( 'Nachricht konnte nicht gesendet werden. Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                                </>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        {

                            this.state.sendingCustomMessageSuccessful ?

                                <Button onClick={ () => this.closeCustomMessageModal() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                            :

                                <>

                                    <Button onClick={ () => this.closeCustomMessageModal() } disabled={ this.state.isSendingCustomMessage } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                                    <Button onClick={ () => this.sendCustomMessage() } disabled={ this.state.customMessageRecipients.length === 0 || ! this.state.customMessageContent || this.state.isSendingCustomMessage }>{ _( 'Senden' ) }</Button>

                                </>

                        }

                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissNotifyingError() } show={ this.state.hasNotifyingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Benachrichtigung(en) konnte(n) nicht gesendet werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default Notification