import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import FeedbackForm from '../../components/elements/FeedbackForm'

const mapStateToProps = state => {

    return {

        isLoading: state.app.isLoading,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleIsLoading: isLoading => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( FeedbackForm )