import { Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Col, Form, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap'
import { deepCopy, getRefereeFullName, getShortName } from '../../util'
import { ASSIGNMENT_STATUS_DECLINED, PRIV_EDIT_GAMES_NOTES } from '../../util/constants'
import { getCustomer } from '../../util/customer'
import { formatDate, formatTime } from '../../util/date'
import { feedbackStatus } from '../../util/enum'
import { hasPrivilege, isAdmin, isScheduler } from '../../util/permissions'
import GameNotesModal from './GameNotesModal'
import RefereeAutoComplete from './RefereeAutoComplete'
import RefereeBadge from './RefereeBadge'

class ScheduleGameRow extends React.Component {

    #customer
    #isReleased

    constructor( props ) {

        super( props )

        this.state = {

            notesModalOpen: false,

        }

        this.#customer = getCustomer()

    }

    changeGame( e ) {

        this.props.onChange( { ...this.props.game, ...e } )

    }

    handleForceEditClick() {

        this.props.onForceEdit && this.props.onForceEdit( this.props.game )

    }

    handleGameClick() {

        this.props.onClick && this.props.onClick( this.props.game )

    }

    toggleNotesModal() {

        this.setState( { notesModalOpen: ! this.state.notesModalOpen } )

    }

    renderNeededReferee( commonlyNeededReferee, i ) {

        const classesNeededReferee = [ 'needed-referee', 'form-check-inline-no-margin' ]
        const classesRefereeBadge  = []
        const neededReferee        = this.props.refereeSystem && this.props.refereeSystem.NeededRefList && this.props.refereeSystem.NeededRefList.find( e => e.LicenseTypeId === commonlyNeededReferee.LicenseTypeId && e.ShortName === commonlyNeededReferee.ShortName )
          let federation           = null
          let referee              = null
          let title                = ''
          let canEditAssignment    = false
        const assignment           = neededReferee && this.props.game.MapRefereeGames && this.props.game.MapRefereeGames.find( e => e.RefereeSystemJob && e.RefereeSystemJob.LicenseTypeId === neededReferee.LicenseTypeId && e.RefereeSystemJob.ShortName === neededReferee.ShortName )

        if ( assignment ) {

            if ( assignment.FederationId ) {

                if ( this.props.federations ) {

                    federation = this.props.federations.find( e => e.Id === assignment.FederationId )
                    title      = federation.Name

                    classesRefereeBadge.push( 'referee-badge-default' )

                }

            } else if ( assignment.RefereeId ) {

                if ( this.props.referees ) {

                    referee = this.props.referees.find( e => e.Id === assignment.RefereeId )

                    if ( referee ) {

                        referee = deepCopy( referee )
                        title   = getRefereeFullName( referee )

                        if ( assignment.Status === ASSIGNMENT_STATUS_DECLINED ) {

                            title += ' (' + feedbackStatus[ 4 ] + ( assignment.DeclineReason === null ? '' : ', ' + assignment.DeclineReason ) + ')'

                        }


                        if ( this.#customer.groupAvailableRefereesByAvailability ) {

                            if ( this.props.assignmentAvailabilities && ( assignment.Id in this.props.assignmentAvailabilities ) ) {

                                referee.availability = this.props.assignmentAvailabilities[ assignment.Id ]

                            } else {

                                classesRefereeBadge.push( 'outline' )

                            }

                        } else {

                            referee.MapLeagueReferees && referee.MapLeagueReferees.forEach( refereePermission => {

                                if ( refereePermission.LeagueType === this.props.game.LeagueType ) {

                                    refereePermission.Classifications && refereePermission.Classifications.forEach( e => {

                                        if ( e.LicenseTypeId === assignment.RefereeSystemJob.LicenseTypeId ) {

                                            referee.classification = e.Category

                                        }

                                    } )

                                }

                            } )

                            ! ( 'classification' in referee ) && classesRefereeBadge.push( 'outline' )

                        }

                    } else {

                        console.warn( 'Inconsistent data! Referee ID [' + assignment.RefereeId + '] assigned in Game [' + this.props.game.Id + '] but not found in Referees.' )

                    }

                }

            }

            canEditAssignment = this.props.editable && ( ! assignment.Released || this.props.game.forceEdit || assignment.Status === ASSIGNMENT_STATUS_DECLINED )

            assignment.Force                                            && classesRefereeBadge.push( 'forced'          )
            assignment.Released                                         && classesRefereeBadge.push( 'released'        )
            this.props.localChanges && ( i in this.props.localChanges ) && classesRefereeBadge.push( 'locally-changed' )

        }

        this.props.isActive && i === this.props.activeNeededRefereeIndex          && classesNeededReferee.push( 'active'       )
        neededReferee && ! neededReferee.Required                                 && classesNeededReferee.push( 'not-required' )
        referee && assignment && assignment.Status === ASSIGNMENT_STATUS_DECLINED && classesNeededReferee.push( 'declined'     )

        const refereeAutoComplete = i === this.props.activeNeededRefereeIndex && canEditAssignment && this.props.availableReferees.length ? this.props.refereeAutoComplete : null

        return (

            <Col key={ i } xs={ 6 } lg className='d-flex narrow'>

                { neededReferee &&

                    <>

                        <div
                            className={ classesNeededReferee.join( ' ' ) }
                            onClick={ () => this.props.onNeededRefereeClick( i ) }
                        >

                            { refereeAutoComplete &&

                                <RefereeAutoComplete
                                    activeReferee={ this.props.activeReferee }
                                    game={ this.props.game }
                                    onRefereeClick={ ( referee, uid ) => this.props.onAvailableRefereeClick( referee, uid ) }
                                    onRefereeDoubleClick={ referee => this.props.onAvailableRefereeDoubleClick( referee ) }
                                    referees={ this.props.availableReferees }
                                    value={ refereeAutoComplete }
                                />

                            }

                            { assignment && assignment.RefereeSystemJob && <div className='needed-referee-label'>{ assignment.RefereeSystemJob.ShortName }</div> }

                            { ( federation || referee ) && ! refereeAutoComplete &&

                                <>

                                    <RefereeBadge
                                        active={ referee && this.props.activeReferee && this.props.activeReferee.referee.Id === referee.Id }
                                        className={ classesRefereeBadge.join( ' ' ) }
                                        displayName={ federation && federation.ShortName }
                                        onClick={ referee ? ( referee, uid ) => this.props.onRefereeClick( referee, uid ) : null }
                                        referee={ referee }
                                        selectable={ ! federation }
                                        title={ title }
                                        uid={ this.props.refereeUidBase + i }
                                    />

                                    { this.props.isActive && canEditAssignment && <span onClick={ () => this.props.onUnassign( i ) } className='unassign'><Icon icon='times'></Icon></span> }

                                </>

                            }

                        </div>

                        { neededReferee.Remoteable && <span className='remoteable-checkbox' title={ _( 'Remote' ) }><Form.Check inline checked={ assignment ? assignment.Remote : false } onChange={ canEditAssignment ? e => this.props.onToggleRemote( i, e.target.checked ) : () => {} } /></span> }

                    </>

                }

            </Col>

        )

    }

    renderNotesButton( hasNotes ) {

        return (

            <Icon icon='circle-info' role='button' onClick={ () => this.toggleNotesModal() } className={ 'ms-1' + ( hasNotes ? ' text-info' : '' ) } />

        )

    }

    render() {

        const classes    = [ 'py-1', 'schedule-game-row', 'align-items-center' ]
          let location   = ''
        this.#isReleased = !! this.props.game.MapRefereeGames

        this.props.game.MapRefereeGames && this.props.game.MapRefereeGames.forEach( e => {

            if ( e.RefereeSystemJob && e.RefereeSystemJob.Required && ! e.Released ) {

                this.#isReleased = false

            }

        } )

        this.props.localChanges && classes.push( 'locally-changed' )
        this.props.isActive     && classes.push( 'active'          )

        if ( this.props.game.Location ) {

            if ( this.props.game.Location.Address && this.props.game.Location.Address.City ) {

                location = this.props.game.Location.Address.City

            } else if ( this.props.game.Location.Name ) {

                location = this.props.game.Location.Name

            }

        }

        return (

            <>

                <Row className={ classes.join( ' ' ) } onClick={ () => this.handleGameClick() }>

                    <Col xs={ 11 } lg={ 5 } className={ this.#isReleased ? 'released' : '' }>

                        <Row className='align-items-center h-100'>

                            <Col>{ formatDate( this.props.game.ScheduleDate, { year: 'none', weekday: 'short' } ) } </Col>
                            <Col className='narrow'>{ this.props.game.GameDay }</Col>
                            <Col className='narrow'>{ formatTime( this.props.game.ScheduleDate ) }</Col>
                            { this.props.showDivisionColumn && <Col className='text-left text-break'>{ this.props.divisionDisplayIsEnabled ? getShortName( this.props.game.DivisionName ) : this.props.game.LeagueShortName || getShortName( this.props.game.LeagueName ) }</Col> }
                            <Col className='narrow'>
                                <span title={ this.props.game.HomeTeam ? this.props.game.HomeTeam.Name : '' }>{ this.props.game.HomeTeam ? this.props.game.HomeTeam.ShortName : '' }</span>
                                -
                                <span title={ this.props.game.AwayTeam ? this.props.game.AwayTeam.Name : '' }>{ this.props.game.AwayTeam ? this.props.game.AwayTeam.ShortName : '' }</span>
                            </Col>
                            <Col className='narrow text-left'>{ location }</Col>

                        </Row>

                    </Col>

                    <Col xs={ 1 } className='form-check-inline-no-margin'>

                        {

                            this.props.editable ?

                                ( this.#isReleased && ! this.props.game.forceEdit ? ( isAdmin( this.props.user ) || isScheduler( this.props.user ) ?

                                    <Icon fw icon='pencil' onClick={ () => this.handleForceEditClick() } /> : '' )

                                :

                                    <Icon fw />

                                )

                            :

                                <OverlayTrigger overlay={ <Tooltip>{ _( 'Sie haben keine Berechtigung, Spiele dieser Liga zu bearbeiten.' ) }</Tooltip> }><span><Icon fw icon='lock' /></span></OverlayTrigger>

                        }

                        {

                            this.props.game.Note ?

                                <OverlayTrigger overlay={<Popover><Popover.Body>{ this.props.game.Note }</Popover.Body></Popover>}>

                                    <span>{ this.renderNotesButton( true ) }</span>

                                </OverlayTrigger>

                            :

                                this.renderNotesButton()

                        }

                    </Col>

                    <Col lg={ 6 } className='needed-referees'>

                        <Row>

                            { this.props.commonlyNeededReferees && this.props.commonlyNeededReferees.map( ( e, i ) => this.renderNeededReferee( e, i ) ) }

                        </Row>

                    </Col>

                </Row>

                <GameNotesModal
                    canEdit={ hasPrivilege( this.props.user, PRIV_EDIT_GAMES_NOTES ) }
                    game={ this.props.game }
                    onChange={ Note => this.changeGame( { Note } ) }
                    onHide={ () => this.toggleNotesModal() }
                    show={ this.state.notesModalOpen }
                    token={ this.props.token }
                />

            </>

        )

    }

}

export default ScheduleGameRow