// KEYBOARD KEYS
export const KEY_BACKSPACE                                              = 8
export const KEY_ENTER                                                  = 13
export const KEY_LEFT                                                   = 37
export const KEY_UP                                                     = 38
export const KEY_RIGHT                                                  = 39
export const KEY_DOWN                                                   = 40
export const KEY_DELETE                                                 = 46
export const KEY_LETTER_A                                               = 65
export const KEY_LETTER_Z                                               = 90
export const KEY_LETTER_OUML                                            = 186
export const KEY_LETTER_SZLIG                                           = 189
export const KEY_LETTER_UUML                                            = 219
export const KEY_LETTER_AUML                                            = 222

// FILE UPLOAD STATUS
export const FILE_UPLOAD_STATUS_ERROR                                   = 'FILE_UPLOAD_STATUS_ERROR'
export const FILE_UPLOAD_STATUS_PENDING                                 = 'FILE_UPLOAD_STATUS_PENDING'
export const FILE_UPLOAD_STATUS_UPLOADED                                = 'FILE_UPLOAD_STATUS_UPLOADED'
export const FILE_UPLOAD_STATUS_UPLOADING                               = 'FILE_UPLOAD_STATUS_UPLOADING'

// ASSIGNMENT STATUS
export const ASSIGNMENT_STATUS_ACCEPTED                                 = 3
export const ASSIGNMENT_STATUS_DECLINED                                 = 4

// COACH RESULT STATUS
export const COACH_RESULT_STATUS_PENDING                                = 0
export const COACH_RESULT_STATUS_RATED                                  = 1
export const COACH_RESULT_STATUS_RELEASED                               = 2

// REFEREEE AVAILABILITIES
export const REFEREE_AVAILABILITY_AVAILABLE                             = 'available'
export const REFEREE_AVAILABILITY_ALREADY_ASSIGNED                      = 'already-assigned'
export const REFEREE_AVAILABILITY_BLOCKED_OR_ABSENT                     = 'blocked-or-absent'

// REFEREE CATEGORIES
export const REFEREE_CATEGORY_A                                         = 0
export const REFEREE_CATEGORY_B                                         = 1
export const REFEREE_CATEGORY_C                                         = 2

// API STATUS MESSAGES
export const STATUS_MESSAGE_LEAGUE_ALREADY_EXISTS                       = 'league already exists'

// GAME INFO CARD TYPES
export const GAME_INFO_CARD_TYPE_GAME                                   = 'game'
export const GAME_INFO_CARD_TYPE_REFEREE                                = 'referee'

// AVAILABLE REFEREES SORT BY OPTIONS
export const AVAILABLE_REFEREES_SORT_BY_OPTION_NAME                     = 'name'
export const AVAILABLE_REFEREES_SORT_BY_OPTION_ZIP_CODE                 = 'zipCode'
export const AVAILABLE_REFEREES_SORT_BY_OPTION_SEASON_COUNT             = 'seasonCount'

// MISC
export const EMPTY_GUID                                                 = '00000000-0000-0000-0000-000000000000'

// MODULES
export const MODULE_ABSENCE_MANAGEMENT                                  = '301E558A-3D2B-4B8A-A3DD-AF9B6104F930'
export const MODULE_ADDRESS_MANAGEMENT                                  = '1D6EF1EF-AC52-49F6-A550-B99649EE1615'
export const MODULE_CLUB_MANAGEMENT                                     = '83D8DB6F-0D8F-4226-9BF2-AD74511278A8'
export const MODULE_DECLINE_REASON_MANAGEMENT                           = '35BA789A-8B1A-4D9F-9E02-14BBEF37AFF8'
export const MODULE_DIVISION_MANAGEMENT                                 = '301E558A-3D2B-4B8A-A3DD-AF9B6104F930'
export const MODULE_EMAIL_MANAGEMENT                                    = '5AA50113-293A-4DA5-A3F3-A4F63D8977F0'
export const MODULE_EVALUATION_MANAGEMENT                               = '1B1C2897-646E-472C-AD8C-A76F0862C514'
export const MODULE_FEDERATION_MANAGEMENT                               = '722ED19A-5A91-41FE-A031-47FCFE1E7C57'
export const MODULE_FILE_MANAGEMENT                                     = '2ECFF887-89EB-443A-8B69-5C7B318F2C2C'
export const MODULE_GAME_MANAGEMENT                                     = '113332B4-90FF-4F82-BB13-52F5EA26336C'
export const MODULE_GROUP_MANAGEMENT                                    = '995469DD-A363-4E62-AB12-0252D01023C9'
export const MODULE_INTERFACE_MANAGEMENT                                = 'C94577EB-0DDB-4FB4-B320-F9BAF5CC678F'
export const MODULE_LEAGUE_MANAGEMENT                                   = '932CAD40-3006-471C-AF9F-258486D573B2'
export const MODULE_LICENSE_TYPE                                        = '125AF5BD-0996-4E3D-BE9F-617282903E01'
export const MODULE_LOCATION_MANAGEMENT                                 = '6AAACFA6-9654-45E7-8DCD-3FF054A56FD9'
export const MODULE_PRIVILEGE_MANAGEMENT                                = '7636D13F-5048-4940-8990-CDE6397D1789'
export const MODULE_REFEREE_MANAGEMENT                                  = 'F139E883-E8EC-4A5C-B5CA-CEC348CB40F6'
export const MODULE_REFEREE_SYSTEM_MANAGEMENT                           = '07DEACE5-BAEF-4D6E-9275-60729403D51D'
export const MODULE_ROLE_MANAGEMENT                                     = '06CEEE73-7491-42EA-B84D-DE3EA1885C67'
export const MODULE_SCHEDULE_MANAGEMENT                                 = 'D90E75D9-DC93-48BC-95E1-6776702B04BF'
export const MODULE_SELF_MANAGEMENT                                     = 'DA97D1B9-67AF-4209-938C-0FE7898F0337'
export const MODULE_TAG_MANAGEMENT                                      = 'A901F01C-5C34-44AB-8AAE-7879F3CD0AF5'
export const MODULE_TEAM_MANAGEMENT                                     = 'D50C1828-3ED3-48E6-84EE-6A5816BCE7D9'

// PRIVILEGES
export const PRIV_ACCEPT_ASSIGNMENT                                     = 'DDE5954E-EF68-4043-8EF2-3B38956BB7BD'
export const PRIV_ADD_USER_TO_GROUP                                     = '340355CE-28BD-4D03-8E9A-F7160595789E'
export const PRIV_ASSIGN_PRIVILEGE                                      = '49FEC3AF-5A94-46F4-9053-188CAACA377C'
export const PRIV_ASSIGN_ROLE_TO_GROUP                                  = 'B409CB38-821D-481B-B55D-8CEF1A627E74'
export const PRIV_ASSIGN_ROLE_TO_USER                                   = '85CD077C-2D99-45E6-AB77-D7E9292D49CD'
export const PRIV_ASSIGN_TAG                                            = 'C7EF251B-2A2F-43BD-965C-ADE33522AB45'
export const PRIV_CALCULATE_DISTANCE_POSSIBILITY                        = '1D036600-09FA-43DA-8506-0E0391A88568'
export const PRIV_CHANGE_EVALUATION_STATUS                              = 'FB5B72B1-C6CB-4369-8B2C-B10657CA301D'
export const PRIV_CREATE_GROUP                                          = '241D0D10-24AA-494D-977E-64E44DA8CFE6'
export const PRIV_CREATE_INTERFACE_MAPPING                              = 'BBA24500-DD94-428F-B78F-162714522F46'
export const PRIV_CREATE_LEAGUE                                         = 'E3D7A880-E09E-4FB6-B943-947DAE9E4907'
export const PRIV_CREATE_ROLE                                           = '790F768C-7DE9-4B76-819F-6D17A473A600'
export const PRIV_CREATE_TAG                                            = '584DEFF6-E014-4B25-8824-426AD6D881CB'
export const PRIV_DECLINE_ASSIGNMENT                                    = '91860257-EFBA-4694-B6AF-9B939F6B8D6E'
export const PRIV_DELETE_ADDRESS                                        = '0F1A0311-BC44-4185-805F-680718E788B3'
export const PRIV_DELETE_CLUB                                           = '6A1877C4-A201-468E-B731-008B23DEB0A6'
export const PRIV_DELETE_DIVISION                                       = '2CC95BD0-8B02-4D17-A594-D9594E070138'
export const PRIV_DELETE_EMAIL_TEMPLATE                                 = '11FEC0E2-5892-402E-A2AA-6CF7EF51CBEB'
export const PRIV_DELETE_FEDERATION                                     = '42709ACB-C8CA-4BB1-BD7D-5C8631AF9168'
export const PRIV_DELETE_FILE                                           = 'FA6B5F45-FF97-4EDC-AADA-41AA7522CE9D'
export const PRIV_DELETE_GAME                                           = '57AA1FC5-FE1D-473C-92DF-2DF542948F51'
export const PRIV_DELETE_GROUP                                          = 'AA19830C-0BD9-4572-A06F-3C561B6A6AD2'
export const PRIV_DELETE_INTERFACE                                      = '7ED3A3E9-4CA1-4B54-8A9D-280621E3C442'
export const PRIV_DELETE_LEAGUE                                         = 'D58E9CAF-1E13-4D0D-808D-7D9FA0D00778'
export const PRIV_DELETE_LOCATION                                       = '308DA634-34FC-43DC-B32F-A10CE0F594C6'
export const PRIV_DELETE_LICENSE_TYPE                                   = '1B0BA07E-5C25-468B-A7D6-C9E95F74E803'
export const PRIV_DELETE_REFEREE                                        = '1CC8B6F7-2ED9-45CB-8D95-9295EB5F306E'
export const PRIV_DELETE_REFEREE_ABSENCE                                = '7E0F35AE-8550-4A5C-A365-8A506BB492AA'
export const PRIV_DELETE_REFEREE_BAN                                    = '4366880F-5877-4789-BBED-A46519FA8906'
export const PRIV_DELETE_REFEREE_RELATION                               = '6B70AF17-D752-4A25-873A-8AEF4EF93F20'
export const PRIV_DELETE_ROLE                                           = 'E434F7EA-5A72-460D-ABB6-F3100622931A'
export const PRIV_DELETE_TAG                                            = '304F6D53-B376-4AA9-A286-C531EEEAB57B'
export const PRIV_DELETE_TEAM                                           = '45058AFA-755C-4CF7-8C83-12C73BE8F04E'
export const PRIV_EDIT_GAMES_NOTES                                      = '53F5A170-83E4-4B22-8B6C-C6DBE4F8C4DC'
export const PRIV_EXECUTE_INTERFACE_IMPORT                              = 'F57ECE68-B805-433E-AC42-1A325680A4C0'
export const PRIV_EXPORT_SCHEDULE_XLS                                   = '46120D82-C8CB-479C-8B33-E577215DAB39'
export const PRIV_IMPORT_GAMES_FROM_EXCEL                               = '998F3344-7191-434E-B0B9-BEA296A1BBA8'
export const PRIV_IMPORT_GAMES_FROM_INTERFACE                           = 'C5C3DDE8-91D0-46F5-9278-F1DEB2EF21F5'
export const PRIV_LOAD_ABSENCES                                         = 'F19D8955-1C8A-492B-9EAA-5B0E2DA91CA1'
export const PRIV_LOAD_ABSENCE_REASONS                                  = 'A37BCA35-3CD2-4011-BD5F-2CD25404514B'
export const PRIV_LOAD_AVAILABLE_REFEREES                               = 'E5363417-3A20-4852-BB1C-7F7C1D1D39CA'
export const PRIV_LOAD_CLUBS                                            = 'A4BE7E11-9716-4035-ACB6-563B5FDCE553'
export const PRIV_LOAD_CONTACTS                                         = 'F87C24FC-2BD1-483D-B745-5822E330299A'
export const PRIV_LOAD_DECLINE_REASONS                                  = '9C5142F4-35C8-486D-82BE-11523530364C'
export const PRIV_LOAD_DIVISIONS                                        = 'CADF3746-D434-4A17-8E18-D134F5C1FC66'
export const PRIV_LOAD_EVALUATION_FACTORS                               = '23283140-1BCA-4FD8-BF53-FC22EA6AFBBB'
export const PRIV_LOAD_EVALUATION_STRUCTURE                             = '0CA2501B-D56E-4DEA-855B-D1B62EEE4804'
export const PRIV_LOAD_EVALUATIONS                                      = '142E3FC8-299B-4C2D-8A0D-A503A9A67023'
export const PRIV_LOAD_FEDERATIONS                                      = 'C2E9C74E-6B07-4374-81FA-9CBFFE512A9E'
export const PRIV_LOAD_GAMES                                            = 'AC2BB756-B582-478C-8DFB-A7CC935B91C7'
export const PRIV_LOAD_GROUPS                                           = '83D61BF3-D0FE-45FB-82B2-00A93B2E7AEF'
export const PRIV_LOAD_INTERFACE_LEAGUES                                = '5EC57ADD-7389-4EFA-B701-0BD58517692B'
export const PRIV_LOAD_INTERFACES                                       = 'B40774E2-8F53-40C0-83DB-CB1595E8F6A5'
export const PRIV_LOAD_LEAGUE_GAMES                                     = 'DC720955-1F83-46B1-861E-016AC1B5C668'
export const PRIV_LOAD_LEAGUES                                          = '7EB10CEC-356F-4FE9-8DBA-0EB99A448D0A'
export const PRIV_LOAD_LICENSE_TYPES                                    = '3B594755-0C9A-4855-92CF-211699B7FCBA'
export const PRIV_LOAD_LOCATIONS                                        = 'B5D1303E-5137-4CCE-AEB1-CB784B90B4B1'
export const PRIV_LOAD_MY_REFEREE                                       = '8AA7CA45-45F9-4087-AF4C-B05D80E6A5ED'
export const PRIV_LOAD_MY_SCHEDULE                                      = '24FB8539-E3DD-47A7-A1C2-053E21C4F6ED'
export const PRIV_LOAD_NOTIFICATIONS                                    = '7C732DC3-2DC9-4212-9E81-0473D4A75B20'
export const PRIV_LOAD_OWN_USER_SETTINGS                                = 'B266012E-9475-49F1-852D-91FAA9B89A48'
export const PRIV_LOAD_REFEREE_SYSTEMS                                  = '5D4456F7-5070-4DBD-AC58-7D1E8FCB75CE'
export const PRIV_LOAD_REFEREES                                         = '0D428638-F0C7-42B7-B0FF-C2D1AFE1B7E2'
export const PRIV_LOAD_REGIONS                                          = '55D7480D-4128-40D3-A36D-EFF59CE52D10'
export const PRIV_LOAD_RELEASE_GAMES                                    = '2277BD32-1D70-4935-B217-15DBEBE03623'
export const PRIV_LOAD_ROLES                                            = '52CFB0E0-636E-4FCB-BA1E-C9DA360BE4D1'
export const PRIV_LOAD_SCHEDULE                                         = '64C6A7A3-E1D9-4FDA-A68E-333CE2B37BFD'
export const PRIV_LOAD_SCHEDULEABLE_LEAGUES                             = 'B24A97BE-6F1C-479F-90F2-C2F081C242B5'
export const PRIV_LOAD_SCHEDULEABLE_REGIONS                             = '9FBABF3D-87F0-4688-83BB-DC4389A67A21'
export const PRIV_LOAD_TEAMS                                            = '4921EEBC-4D2B-4BF7-8DE8-53B86F9B7F5E'
export const PRIV_LOAD_URGENT_CHANGES                                   = 'B5C18BD2-139C-4AF4-844E-D966B71399CE'
export const PRIV_NOTIFY_ASSIGNMENT_USERS                               = 'DA4A5595-4559-455B-8415-5B4E49199190'
export const PRIV_PRINT_EVALUATION                                      = '87A7611E-2E04-487B-9AB9-A5897DA35ACF'
export const PRIV_READ_EVALUATION                                       = '19D8BFF7-DA35-458D-B105-6C2F13243471'
export const PRIV_READ_INTERFACE_RESULT_STRUCTURE                       = '9D659952-DC4F-486C-B1EB-E7B4A3655BAE'
export const PRIV_SET_COACH_RESULT_STATUS                               = 'FB5B72B1-C6CB-4369-8B2C-B10657CA301D'
export const PRIV_RELEASE_GAMES                                         = '9718F474-35EB-4D32-B19B-5C4A8C342275'
export const PRIV_REMOVE_ROLE_FROM_GROUP                                = 'ABEF329B-B501-44F6-95DD-13D5434F7E8B'
export const PRIV_REMOVE_ROLE_FROM_USER                                 = '749CB8A1-CA8B-451E-B194-169B8C19D0C5'
export const PRIV_REMOVE_TAG                                            = 'B3546CFD-3BC6-401C-8F37-D48823913713'
export const PRIV_REMOVE_USER_FROM_GROUP                                = 'D2CDFC4B-7161-444D-B873-FC029DA49CE9'
export const PRIV_SAVE_ADDRESS                                          = 'DC6F9074-F34E-4967-8BD5-2CCDDCBBB736'
export const PRIV_SAVE_CLUB                                             = '51FDE461-3E80-4EE2-9BE5-AEF9945B0822'
export const PRIV_SAVE_DIVISION                                         = '2294D9DA-6EB9-407C-B8E7-F44DFE20570B'
export const PRIV_SAVE_EMAIL_TEMPLATE                                   = '3AFA985B-F824-4C0D-842B-66EF8CACA5AC'
export const PRIV_SAVE_EVALUATION                                       = '3F964942-64D0-475D-A3D4-459ABDB4301C'
export const PRIV_SAVE_FEDERATION                                       = '0E9E8E16-844E-48D4-A0B0-7DA0A84C09CB'
export const PRIV_SAVE_INTERFACE                                        = 'CE97FA04-4E9F-4A49-B3E8-8E4A0F86033B'
export const PRIV_SAVE_LICENSE_TYPE                                     = 'F3AE6AFE-8C15-4400-BB40-938F53786DF5'
export const PRIV_SAVE_LOCATION                                         = '30B1A250-9A64-4C5B-B9CC-628C3ECED213'
export const PRIV_SAVE_OWN_USER_SETTINGS                                = 'B266012E-9475-49F1-852D-91FAA9B89A48'
export const PRIV_SAVE_REFEREE_ABSENCE                                  = 'A5D100F6-2EE9-4B51-8D49-54DB92A181A5'
export const PRIV_SAVE_REFEREE_ALL                                      = '2C085405-803C-4A41-BEAE-423293B2AFC7'
export const PRIV_SAVE_REFEREE_BAN                                      = '58186B60-F226-470A-AC1D-140C428F4706'
export const PRIV_SAVE_REFEREE_BASIC                                    = '0CDAFB7E-EBE8-4267-AFDC-40AD53FBF910'
export const PRIV_SAVE_REFEREE_LEAGUE                                   = '8DC159FA-876F-4E56-95BB-F9CC73910267'
export const PRIV_SAVE_REFEREE_RELATION                                 = '942916F0-4E3D-43C8-997D-451F5E2E61EC'
export const PRIV_SAVE_SCHEDULE                                         = '1FB87CC9-CEE5-432F-A280-E630A9A13C42'
export const PRIV_SAVE_TEAM                                             = '8C282405-5631-4F3A-8C43-4FF876B84AF3'
export const PRIV_SEARCH_TAG                                            = 'DC854697-981C-4F69-9703-0D573638E636'
export const PRIV_SEND_CUSTOM_MESSAGE                                   = 'F931F771-6AA7-489A-82D3-9B1137EEA262'
export const PRIV_SET_LEAGUE_OF_FUTURE_GAMES                            = 'C7479B9C-4F98-4D95-A58C-C3AF0AA4AA8D'
export const PRIV_SET_REFEREE_PROPERTY                                  = '844E56E6-642F-466E-8643-A2228690DA42'
export const PRIV_UPLOAD_FILE                                           = '07AD282A-FEAB-48FC-B0C8-9C8EA947E308'