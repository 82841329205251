import { Icon } from '@hockeydata/skynet'
import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

class CtCInput extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            copied: false,

        }

        this.#dom = {

            input: React.createRef(),

        }

    }

    copy() {

        this.#dom.input.current.select()

        document.execCommand( 'copy' )

        this.setState( { copied: true } )

        setTimeout( () => this.setState( { copied: false } ), 3000 )

    }

    render() {

        return (

            <InputGroup { ...( this.props.inputGroupProps || {} ) }>

                <Form.Control readOnly value={ this.props.value } ref={ this.#dom.input } onClick={ e => e.target.select() } />

                <Button variant={ this.state.copied ? 'success' : 'secondary' } onClick={ () => this.copy() }><Icon icon={ this.state.copied ? 'check' : 'copy' } fw /></Button>

            </InputGroup>

        )

    }

}

export default CtCInput