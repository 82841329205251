// REACT_APP_EASYREF_CUSTOMER=deb npm start
// REACT_APP_EASYREF_CUSTOMER=deb npm run build

const customers = {

    afboe:
    {
        devBaseDataUrl:                         'https://afboe-easyref.morawa.digital/data/',
        disablePublicSchedule:                  true,
        groupAvailableRefereesByAvailability:   true,
        logoImageUrl:                           '/customer/afboe/logo.png',
    },
    deb:
    {
        devBaseDataUrl:                         'https://refereedev.hockeydata.net/data/',
        logoImageUrl:                           '/customer/deb/logo.png',
    },
    ijs:
    {
        devBaseDataUrl:                         'https://refereedev.hockeydata.net/data/',
        groupAvailableRefereesByAvailability:   true,
        logoImageUrl:                           '/customer/ijs/logo.png',
    },
    morawa:
    {
        devBaseDataUrl:                         'https://refereedev.hockeydata.net/data/',
        logoImageUrl:                           '/customer/morawa/logo.png',
    },
    oeehv:
    {
        devBaseDataUrl:                         'https://oeehv-refereedev.hockeydata.net/data/',
        logoImageUrl:                           '/customer/oeehv/logo.png',
        useAlternativeRefereeShortname:         true,
    },

}

const installations = {

    'easyref.morawa.digital':          'deb',
    'refereedev.hockeydata.net':       'deb',
    'refereetest.hockeydata.net':      'deb',
    'ijs-easyref.morawa.digital':      'ijs',
    'oeehv-refereedev.hockeydata.net': 'oeehv',

}

const defaultCustomer = 'morawa'
  let customer        = null

export const getCustomer = () => {

    if ( customer ) {

        return customers[ customer ]

    }

    customer = defaultCustomer

    if ( process.env.REACT_APP_EASYREF_CUSTOMER ) {

        customer = process.env.REACT_APP_EASYREF_CUSTOMER

    } else {

        Object.keys( installations ).forEach( e => {

            if ( document.domain === e ) {

                customer = installations[ e ]

            }

        } )

    }

    return customers[ customer ]

}