import React from 'react'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { Card, Col, Placeholder, Popover, Row } from 'react-bootstrap'
import GameRowInfoColumn from './GameRowInfoColumn'
import GameRowRefereesColumn from './GameRowRefereesColumn'
import { Link } from 'react-router-dom'
import { createDate, fillDate, formatDate, formatTime, stripDate } from '../../util/date'

class UrgentChangeGameRow extends React.Component {

    setDateRangeAndLeague() {

        const gameDate = createDate( this.props.game.ScheduleDate )

        this.props.onDateRangeChange( stripDate( gameDate ).toJSON(), fillDate( gameDate ).toJSON() )
        this.props.onDivisionsChange( [ this.props.game.DivisionId ]                   )

    }

    render() {

        const referees = this.props.game && this.props.game.MapRefereeGames ? this.props.game.MapRefereeGames : []

        return (

            <Card className={ 'mb-2 release-game-row' + ( this.props.game && this.props.game.isReleased ? ' released' : '' ) }>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } xl={ 4 }>

                            <GameRowInfoColumn
                                dateTimeBg={ this.props.game && this.props.game.TimeChanged ? 'danger' : null }
                                dateTimeHeader={ this.props.game && this.props.game.TimeChanged ? <small><Icon icon='exclamation-triangle' /></small> : null }
                                dateTimePopover={ this.props.game && this.props.game.TimeChanged && this.props.game.OldScheduleDate ? <Popover><Popover.Body className='text-center'><div className='text-muted'><small>{ _( 'Ursprüngliches Datum' ) }</small></div><div className='lead'>{ formatDate( this.props.game.OldScheduleDate, { weekday: 'short' } ) } - { formatTime( this.props.game.OldScheduleDate ) }</div></Popover.Body></Popover> : null }
                                game={ this.props.game }
                                isSelected={ this.props.isSelected }
                                noCheckbox={ true }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } md={ 10 } xl={ 7 }>

                            <div className='my-3'>

                                <GameRowRefereesColumn
                                    federations={ this.props.federations }
                                    game={ this.props.game }
                                    referees={ referees }
                                />

                            </div>

                        </Col>

                        <Col xs={ 12 } md={ 2 } xl={ 1 } className='text-right'>

                            {

                                this.props.game ?

                                    <Link to='/einteilung' className='btn btn-warning' onClick={ () => this.setDateRangeAndLeague() }>{ _( 'Ändern' ) }</Link>

                                :

                                    <Placeholder.Button xs={ 3 } />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default UrgentChangeGameRow