import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import MySchedule from '../../components/pages/MySchedule'

const mapStateToProps = state => {

    return {

        isLoading: state.app.isLoading,
        token:     state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleIsLoading: isLoading => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( MySchedule )