import { connect } from 'react-redux'
import UrgentChangeGameRow from '../../components/elements/UrgentChangeGameRow'
import { setDateRange, setDivisions } from '../../store/actions'

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange: ( from, to ) => dispatch( setDateRange( from, to  ) ),
        onDivisionsChange: divisions    => dispatch( setDivisions( divisions ) ),

    }

}

export default connect( null, mapDispatchToProps )( UrgentChangeGameRow )