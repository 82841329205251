import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Release from '../../components/pages/Release'
import { setDateRange, setDivisions } from '../../store/actions'
import { createDate } from '../../util/date'

const mapStateToProps = state => {

    return {

        dateRangeFrom: createDate( state.ui.dateRangeFrom ),
        dateRangeTo:   createDate( state.ui.dateRangeTo   ),
        divisions:     state.ui.divisions || [],
        isLoading:     state.app.isLoading,
        token:         state.auth.token,
        user:          state.auth.user,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange: ( from, to ) => dispatch( setDateRange( from, to                      ) ),
        onDivisionsChange: divisions    => dispatch( setDivisions( divisions                     ) ),
        onToggleIsLoading: isLoading    => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Release )